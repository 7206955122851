import {
    DEFAULT_AVATAR_URL
} from '../shared/constants';

export class RealityMgmtUser {
    constructor(firstName, lastName, orgs, role, address = null, email, isFirstTime, isAdmin, uid = null, photoURL = null, stripeID = null, subscriptionLevel = null, goal = null, password = null) {
        this.firstName = firstName;
        this.lastName = lastName;
        this.isAdmin = isAdmin;
        this.uid = uid;
        this.address = (null != address) ? address : { line1: '', line2: '', city: '', state: '', zip: '' };
        this.email = email;
        this.photoURL = (null != photoURL) ? photoURL : DEFAULT_AVATAR_URL;
        this.stripeID = stripeID;
        this.isFirstTime = isFirstTime;
        this.fcmToken = '';
        this.subscriptionLevel = (null != subscriptionLevel) ? subscriptionLevel : 'tier1';
        this.goal = goal;
        this.password = password;
        this.orgs = orgs;
        this.role = role;

        if (!this.firstName) {
            throw "No firstName supplied";
        }

        if (!this.lastName) {
            throw "No lastName supplied";
        }

        if (!this.address) {
            throw "No address supplied";
        }

        if (!this.email) {
            throw "No email supplied";
        }
    }
}