// ****************************************************************
//
// This file is subject to the terms and conditions defined in file
// 'LICENSE.txt', which can be found at the root of this project.
//
// ****************************************************************

import { combineReducers } from "redux";
import { getUsers } from "../../services/accountApi";

const SESSION_INIT = "SESSION_INIT";
const SELECT_USER_SUCCESS = "SELECT_USER_SUCCESS";
const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
const GET_ORGS_SUCCESS = "GET_ORGS_SUCCESS";
const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
const START_SESSION_SUCCESS = "START_SESSION_SUCCESS";
const END_SESSION = "END_SESSION";
const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
const LOADING = "LOADING";
const STOP_LOADING = "STOP_LOADING";

export function sessionStateInit(value) {
  return {
    type: SESSION_INIT,
    value,
  };
}

export function logoutSuccess() {
  return {
    type: LOGOUT_SUCCESS,
  };
}

export function startSessionSuccess(value) {
  return {
    type: START_SESSION_SUCCESS,
    value,
  };
}

export function endSession(value) {
  return {
    type: END_SESSION,
    value,
  };
}

export function selectUserSuccess(value) {
  return {
    type: SELECT_USER_SUCCESS,
    value,
  };
}

export function addUserSuccess(value) {
  return {
    type: ADD_USER_SUCCESS,
    value,
  };
}

export function getUsersSuccess(value) {
  return {
    type: GET_USERS_SUCCESS,
    value,
  };
}

export function getOrgsSuccess(value) {
  return {
    type: GET_ORGS_SUCCESS,
    value,
  };
}

export function showLoading() {
  return {
    type: LOADING
  };
}

export function hideLoading() {
  return {
    type: STOP_LOADING
  };
}


const defaultParams = {
  isOrgAdmin: false,
  isBldgAdmin: false,
  orgs: [],
  users: [],
  user: {
    email: "",
    fcmToken: "",
    address: {
      line1: "",
      line2: "",
      city: "",
      state: "",
      zip: "",
    },
    subscriptionLevel: "",
    firstName: "",
    userName: "",
    lastName: "",
    phone: "",
    stripeID: "",
    uid: null,
    role: "",
    orgs: null,
    osType: "",
    password: "",
    isFirstTime: true,
    lastActivity: 0,
    fcmRegToken: "",
    isAdmin: false,
    createdAt: 0,
    lastSignedInAt: 0,
    photoURL: "",
    gender: "",
    birthdate: "",
  },
  newUser: {
    email: "",
    fcmToken: "",
    subscriptionLevel: "tier1",
    firstName: "",
    userName: "",
    lastName: "",
    phone: "",
    stripeID: "",
    uid: "",
    orgs: null,
    osType: "",
    password: "realitymgmt",
    isFirstTime: true,
    lastActivity: 0,
    fcmRegToken: "",
    role: "",
    isAdmin: false,
    createdAt: 0,
    lastSignedInAt: 0,
    photoURL: "",
    gender: "",
    birthdate: "",
  },
  selectedUser: null,
  org: null,
  sessionID: null,
  loading: false,
};

export default function sessionParams(state = defaultParams, action) {
  const userRef = state.user;
  const isAdmin = state.user.isAdmin;
  const sessionID = state.user.sessionID;
  switch (action.type) {
    case SESSION_INIT:
      var user = action.value;
      var org = user.orgs;
      const buildings = (null != org) ? org.buildings : null;
      var isBldgAdmin = false;
      if (null != buildings) {
        // Looking for an 'Any' isAdmin relationship
        for (let building of buildings) {
          if (building.isAdmin) {
              isBldgAdmin = true;
              break;
          } else {
              // remains false
          }
        }
      }
      // console.log("isBldgAdmin", isBldgAdmin, buildings);
      return {
        ...state,
        user: action.value,
        isOrgAdmin: org.isAdmin,
        isBldgAdmin: isBldgAdmin,
        selectedUser: action.value,
        org: user.orgs
      };

    case START_SESSION_SUCCESS:
      console.log("START_SESSION_SUCCESS", action.value);
      return {
        ...state,
        sessionID: action.value.id,
      };

    case END_SESSION:
      return {
        ...state,
        sessionID: null,
      };

    case GET_USERS_SUCCESS:
      // console.log("GET_USERS_SUCCESS", action.value);
      return {
        ...state,
        users: action.value,
      };

    case ADD_USER_SUCCESS:
      console.log("ADD_USER_SUCCESS", action.value);
      return {
        ...state,
        users: [...state.users, action.value],
        selectedUser: action.value,
      };

    case SELECT_USER_SUCCESS:
      // console.log("SELECT_USER_SUCCESS", action.value);
      return {
        ...state,
        selectedUser: action.value,
      };

    case LOGOUT_SUCCESS:
      // console.log("SELECT_USER_SUCCESS", action.value);
      return {
        ...state,
        selectedUser: null,
      };

    case LOADING:
      // console.log("SELECT_USER_SUCCESS", action.value);
      return {
        ...state,
        loading: true,
      };

    case STOP_LOADING:
      // console.log("SELECT_USER_SUCCESS", action.value);
      return {
        ...state,
        loading: false,
      };

    default: {
      return state;
    }
  }
}
