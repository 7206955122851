export class FundamentalizerData {
    constructor(frequency,
    harmonicsArray,
    nearestNote,
    cents,
    visibleFrequency,
    wavelength,
    colorSpace,
    fundamentalNoteCount,
    highestCount,
    totalNotes,
    date = new Date(Date.now()).getTime(),
    id = null,
    transcript = null) {
        this.frequency = frequency;
        this.harmonicsArray = harmonicsArray
        this.nearestNote = nearestNote;
        this.cents = cents;
        this.visibleFrequency = visibleFrequency;
        this.wavelength = wavelength;
        this.colorSpace = colorSpace;
        this.fundamentalNoteCount = fundamentalNoteCount;
        this.highestCount = highestCount;
        this.totalNotes = totalNotes;
        this.date = date;
        this.id = id;
        this.transcript = transcript;
        this.timestamp = date;
    }
}

export class FundamentalizerVoiceData {
    constructor(frequency,
    visibleFrequency,
    fundamentalNoteCount,
    highestCount,
    date = new Date(Date.now()).getTime(),
    id = null,
    transcript = null) {
        this.frequency = frequency;
        this.visibleFrequency = visibleFrequency;
        this.fundamentalNoteCount = fundamentalNoteCount;
        this.highestCount = highestCount;
        this.date = date;
        this.id = id;
        this.transcript = transcript;
        this.timestamp = date;
    }
}