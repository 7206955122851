import React, { useState, useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateColorWindowStatus } from "./store/channels/lsvParams";
import { sessionStateInit, getUsersSuccess, selectUserSuccess, addUserSuccess, startSessionSuccess } from "./store/session/sessionData";
import socketIO from 'socket.io-client';
import Fundamentalizer from "./components/fundamentalizer";
import { RealityMgmtUser } from "./models/userModel";
import logo from "./logo.svg";
import "./App.css";

import { Layout, Alert, TabsProps, Button, Tabs, Space } from "antd";

import {
    channels,
    REALITY_MGMT_JWT_TOKEN,
    REALITY_MGMT_UID,
    api_url,
    DEFAULT_ORG,
    base_url,
    auth_url,
    login_url,
    users_url,
    newUser_url,
    account_url,
    cards_url,
    signup_url,
    sessionData_url,
} from "./shared/constants";
import { recordSession } from "./services/accountApi";

import firebase from "firebase/app";
import "firebase/auth";
// import { check } from "reachability";

/************************************************************
Check internet connection (will make into a subscription later)
/*************************************************************/
// check()
// .then((online) => {
//     console.log("Online", online);
//     if (!online) {
//       window.alert("Please check your internet connection");
//     }
// });


const socket = socketIO.connect('https://happy-place.onrender.com');

const RenderInWindow = (props) => {
    const dispatch = useDispatch();
    const colorWindowOpen = useSelector((state) => state.lsvParams.colorWindowOpen);
    const [container, setContainer] = useState(null);
    const newWindow = useRef(window);

    useEffect(() => {
        const div = document.createElement("div");
        setContainer(div);
    }, []);

    useEffect(() => {
        if (container) {
            newWindow.current = window.open("about:blank", "newWin", "width=600,height=400,left=200,top=200");
            newWindow.current.document.body.setAttribute("style", "margin-left:0; margin-right: 0; margin-top: 0; margin-bottom: 0;");
            newWindow.current.document.body.appendChild(container);
            const curWindow = newWindow.current;
            // if (!colorWindowOpen) {
            //   curWindow.close();
            //   console.log("closing");
            // }
            curWindow.addEventListener("beforeunload", () => dispatch(updateColorWindowStatus(false)));
            // window.addEventListener('unload', () => curWindow.close());
            return () => {
                console.log("closing");
                curWindow.close();
                // window.removeEventListener('beforeunload', dispatch(updateColorWindowStatus(!colorWindowOpen)));
                // curWindow.on('close', function() { //   <---- Catch close event

                //     // The dialog box below will open, instead of your app closing.
                //     alert('closing!!!')
                // });
                // dispatch(updateColorWindowStatus(!colorWindowOpen));
            };
        }
    }, [container]);

    return container && createPortal(props.children, container);
};

function App() {
    /*
        CONSTANTS/VARIABLES
    */
    const dispatch = useDispatch();
    const colorWindowOpen = useSelector((state) => state.lsvParams.colorWindowOpen);
    const fundamentalInfo = useSelector((state) => state.lsvParams.fundamentalizer);

    const isAdmin = useSelector((state) => state.sessionParams.user.isAdmin);
    const userRef = useSelector((state) => state.sessionParams.user);
    const selectedUser = useSelector((state) => state.sessionParams.selectedUser);
    const sessionID = useSelector((state) => state.sessionParams.sessionID);

    const [socketID, setSocketID] = useState('');
    const [skyboxImage, setSkyboxImage] = useState('');
    const [collapsed, setCollapsed] = useState(false); // Sidebar Menu
    const [visible, setVisible] = useState(false); // Bottom Drawer
    // const [headerHeight, setHeaderHeight] = useState(0);
    const [durationFormat, setDurationFormat] = useState(true);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [showPause, setShowPause] = useState(false);
    const [visChannels, setVisChannels] = useState(["Ao", "Td", "Rl", "Bl"]);
    const [isCanvasHidden, setCanvasHidden] = useState(true);
    const footerRef = useRef(null);

    const [loginVisible, setLoginVisible] = useState(false);
    const [signupVisible, setSignupVisible] = useState(false);

    const [logOutVisible, setLogOutVisible] = useState(false);
    const [splashVisible, setSplashVisible] = useState(true);
    const [credentials, setCredentials] = useState({ email: "", password: "", confirmPassword: "", goal: "", firstName: "", lastName: "" });
    const [tierOne, setTierOne] = useState(false);
    const [tierTwo, setTierTwo] = useState(false);
    var confirmPassword = "";
    // LocalStorage
    const appStorage = window.localStorage;

    var user;
    // Firebase imports
    let firebaseApp = null;
    let auth = null;
    const firebaseConfig = {
        apiKey: "AIzaSyDniHnvuDPyKOPZBqbmOMMDTkr0oCaji64",
        authDomain: "innersense-server.firebaseapp.com",
        databaseURL: "https://innersense-server.firebaseio.com",
        projectId: "innersense-server",
        storageBucket: "innersense-server.appspot.com",
        messagingSenderId: "469401836773",
        appId: "1:469401836773:web:d73426efeed6a49416480c",
    };

    // Initialize Firebase
    let app = null;
    if (!firebase.apps.length) {
        app = firebase.initializeApp(firebaseConfig);
        auth = firebase.auth();
    } else {
        auth = firebase.auth();
    }
    useEffect(() => {
        _checkAuth();
    }, []);
    let ALERT_TITLE = "Successfully Signed Up!";
    let ALERT_BUTTON_TEXT = "Thanks";
    const formRef = useRef(null);
    /************************************************************
  Check Firebase Session
  /*************************************************************/
    const showTierOne = () => {
        setTierOne(true);
    };

    const showTierTwo = () => {
        setTierTwo(true);
    };

    const handleSubmit = () => {
        let serverCredentials = {
            email: credentials.email,
            id_token: "",
        };
        firebaseSignIn(serverCredentials, credentials.password);
    };

    const handleSubmitSignUp = () => {
        if (confirmPassword.trim() !== credentials.password) {
            window.alert("Passwords must match");
        } else {
            let newUser = new RealityMgmtUser(
                credentials.firstName,
                credentials.lastName,
                DEFAULT_ORG,
                "User",
                null,
                userRef.email,
                true,
                false,
                null,
                null,
                null,
                null,
                credentials.goal,
                credentials.password
            );
            console.log("createUser", newUser);
            // createUser(newUser);
        }
    };

    const items = [
        {
            key: "1",
            label: `Login`,
            children: () => (`<div className="loginMain">
        <div style={{ zIndex: 1, width: '100%', height: '100%', backgroundColor: '#353535', backgroundImage: 'url('logo.png')', backgroundSize: '100%', position: 'absolute', top: '0' }}>
          <div className="loginContainer">
            <div style={{ display: 'inline-block', backgroundSize: 'cover', width: '100px', height: '100px', backgroundImage: 'url('assets/img/icon.png')'' }}></div>
            <h1>Login</h1><br />
            <form className="container">
              <div className="form-group">
                <label>Email</label><br />
                <input value={credentials.email} onChange={(e) => handleEmail(e)}  type="email" className="form-control" id="emailSignIn" />
              </div>
              <div className="form-group">
                <label>Password</label><br />
                <input value={credentials.password} onChange={(e) => handlePassword(e)} type="password" className="form-control" id="passwordSignIn" />
              </div>
              <Button id="newBtn" onClick={handleSubmit} type="primary" className="loginBtn">Login</Button>
          </form>
          </div>
        </div>
      </div>`),
        },
        {
            key: "2",
            label: `Sign Up`,
            children: () => (`<div className="signUpMain">
        <div style={{ zIndex: 1, width: '100%', height: '100%', backgroundColor: '#353535', backgroundImage: 'url('logo.png')', backgroundSize: '100%', position: 'absolute', top: '0' }}>
          <div className="loginContainer">
            <div style={{ display: 'inline-block', backgroundSize: 'cover', width: '100px', height: '100px', backgroundImage: 'url('assets/img/icon.png')' }}></div>
            <h1>Login</h1><br />
            <form className="container">
              <div className="form-group">
                <label>Email</label><br />
                <input value={credentials.email} onChange={(e) => handleEmail(e)}  type="email" className="form-control" id="emailSignIn" />
              </div>
              <div className="form-group">
                <label>Email</label><br />
                <input value={credentials.firstName} onChange={(e) => handleFirstName(e)}  type="text" className="form-control" id="emailSignIn" />
              </div>
              <div className="form-group">
                <label>Password</label><br />
                <input value={credentials.lastName} onChange={(e) => handleLastName(e)} type="text" className="form-control" id="passwordSignIn" />
              </div>
              <div className="form-group">
                <label>Password</label><br />
                <input value={credentials.goal} onChange={(e) => handleGoal(e)} type="text" className="form-control" id="passwordSignIn" />
              </div>
              <div className="form-group">
                <label>Confirm Password</label><br />
                <input value={credentials.password} onChange={(e) => handlePassword(e)} type="password" className="form-control" id="passwordSignIn" />
              </div>
              <div className="form-group">
                <label>Confirm Password</label><br />
                <input value={confirmPassword} onChange={(e) => handleConfirmPassword(e)} type="password" className="form-control" id="passwordSignIn" />
              </div>
              <Button id="newBtn"onClick={handleSubmitSignUp} type="primary" className="loginBtn">Sign Up</Button>
          </form>
          </div>
        </div>
      </div>`),
        },
    ];

    const handleEmail = (e) => {
        setCredentials({
            email: e.target.value,
            password: credentials.password,
            firstName: credentials.firstName,
            lastName: credentials.lastName,
            goal: credentials.goal,
        });
        // console.log("credentials", credentials);
    };

    const handleFirstName = (e) => {
        setCredentials({
            email: credentials.email,
            password: credentials.password,
            firstName: e.target.value,
            lastName: credentials.lastName,
            goal: credentials.goal,
        });
        // console.log("credentials", credentials);
    };

    const handleLastName = (e) => {
        setCredentials({
            email: credentials.email,
            password: credentials.password,
            firstName: credentials.firstName,
            lastName: e.target.value,
            goal: credentials.goal,
        });
        // console.log("credentials", credentials);
    };

    const handleGoal = (e) => {
        setCredentials({
            email: credentials.email,
            password: credentials.password,
            firstName: credentials.firstName,
            lastName: credentials.lastName,
            goal: e.target.value,
        });
        // console.log("credentials", credentials);
    };

    const handlePassword = (e) => {
        setCredentials({
            password: e.target.value,
            email: credentials.email,
            firstName: credentials.firstName,
            lastName: credentials.lastName,
            goal: credentials.goal,
        });
        // console.log("credentials", credentials);
    };

    const handleConfirmPassword = (e) => {
        confirmPassword = e.target.value;
        // setCredentials({ password: e.target.value, email: credentials.email, firstName: credentials.firstName, lastName: credentials.lastName, goal: credentials.goal });
        // console.log("credentials", credentials);
    };

    let toggleSider = () => {
        setCollapsed(!collapsed);
    };

    const showLogOut = () => {
        setLogOutVisible(true);
    };

    const hideLogOut = () => {
        setLogOutVisible(false);
    };

    const showLogin = () => {
        setLoginVisible(true);
    };

    const hideLogin = () => {
        setLoginVisible(false);
    };

    const showSignup = () => {
        setSignupVisible(true);
    };

    const hideSignup = () => {
        setSignupVisible(false);
    };

    const showSplash = () => {
        console.log("showSplash");
        setSplashVisible(true);
    };

    const hideSplash = () => {
        console.log("hideSplash");
        setSplashVisible(false);
    };

    const handleLogout = () => {
        logOut();
    };

    const closeColorWindow = () => {
        // dispatch(updateColorWindowStatus(!colorWindowOpen));
        alert("closeColorWindow()");
    };

    /*
        FUNCTIONS
    */

    function createUser(user) {
        fetch(newUser_url, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ data: user }),
        })
            .then(function (responseOne) {
                return responseOne.json();
            })
            .then(function (serverResponse) {
                if (serverResponse.success) {
                    let serverCredentials = {
                        email: credentials.email,
                        id_token: "",
                    };
                    firebaseSignIn(serverCredentials, credentials.password);
                    // const dispatch = useDispatch();
                } else {
                }
            })
            .catch(function (error) {
                console.error(error);
            });
    }

    function _checkAuth() {
        auth.onAuthStateChanged(function (user) {
            if (user) {
                user.getIdToken().then(function (auth) {
                    let idToken = auth;
                    console.log("Token! ", auth);
                    let serverCredentials = {
                        email: user.email,
                        id_token: idToken,
                    };
                    _loginServer(serverCredentials, null);
                    hideSplash();
                    hideLogin();
                });
            } else {
                console.log("User not signed in ", user);
                let token = appStorage.getItem(REALITY_MGMT_JWT_TOKEN);
                console.log("token", token);
                hideSplash();
                showLogin();
                // getUsers();
            }
        });
    }

    /************************************************************
        Firebase Sign In
    /*************************************************************/

    function firebaseSignIn(serverCredentials, password) {
        auth.signInWithEmailAndPassword(serverCredentials.email, password)
            .then(function (response) {
                let user = response.user;
                console.log("Signed In User! ", user.refreshToken, user.uid);
                user.getIdToken().then(function (auth) {
                    let idToken = auth;
                    console.log("Token! ", idToken);
                    let serverCredentials = {
                        email: user.email,
                        id_token: idToken,
                    };
                    _loginServer(serverCredentials, password);
                });
            })
            .catch(function (error) {
                console.log("emailSignIn ", error);
                // Handle Errors here.
                let errorCode = error.code;
                let errorMessage = error.message;
                ALERT_TITLE = "Login Error";
                switch (errorCode) {
                    case "auth/user-not-found":
                        window.alert("User Account Not Found!", "Login Error");
                        break;
                    case "auth/invalid-email":
                        window.alert("Invalid Email!", "Login Error");
                        break;
                    case "auth/user-disabled":
                        window.alert("User is disabled!", "Login Error");
                        break;
                    case "auth/wrong-password":
                        window.alert("Wrong Password!", "Login Error");
                        break;
                    default:
                        window.alert(error.message, "Login Error");
                        break;
                }
            });
    }

    /************************************************************
        Login to the server
    /*************************************************************/

    function _loginServer(serverCredentials, password) {
        console.log("Server", serverCredentials);

        fetch(login_url, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(serverCredentials),
        })
            .then(function (responseOne) {
                return responseOne.json();
            })
            .then(function (serverResponse) {
                if (serverResponse.success) {
                    // console.log("Innersense User! ", serverResponse);
                    let innersenseUser = serverResponse.data;
                    let serverUser = new RealityMgmtUser(
                        innersenseUser.firstName,
                        innersenseUser.lastName,
                        innersenseUser.orgs,
                        innersenseUser.role,
                        innersenseUser.address,
                        innersenseUser.email,
                        innersenseUser.isFirstTime,
                        innersenseUser.isAdmin,
                        innersenseUser.uid,
                        innersenseUser.photoURL,
                        innersenseUser.stripeID,
                        innersenseUser.subscriptionLevel
                    );
                    user = serverUser;
                    dispatch(sessionStateInit(user));
                    // getUsers();
                    appStorage.setItem(REALITY_MGMT_JWT_TOKEN, serverResponse.accessToken);
                    appStorage.setItem(REALITY_MGMT_UID, serverResponse.data.uid + "___" + serverResponse.data.email);
                    getUsers();
                    // hideLogin();
                    if (null != user.subscriptionLevel && user.subscriptionLevel === "tier1") {
                        showTierOne();
                    } else if (null != user.subscriptionLevel && user.subscriptionLevel === "tier2") {
                        showTierTwo();
                    }
                } else {
                    if (null != password) {
                        firebaseSignIn(serverCredentials, password);
                    }
                }
            })
            .catch(function (error) {
                console.error(error);
            });
    }

    function getUsers() {
        let token = appStorage.getItem(REALITY_MGMT_JWT_TOKEN);
        fetch(users_url, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
        })
            .then(function (responseOne) {
                return responseOne.json();
            })
            .then(function (serverResponse) {
                if (serverResponse.success) {
                    console.log("Users", serverResponse);
                    dispatch(getUsersSuccess(serverResponse.data));
                    // dispatch(addUser());
                } else {
                }
            })
            .catch(function (error) {
                console.error(error);
            });
    }

    function logOut() {
        auth.signOut();
        // showLogin();
        let token = appStorage.getItem(REALITY_MGMT_JWT_TOKEN);
        if (null != token) {
            appStorage.setItem(REALITY_MGMT_JWT_TOKEN, token + "expires=Thu, 01 Jan 1970 00:00:01 GMT;");
        }
        // hideLogOut();
    }

    useEffect(() => {
        socket.on('connection', (data) => {
            setSocketID(data);
            console.log(data)
        });

        socket.on('skyboxDetails', (data) => {
            setSkyboxImage(data.content.file);
            console.log(data)
        });
    }, [socket]);

    return (
        <Layout className="App">
           
            <div className="splashContainer" style={{ display: splashVisible ? "block" : "none", visibility: splashVisible ? "visible" : "hidden" }}>
                <div
                    style={{
                        zIndex: 1,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "#353535",
                        backgroundImage: `url('logo.png')`,
                        backgroundSize: "100%",
                        position: "absolute",
                        top: "0",
                    }}>
                    <div className="loginContainer">
                        <div
                            style={{
                                display: "inline-block",
                                backgroundSize: "100%",
                                width: "100px",
                                height: "100px",
                                backgroundImage: `url('assets/img/icon.png')`,
                            }}
                        ></div>
                        <h1>Loading...</h1>
                        <br />
                    </div>
                </div>
            </div>

            
        <Fundamentalizer socketID={socketID} skyboxImage={skyboxImage} />

        {(null != selectedUser) && colorWindowOpen && (
            <RenderInWindow onClose={() => closeColorWindow()}>
                <div style={{ height: "100%", backgroundColor: fundamentalInfo.colorSpace.rgb }} />
            </RenderInWindow>
        )}
        </Layout>
    );
}

export default App;

//

        //     <Tabs style={{ display: loginVisible ? "block" : "none", visibility: loginVisible ? "visible" : "hidden" }} centered defaultActiveKey="1" items={[
            
        //    {key: "1",
        //     label: "Login",
        //     children: <div className="loginMain">
        // <div id="loginMain" style={{ zIndex: 1, width: '100%', height: '100%', backgroundColor: '#353535', position: 'absolute', top: '0' }}>
        //   <div className="loginContainer">
        //     <div style={{ display: 'inline-block', backgroundSize: 'cover', width: '100px', height: '100px'}}></div>
        //     <h1>Login</h1><br />
        //     <form className="container">
        //       <div className="form-group">
        //         <label>Email</label><br />
        //         <input value={credentials.email} onChange={(e) => handleEmail(e)}  type="email" className="form-control" id="emailSignIn" />
        //       </div>
        //       <div className="form-group">
        //         <label>Password</label><br />
        //         <input value={credentials.password} onChange={(e) => handlePassword(e)} type="password" className="form-control" id="passwordSignIn" />
        //       </div>
        //       <Button onClick={handleSubmit} type="primary" className="loginBtn">Login</Button>
        //   </form>
        //   </div>
        //   </div>
        // </div> 
        // },
        // {
        //     key: "2",
        //     label: "Sign Up",
        //     children: <div className="signUpMain">
        // <div id="signUpMain" style={{ zIndex: 1, width: '100%', height: '100%', backgroundColor: '#353535', position: 'absolute', top: '0' }}>
        //   <div className="loginContainer">
        //     <div style={{ display: 'inline-block', backgroundSize: 'cover', width: '100px', height: '100px' }}></div>
        //     <h1>Sign Up</h1><br />
        //     <form className="container">
        //       <div className="form-group">
        //         <label>Email</label><br />
        //         <input value={credentials.email} onChange={(e) => handleEmail(e)}  type="email" className="form-control" id="emailSignIn" />
        //       </div>
        //       <div className="form-group">
        //         <label>First Name</label><br />
        //         <input value={credentials.firstName} onChange={(e) => handleFirstName(e)}  type="text" className="form-control" id="emailSignIn" />
        //       </div>
        //       <div className="form-group">
        //         <label>Last Name</label><br />
        //         <input value={credentials.lastName} onChange={(e) => handleLastName(e)} type="text" className="form-control" id="passwordSignIn" />
        //       </div>
        //       <div className="form-group">
        //         <label>Goal?</label><br />
        //         <input value={credentials.goal} onChange={(e) => handleGoal(e)} type="text" className="form-control" id="passwordSignIn" />
        //       </div>
        //       <div className="form-group">
        //         <label>Password</label><br />
        //         <input value={credentials.password} onChange={(e) => handlePassword(e)} type="password" className="form-control" id="passwordSignIn" />
        //       </div>
        //       <div className="form-group">
        //         <label>Confirm Password</label><br />
        //         <input value={credentials.confirmPassword} onChange={(e) => handleConfirmPassword(e)} type="password" className="form-control" id="passwordSignIn" />
        //       </div>
        //       <Button onClick={handleSubmitSignUp} type="primary" className="loginBtn">Sign Up</Button>
        //   </form>
        //   </div>
        //   </div>
        // </div>}
        // ]}
        // ></Tabs>
        //     