// ****************************************************************
//
// This file is subject to the terms and conditions defined in file
// 'LICENSE.txt', which can be found at the root of this project.
//
// ****************************************************************

import { getUsersSuccess, addUserSuccess } from "../store/session/sessionData";
import { useDispatch, useSelector } from "react-redux";
import { RealityMgmtUser } from "../models/userModel";
import {
    REALITY_MGMT_JWT_TOKEN,
    REALITY_MGMT_UID,
    api_url,
    base_url,
    auth_url,
    login_url,
    users_url,
    account_url,
    newUser_url,
    cards_url,
    signup_url,
    sessionData_url
} from '../shared/constants';

const appStorage = window.localStorage;
// const dispatch = useDispatch();

export const recordSession = function (session) {
    let token = appStorage.getItem(REALITY_MGMT_JWT_TOKEN);
    fetch(sessionData_url + session.sessionID + "/" + session.uid, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
        },
        body: JSON.stringify({ data: session }),
    })
    .then(function (responseOne) {
        return responseOne.json();
    })
    .then(function (serverResponse) {
        if (serverResponse.success) {
            // console.log("serverResponse", serverResponse);
            // dispatch(saveSessionData(serverResponse.data));
        } else {
        }
    })
    .catch(function (error) {
        console.error(error);
    });
};

export const getUsers = function () {
    let token = appStorage.getItem(REALITY_MGMT_JWT_TOKEN);
    fetch(users_url, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
        },
    })
    .then(function (responseOne) {
        return responseOne.json();
    })
    .then(function (serverResponse) {
        if (serverResponse.success) {
            // console.log("Users", serverResponse);
            // const dispatch = useDispatch();
            getUsersSuccess(serverResponse.data);
        } else {
        }
    })
    .catch(function (error) {
        console.error(error);
    });
};

// export const addUser = function (user) {
//     let token = appStorage.getItem(REALITY_MGMT_JWT_TOKEN);
//     let serverUser = new RealityMgmtUser(user.firstName, user.lastName, {
//         line1: "Test",
//         city: "New York",
//         state: "NY",
//         zip: "10010",
//     }, user.email, true);
//     fetch(users_url, {
//         method: "POST",
//         headers: {
//             Accept: "application/json",
//             "Content-Type": "application/json",
//             Authorization: "Bearer " + token,
//         },
//         body: JSON.stringify({ data: serverUser }),
//     })
//     .then(function (responseOne) {
//         return responseOne.json();
//     })
//     .then(function (serverResponse) {
//         if (serverResponse.success) {
//             console.log("serverResponse", serverResponse);
//             addUserSuccess(serverResponse.data);
//         } else {
//         }
//     })
//     .catch(function (error) {
//         console.error(error);
//     });
// };
