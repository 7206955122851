// ****************************************************************
//
// This file is subject to the terms and conditions defined in file
// 'LICENSE.txt', which can be found at the root of this project.
//
// ****************************************************************

import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Row, Col, Card, Collapse, Avatar, Tooltip, Space, Form, Upload, Modal, Alert, Select, Tag, Button, List } from "antd";

import domtoimage from "dom-to-image";
import { saveAs } from "file-saver";

import "antd/dist/antd.min.css";
import "./vectorscope.css";

import { InboxOutlined, CloseCircleOutlined, ExclamationCircleOutlined, PlusOutlined, RightOutlined, DownOutlined, AudioOutlined } from "@ant-design/icons";

const { Meta } = Card;
const { Panel } = Collapse;
const { Dragger } = Upload;
const { confirm } = Modal;
const { Option } = Select;

let fftSize = 4096;
let SAMPLERATE = 48000;
let nyquist = SAMPLERATE / 2;
let binCount = fftSize / 2;
let freqResolution = nyquist / binCount; // 24000/2048 = 11.71875Hz per bar

const Vectorscope = React.memo(function Vectorscope(props) {
  const dispatch = useDispatch();
  // Fundamentalizer ("enabled", "disabled", "frozen")
  const fundamentalizerStatus = useSelector((state) => state.lsvParams.fundamentalizerStatus);
  const fundamentalizerDevice = useSelector((state) => state.lsvParams.fundamentalizerDevice);
  const fundamentalInfo = useSelector((state) => state.lsvParams.fundamentalizer);
  const fundamentalNoteCount = useSelector((state) => state.lsvParams.fundamentalizer.fundamentalNoteCount);
  const colorWindowOpen = useSelector((state) => state.lsvParams.colorWindowOpen);

  const brainstateList = useSelector((state) => state.lsvParams.brainstateList);
  const selectedBrainstate = useSelector((state) => state.lsvParams.selectedBrainstate);

  const scopeRef = useRef(null);
  const imgRef = useRef(null);

  const [binauralPlaying, setBinauralPlaying] = useState(false);
  const [binauralFrequency, setBinauralFrequency] = useState([0, 0]);
  const [visColorSpectrumArray, setVisColorSpectrumArray] = useState([]);

  const [colorArray, setColorArray] = useState([
    { note: "C", color: "rgb(0%,0%,0%)" },
    { note: "C#", color: "rgb(0%,0%,0%)" },
    { note: "D", color: "rgb(0%,0%,0%)" },
    { note: "D#", color: "rgb(0%,0%,0%)" },
    { note: "E", color: "rgb(0%,0%,0%)" },
    { note: "F", color: "rgb(0%,0%,0%)" },
    { note: "F#", color: "rgb(0%,0%,0%)" },
    { note: "G", color: "rgb(0%,0%,0%)" },
    { note: "G#", color: "rgb(0%,0%,0%)" },
    { note: "A", color: "rgb(0%,0%,0%)" },
    { note: "A#", color: "rgb(0%,0%,0%)" },
    { note: "B", color: "rgb(0%,0%,0%)" },
  ]);

  let binColorSpectrumArray = [];

  let scope;
  let drawScope;
  let frameCountId = 0;
  let WIDTH = 600;
  let HEIGHT = 600;

  // Interesting parameters to tweak!
  let SMOOTHING = 0.8;

  var scaleFactor = 0.03;

  let index = 0;
  let animateId;
  let previousTranslate = { x: 0, y: 0 };

  // startLissajous();

  const drawScopeCanvas = (gainPercentage = 1) => {
    // MUST BE SQUARE DIMENSIONS
    scope = scopeRef.current;
    // console.log({scope})
    if (scope !== null) {
      drawScope = scope.getContext("2d");

      drawScope.translate(-previousTranslate.x, -previousTranslate.y);
      drawScope.clearRect(0, 0, scope.width, scope.height);
      drawScope.beginPath();
      drawScope.strokeStyle = "#befde5";
      drawScope.lineWidth = 1;

      for (let i = -binCount / 2; i <= binCount / 2; i++) {
        index = i + binCount / 2;

        let offset = (scope.width * (binCount / (binCount - 1)) * i) / binCount;

        let yc = gainPercentage * props.timeDomainR[index] * 200;
        let xc = gainPercentage * props.timeDomainL[index] * 200;

        //quick scaling
        yc = yc / gainPercentage;
        xc = xc / gainPercentage;

        yc += scope.height / 2;
        xc += scope.width / 2;

        drawScope.lineTo(xc, yc);
      }

      drawScope.stroke();
      drawScope.strokeStyle = "rgba(174,244,218,0.3)";
      drawScope.lineWidth = 3;
      drawScope.stroke();
      drawScope.strokeStyle = "rgba(174,244,218,0.3)";
      drawScope.lineWidth = 4;
      drawScope.stroke();
      requestAnimationFrame(drawScopeCanvas);
    }
  };

  function animate() {
    // vectorAnalyser.getFloatTimeDomainData(props.timeDomainL, props.timeDomainR);

    window.requestAnimationFrame(animate);

    drawScopeCanvas();
  }

  // drawScopeCanvas();
  // animate();

  useEffect(() => {
    // console.log(props.timeDomainL)
    // console.log("fundamentalInfo useEffect", fundamentalInfo)
    // frameCountId = requestAnimationFrame(drawScopeCanvas);
    // scopeRef.current = frameCountId;
    // console.log(frameRef.current)
    // return () => cancelAnimationFrame(frameCountId);
    drawScopeCanvas();
  }, [props.timeDomainL]);

  const chartToImg = () => {
    const scale = 3;
    const node = scopeRef.current;
    const imgNode = imgRef.current;
    // console.log(imgNode.childNodes.length)

    const style = {
      transform: "scale(" + scale + ")",
      transformOrigin: "top left",
      width: node.offsetWidth + "px",
      height: node.offsetHeight + "px",
    };

    const param = {
      // height: node.offsetHeight * scale,
      // width: node.offsetWidth * scale,
      quality: 1,
      // style
    };

    domtoimage
      .toJpeg(node, param)
      .then(function (dataUrl) {
        let img = new Image();
        img.src = dataUrl;
        let imgContainer = new DOMParser().parseFromString('<span class="capture-preview ant-col-24"></span>', "text/html");
        imgContainer.className = "ant-col-6";
        // console.log(imgContainer.body.firstChild)
        imgContainer.body.firstChild.appendChild(img);
        if (imgNode.childNodes.length > 0) {
          imgNode.prepend(imgContainer.body.firstChild);
          // imgNode.replaceChild(img, imgNode.childNodes[0]);
        } else {
          imgNode.appendChild(imgContainer.body.firstChild);
        }
      })
      .catch(function (error) {
        console.error("oops, something went wrong!", error);
      });
  };

  return <canvas id="scope-canvas" ref={scopeRef} width="400" height="400" />;
});

export default Vectorscope;
