import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateFundamentalInfo,
  updateFundamentalStatus,
  updateFundamentalDevice,
  updateColorWindowStatus,
  updateSelectedLanguage,
  updateSelectedDialect,
  updateHappyPlace
} from '../store/channels/lsvParams';

import {
  Row,
  Col,
  Card,
  Collapse,
  Avatar,
  Tooltip,
  Space,
  Form,
  Upload,
  Modal,
  Alert,
  Select,
  Tag,
  Button,
  List,
  Input,
  Spin
} from 'antd';

import 'antd/dist/antd.min.css';
import './fundamentalizer.css';

import {
  InboxOutlined,
  CloseCircleOutlined,
  PlusOutlined,
  RightOutlined,
  DownOutlined,
  AudioOutlined,
  AudioMutedOutlined,
  EditOutlined,
  SmileOutlined,
  LoadingOutlined
} from '@ant-design/icons';

import { RealityMgmtUser } from '../models/userModel';
import {
    REALITY_MGMT_JWT_TOKEN,
    REALITY_MGMT_UID,
    api_url,
    base_url,
    auth_url,
    login_url,
    users_url,
    account_url,
    cards_url,
    signup_url,
    sessionData_url
} from '../shared/constants';

const { Meta } = Card;
const { Panel } = Collapse;
const { Dragger } = Upload;
const { confirm } = Modal;
const { Option } = Select;
const { TextArea } = Input;

function SpeechTranscription(props) {
    const [finalTranscript, setFinalTranscript] = useState('');
    const [interimTranscript, setInterimTranscript] = useState('');
    const [finalVisibility, setFinalVisibility] = useState(true);
    const [speechTimingArray, setSpeechTimingArray] = useState([]);
    const [happyPlaceTranscript, setHappyPlaceTranscript] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [editVisible, setEditVisible] = useState(false);
    const [shouldClear, setShouldClear] = useState(true);
    const [firstLoad, setFirstLoad] = useState(true);

    const selectedLanguage = useSelector(state => state.lsvParams.selectedLanguage);
    const selectedDialect = useSelector(state => state.lsvParams.selectedDialect);
    const fundamentalizerStatus = useSelector(state => state.lsvParams.fundamentalizerStatus);
    const happyPlaceInfo = useSelector(state => state.lsvParams.happyPlaceInfo);
    const dispatch = useDispatch();

    // Create a ref and update it with the latest happyPlaceInfo after every render
    const happyPlaceInfoRef = useRef();

    useEffect(() => {
        happyPlaceInfoRef.current = happyPlaceInfo;
    }, [happyPlaceInfo]);

    let speechRecognition;

    let languageArray = [
        ['Afrikaans', ['af-ZA']],
        ['Bahasa Indonesia', ['id-ID']],
        ['Bahasa Melayu', ['ms-MY']],
        ['Català', ['ca-ES']],
        ['Čeština', ['cs-CZ']],
        ['Deutsch', ['de-DE']],
        ['English', ['en-AU', 'Australia'],
            ['en-CA', 'Canada'],
            ['en-IN', 'India'],
            ['en-NZ', 'New Zealand'],
            ['en-ZA', 'South Africa'],
            ['en-GB', 'United Kingdom'],
            ['en-US', 'United States']],
        ['Español', ['es-AR', 'Argentina'],
            ['es-BO', 'Bolivia'],
            ['es-CL', 'Chile'],
            ['es-CO', 'Colombia'],
            ['es-CR', 'Costa Rica'],
            ['es-EC', 'Ecuador'],
            ['es-SV', 'El Salvador'],
            ['es-ES', 'España'],
            ['es-US', 'Estados Unidos'],
            ['es-GT', 'Guatemala'],
            ['es-HN', 'Honduras'],
            ['es-MX', 'México'],
            ['es-NI', 'Nicaragua'],
            ['es-PA', 'Panamá'],
            ['es-PY', 'Paraguay'],
            ['es-PE', 'Perú'],
            ['es-PR', 'Puerto Rico'],
            ['es-DO', 'República Dominicana'],
            ['es-UY', 'Uruguay'],
            ['es-VE', 'Venezuela']],
        ['Euskara', ['eu-ES']],
        ['Français', ['fr-FR']],
        ['Galego', ['gl-ES']],
        ['Hrvatski', ['hr_HR']],
        ['IsiZulu', ['zu-ZA']],
        ['Íslenska', ['is-IS']],
        ['Italiano', ['it-IT', 'Italia'],
            ['it-CH', 'Svizzera']],
        ['Magyar', ['hu-HU']],
        ['Nederlands', ['nl-NL']],
        ['Norsk bokmål', ['nb-NO']],
        ['Polski', ['pl-PL']],
        ['Português', ['pt-BR', 'Brasil'],
            ['pt-PT', 'Portugal']],
        ['Română', ['ro-RO']],
        ['Slovenčina', ['sk-SK']],
        ['Suomi', ['fi-FI']],
        ['Svenska', ['sv-SE']],
        ['Türkçe', ['tr-TR']],
        ['български', ['bg-BG']],
        ['Pусский', ['ru-RU']],
        ['Српски', ['sr-RS']],
        ['한국어', ['ko-KR']],
        ['中文', ['cmn-Hans-CN', '普通话 (中国大陆)'],
            ['cmn-Hans-HK', '普通话 (香港)'],
            ['cmn-Hant-TW', '中文 (台灣)'],
            ['yue-Hant-HK', '粵語 (香港)']],
        ['日本語', ['ja-JP']],
        ['Lingua latīna', ['la']]
    ];

    let final_transcript = "";
    let interim_transcript = "";
    let happy_place_transcript = "";
    let speechTimingCurrent = [];

    const getPromptColors = (fundamentalCountArray) => {
        let arr = fundamentalCountArray;
        arr.sort((a, b) => b.count - a.count);
        let topCounts = [...new Set(arr.map(obj => obj.count))].slice(0, 2);
        let top2Values = arr.filter(obj => topCounts.includes(obj.count));
        let colorStr = top2Values.map(obj => obj.color).join(', ');

        return colorStr;
    }


    const generateSkybox = async (description) => {
        const url = 'https://happy-place.onrender.com/generateSkybox';
        console.log(description);
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ description: description, webhook_url: 'https://happy-place.onrender.com/skyboxUpdate', socket: props.socketID })
        });

        if (response.ok) {
            const data = await response.json();
            console.log(data);
            setEditVisible(false);
            setHappyPlaceTranscript(data.prompt);
            dispatch(updateHappyPlace({ ...happyPlaceInfo, prompt: data.prompt }))
        } else {
            console.error('Error:', response.status, response.statusText);
        }
    }

    const speechRecognitionInit = () => {
        console.log("initializing speech recognition")
        // Callback Function for the onStart Event
        speechRecognition.onstart = () => {
            console.log("starting capture")
            // Show the Status Element
            // document.querySelector("#status").style.display = "block";
        };
        speechRecognition.onerror = () => {
            // Hide the Status Element
            // document.querySelector("#status").style.display = "none";
        };
    
        speechRecognition.onend = () => {
            // Hide the Status Element
            console.log("capture ended");
            return;
            // document.querySelector("#status").style.display = "none";
            // if (fundamentalizerStatus === "enabled") {
                // speechRecognition.start();
            // }
        };
        speechRecognition.onspeechend = () => {
            // Hide the Status Element
            console.log("speech ended");
            return;
            // document.querySelector("#status").style.display = "none";
            // if (fundamentalizerStatus === "enabled") {
                // speechRecognition.start();
            // }
        };
        speechRecognition.onsoundend = () => {
            // Hide the Status Element
            console.log("sound ended");
            return;
            // document.querySelector("#status").style.display = "none";
            // if (fundamentalizerStatus === "enabled") {
                // speechRecognition.start();
            // }
        };
        
        speechRecognition.onresult = (event) => {
            // speechRecognition.onend = null;
            // Create the interim transcript string locally because we don't want it to persist like final transcript
            console.log({shouldClear})
            if (shouldClear === true) {
                interim_transcript = "";
                setShouldClear(false);
            }
                // Loop through the results from the speech recognition object.
                for (let i = event.resultIndex; i < event.results.length; ++i) {
                    // If the result item is Final, add it to Final Transcript, Else add it to Interim transcript
                    if (event.results[i].isFinal) {
                        final_transcript += event.results[i][0].transcript;
                        speechTimingCurrent.push({transcript: event.results[i][0].transcript, confidence: event.results[i][0].confidence, timeStamp: event.timeStamp})
                        
                        if (happyPlaceInfoRef.current.capture) {
                            happy_place_transcript += event.results[i][0].transcript;
                        }

                        setFinalVisibility(true);
                    } else {
                        interim_transcript += event.results[i][0].transcript;
                        setFinalVisibility(false);
                    }
                setSpeechTimingArray(speechTimingCurrent);
                setFinalTranscript(final_transcript);
                setInterimTranscript(interim_transcript);
                setHappyPlaceTranscript(happy_place_transcript);
            }


        };
        setFirstLoad(false);
    }

    useEffect(() => {
        if (props.active === true && firstLoad === true) {
            setShouldClear(true);
        } else if (props.active !== true) {
            setShouldClear(true)
        }
    }, [props.active, firstLoad])

    useEffect(() => {
        console.log("speechRecognition", speechRecognition)
    // if (speechRecognition !== undefined) {

      // Speech Recognition Stuff goes here
        if (props.active === true) {
            if (window.SpeechRecognition || window.webkitSpeechRecognition) {
                // Initialize webkitSpeechRecognition
                speechRecognition = new window.webkitSpeechRecognition();

                speechRecognitionInit();
                // String for the Final Transcript
                // let final_transcript = "";
                speechRecognition.lang = selectedDialect[0];
                // Set the properties for the Speech Recognition object
                speechRecognition.continuous = true;
                speechRecognition.interimResults = true;
                // speechRecognition.lang = ""

                speechRecognition.start();
                console.log(speechRecognition)
            
            } else {
              console.log("Speech Recognition Not Available")
            }
          
        } else {
            if (speechRecognition !== undefined && props.active === 'reset') {
                speechRecognition.stop();
            }
        }
    // }
    
  }, [props.active])

    const handleLanguageChange = (value) => {
        dispatch(updateSelectedLanguage(value));
    }

    const handleDialectChange = (value) => {
        dispatch(updateSelectedDialect(value));
    }

    const happyPlaceTitle = () => {
        let title = happyPlaceInfo.capture === false ? 
                                    <>Press
                                        <Button 
                                            type="primary"
                                            disabled={fundamentalizerStatus === "enabled" ? false : true}
                                            /*style={fundamentalizerStatus !== "enabled" ? {} : (happyPlaceInfo.capture === true ? { background: "#52c41a", borderColor: "#52c41a" } : { background: "#f5222d", borderColor: "#f5222d" })}*/
                                            onClick={() => dispatch(updateHappyPlace({ ...happyPlaceInfo, capture: true }))}
                                            icon={<AudioOutlined />}
                                        /> and describe your happy place</> : 
                                    <>Press 
                                        <Button 
                                            type="primary"
                                            disabled={fundamentalizerStatus === "enabled" ? false : true}
                                            /*style={fundamentalizerStatus !== "enabled" ? {} : (happyPlaceInfo.capture === true ? { background: "#52c41a", borderColor: "#52c41a" } : { background: "#f5222d", borderColor: "#f5222d" })}*/
                                            onClick={() => dispatch(updateHappyPlace({ ...happyPlaceInfo, capture: false }))}
                                            icon={<AudioMutedOutlined />}
                                        />  to stop</>
        return  <Row>
                    <Space>
                        {title}
                    </Space>
                </Row>
    }

    const happyPlaceDescription = () => {
        console.log({editVisible, happyPlaceTranscript}, happyPlaceInfo?.prompt)
        return  <>
                    <Col span={24}>
                        <Row justify="start">
                            <Col span={24}>
                                {happyPlaceTranscript.length ? 
                                    (editVisible ?
                                            <TextArea
                                                style={{ width: "100%", borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                                                defaultValue={happyPlaceTranscript}
                                                onChange={(e) => dispatch(updateHappyPlace({ ...happyPlaceInfo, prompt: e.target.value }))}
                                                placeholder="Happy Place Transcript"
                                                autoSize={{
                                                    minRows: 3,
                                                    maxRows: 5,
                                                }}
                                            /> : <>{happyPlaceInfo?.prompt || happyPlaceTranscript}</>
                                    ) : 
                                (happyPlaceInfo.capture && <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />)}
                            </Col>
                        </Row>
                    </Col>
                </>
    }

    const showHappyPlaceModal = () => {
      confirm({
        title: happyPlaceTitle(),
        icon: <SmileOutlined style={{ color: '#52c41a', display: 'hidden' }} />,
        content: happyPlaceDescription(),
        okText: 'Yes',
        okType: 'danger',
        centered: true,
        okButtonProps: {
          disabled: true,
        },
        cancelText: 'No',
        onOk() {
          return new Promise((resolve, reject) => {
            setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
          }).catch(() => console.log('Oops errors!'));
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    }

  return (
    <>
        <Col span={24}>
            <br/>
            <Select
              value={selectedLanguage}
              placeholder="Select Language"
              style={{
                width: 180,
              }}
              onChange={handleLanguageChange}
            >
                {languageArray.map(l =>
                   <Option key={l[0]} value={l[0]}>{l[0]}</Option>
                    )
                }
              
            </Select>
            {languageArray[languageArray.findIndex(d => d[0] === selectedLanguage)].length > 1 &&
                <Select
                  value={selectedDialect}
                  placeholder="Select Dialect"
                  style={{
                    width: 180,
                  }}
                  onChange={handleDialectChange}
                >
                    {languageArray[languageArray.findIndex(d => d[0] === selectedLanguage)].slice(1).map(d =>
                       <Option key={d[0]} value={d[0]}>{d.length > 1 ? d[1] : d[0]}</Option>
                        )
                    }
                </Select>
            }
            <>
                <Button type="primary" onClick={() => setModalOpen(true)}>
                    HAPPY PLACE
                </Button>
                <Modal
                    title={happyPlaceTitle()}
                    centered
                    open={modalOpen}
                    okText={'Take Me To My Happy Place!'}
                    okButtonProps={{
                        disabled: editVisible === true || happyPlaceInfo.capture === true || happyPlaceTranscript.length === 0
                    }}
                    onOk={() => {
                        setModalOpen(false);
                        generateSkybox((happyPlaceInfo.prompt.length ? happyPlaceInfo.prompt : happyPlaceTranscript) + ' ' + getPromptColors(props.fundamentalNoteCount));
                    }}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    onCancel={() => setModalOpen(false)}
                    width={500}
                >
                    <p>
                    {happyPlaceDescription()}
                    {happyPlaceTranscript.length !== 0 && happyPlaceInfo.capture === false &&
                        <Row style={{ fontWeight: 600, paddingTop: 16 }}>
                            <Space>
                                <Button type="primary" disabled={happyPlaceTranscript.length === 0 || happyPlaceInfo.capture === true} className="happy-place" onClick={() => setEditVisible(!editVisible)}>
                                    <EditOutlined /> {editVisible ? "Finish Editing" : "Edit Transcript?"}
                                </Button>
                            </Space>
                        </Row>
                    }
                    </p>
                </Modal>
            </>
        </Col>
    </>
  );
}

export default SpeechTranscription;