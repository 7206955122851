// ****************************************************************
//
// This file is subject to the terms and conditions defined in file
// 'LICENSE.txt', which can be found at the root of this project.
//
// ****************************************************************

import {combineReducers} from 'redux';

import sessionParams from './session/sessionData';
import lsvParams from './channels/lsvParams';

const rootReducer = combineReducers({
  // Define a top-level state field named `todos`, handled by `todosReducer`
  lsvParams,
  sessionParams
})

export default rootReducer;