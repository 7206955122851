// ****************************************************************
//
// This file is subject to the terms and conditions defined in file
// 'LICENSE.txt', which can be found at the root of this project.
//
// ****************************************************************

const UPDATE_FUNDAMENTAL_STATUS = "UPDATE_FUNDAMENTAL_STATUS";

export function updateFundamentalStatus(value) {
	return {
		type: UPDATE_FUNDAMENTAL_STATUS,
		value,
	}
}

const UPDATE_FUNDAMENTAL_DEVICE = "UPDATE_FUNDAMENTAL_DEVICE";

export function updateFundamentalDevice(value) {
	return {
		type: UPDATE_FUNDAMENTAL_DEVICE,
		value,
	}
}

const UPDATE_FUNDAMENTAL_INFO = 'UPDATE_FUNDAMENTAL_INFO';

export function updateFundamentalInfo(value) {
	return {
		type: UPDATE_FUNDAMENTAL_INFO,
		value,
	}
}

const UPDATE_SPEECH_ARAY = "UPDATE_SPEECH_ARAY";

export function updateSpeechArray(value) {
	return {
		type: UPDATE_SPEECH_ARAY,
		value,
	}
}

const UPDATE_CURRENT_SPEECH_ARAY = "UPDATE_CURRENT_SPEECH_ARAY";
export function updateCurrentSpeechArray(value) {
	return {
		type: UPDATE_CURRENT_SPEECH_ARAY,
		value,
	}
}

const UPDATE_NOTE_COUNT = 'UPDATE_NOTE_COUNT';

export function updateNoteCount(value) {
	return {
		type: UPDATE_NOTE_COUNT,
		value,
	}
}

const UPDATE_COLOR_WINDOW_STATUS = 'UPDATE_COLOR_WINDOW_STATUS';

export function updateColorWindowStatus(value) {
	return {
		type: UPDATE_COLOR_WINDOW_STATUS,
		value,
	}
}

const UPDATE_SELECTED_LANGUAGE = 'UPDATE_SELECTED_LANGUAGE';

export function updateSelectedLanguage(value) {
	return {
		type: UPDATE_SELECTED_LANGUAGE,
		value,
	}
}

const UPDATE_SELECTED_DIALECT = 'UPDATE_SELECTED_DIALECT';

export function updateSelectedDialect(value) {
	return {
		type: UPDATE_SELECTED_DIALECT,
		value,
	}
}

const UPDATE_SELECTED_BRAINSTATE = 'UPDATE_SELECTED_BRAINSTATE';

export function updateSelectedBrainstate(value) {
	return {
		type: UPDATE_SELECTED_BRAINSTATE,
		value,
	}
}

const UPDATE_HAPPY_PLACE = 'UPDATE_HAPPY_PLACE';

export function updateHappyPlace(value) {
	return {
		type: UPDATE_HAPPY_PLACE,
		value,
	}
}

const defaultParams = {
	fundamentalizerStatus: "disabled",
	fundamentalizerDevice: "input",
	fundamentalizer: {
		frequency: 0,
		harmonicsArray: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
		nearestNote: { 
			note: "", 
			frequency: 0 
		},
		cents: 0,
		visibleFrequency: 0,
		wavelength: 0,
		colorSpace: {
			rgb: 'rgb(0%, 0%, 0%)',
			red: 0,
			green: 0,
			blue: 0,
			alpha: 0
		},
		fundamentalNoteCount: [
	    { note: 'C', count: 0 },
	    { note: 'C#', count: 0 },
	    { note: 'D', count: 0 },
	    { note: 'D#', count: 0 },
	    { note: 'E', count: 0 },
	    { note: 'F', count: 0 },
	    { note: 'F#', count: 0 },
	    { note: 'G', count: 0 },
	    { note: 'G#', count: 0 },
	    { note: 'A', count: 0 },
	    { note: 'A#', count: 0 },
	    { note: 'B', count: 0 }
	  ],
	  highestCount: 0,
	  totalNoteCount: 0,
    fundamentalAverage: {
      note: "--",
      frequency: 0
    },
    fundamentalMode: {
      note: "--",
      frequency: 0
    },
	},
  fundamentalNoteCount: [
    { note: 'C', count: 0 },
    { note: 'C#', count: 0 },
    { note: 'D', count: 0 },
    { note: 'D#', count: 0 },
    { note: 'E', count: 0 },
    { note: 'F', count: 0 },
    { note: 'F#', count: 0 },
    { note: 'G', count: 0 },
    { note: 'G#', count: 0 },
    { note: 'A', count: 0 },
    { note: 'A#', count: 0 },
    { note: 'B', count: 0 }
  ],
  colorWindowOpen: false,
  selectedLanguage: 'English',
  selectedDialect: ['en-US', 'United States'],
  brainstateList: [
  		{ name: 'Delta', fqDelta: 3, description: 'Sleep' },
  		{ name: 'Theta', fqDelta: 6, description: 'Dream' },
  		{ name: 'Alpha', fqDelta: 9, description: 'Flow' },
  		{ name: 'Beta', fqDelta: 18, description: 'Energize' },
  		{ name: 'Gamma', fqDelta: 40, description: 'Focus' }
  ],
  selectedBrainstate: { name: 'Theta', fqDelta: 6, description: 'Dream' },
  speechTranscript: '',
  currentSpeechTranscript: '',
	happyPlaceInfo: {
		capture: false,
		prompt: '',
		status: '',
		image: ''
	}
};

export default function lsvParams(state=defaultParams, action) {
	const noteSelection = state.fundamentalNoteCount.find(n => action.param === n.note);
	const noteExclusion = state.fundamentalNoteCount.filter(n => action.param !== n.note);

	switch (action.type) {
		case UPDATE_FUNDAMENTAL_STATUS:
			return {
				...state,
				fundamentalizerStatus: action.value
			};
		case UPDATE_FUNDAMENTAL_DEVICE:
			return {
				...state,
				fundamentalizerDevice: action.value
			};
		case UPDATE_FUNDAMENTAL_INFO:
			return {
				...state,
				fundamentalizer: action.value
			};
		case UPDATE_NOTE_COUNT:
			return {
				...state,
				fundamentalNoteCount: action.value
			};
		case UPDATE_COLOR_WINDOW_STATUS:
			return {
				...state,
				colorWindowOpen: action.value
			};
		case UPDATE_SELECTED_LANGUAGE:
			return {
				...state,
				selectedLanguage: action.value
			}
		case UPDATE_SELECTED_DIALECT:
			return {
				...state,
				selectedDialect: action.value
			}
		case UPDATE_SELECTED_BRAINSTATE:
			return {
				...state,
				selectedBrainstate: action.value
			}
		case UPDATE_HAPPY_PLACE:
			return {
				...state,
				happyPlaceInfo: action.value
			}

		case UPDATE_SPEECH_ARAY:
			// console.log("UPDATE_SPEECH_ARAY", action.value);
			return {
				...state,
				speechTranscript: action.value
			}
		case UPDATE_CURRENT_SPEECH_ARAY:
			// console.log("UPDATE_SPEECH_ARAY", action.value);
			return {
				...state,
				currentSpeechTranscript: action.value
			}
		default:
			return state;
	}
}