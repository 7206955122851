// ****************************************************************
//
// This file is subject to the terms and conditions defined in file
// 'LICENSE.txt', which can be found at the root of this project.
//
// ****************************************************************

import * as THREE from 'three';
import { Suspense } from "react";
import { Canvas, useThree, useLoader } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";
import { TextureLoader } from "three";

const Panorama = ({ img }) => {
  const { scene } = useThree();
  const texture = useLoader(TextureLoader, img);
  texture.mapping = THREE.EquirectangularReflectionMapping;
  texture.encoding = THREE.sRGBEncoding;
  texture.flipX = false;
  scene.background = texture;
  return null;
};

export default function PanoViewer(props) {
  return (
    <Canvas 
      style={{
        height: "100vh",
        width: "100vw",
        display: "grid",
        justifyItems: "center",
        alignItems: "center",
        top: "0",
        left: "0"
      }}>
      <OrbitControls 
        autoRotate
        enableZoom={false}
        enablePan={false}
        dampingFactor={0.1}
        rotateSpeed={-0.4}
        autoRotateSpeed={0.4}  // lower this value for slower automatic rotation
      />
      <Suspense fallback={null}>
        <Panorama img={props.img} />
      </Suspense>
    </Canvas>
  );
}
