import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { 
  updateFundamentalInfo, 
  updateFundamentalStatus, 
  updateFundamentalDevice, 
  updateColorWindowStatus, 
  updateSelectedBrainstate,
  updateHappyPlace
} from "../store/channels/lsvParams";

import { Row, Col, Input, Card, Collapse, Avatar, Tooltip, Space, Form, Upload, Modal, Alert, Select, Tag, Button, List, Spin } from "antd";
import { MenuUnfoldOutlined, MenuFoldOutlined, EyeInvisibleOutlined, EyeTwoTone, UnorderedListOutlined, AppstoreTwoTone, SoundTwoTone, FundTwoTone, ControlTwoTone, SettingTwoTone, LogoutOutlined, SoundOutlined, BackwardFilled, PauseCircleFilled, PlayCircleFilled, ForwardFilled, BuildTwoTone } from "@ant-design/icons";
import domtoimage from "dom-to-image";
import { saveAs } from "file-saver";
import QRCode from "qrcode.react";
import { Storage } from "megajs";
import { Dropbox, DropboxAuth } from "dropbox";

import Vectorscope from "./vectorscope";
import SpeechTranscription from "./speechTranscription";
import RCShader from './rcShader';
import PanoViewer from "./panorama";

import "antd/dist/antd.min.css";
import "./fundamentalizer.css";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/storage";

import { InboxOutlined, CloseCircleOutlined, ExclamationCircleOutlined, PlusOutlined, RightOutlined, DownOutlined, AudioOutlined, CameraOutlined } from "@ant-design/icons";

import { RealityMgmtUser } from "../models/userModel";
import { REALITY_MGMT_JWT_TOKEN, DEFAULT_ORG, REALITY_MGMT_UID, api_url, users_v1_url, base_url, auth_url, login_url, harmonicsLabel, harmonicsLabelMobile, notesArray, users_url, account_url, newUser_url, cards_url, signup_url, notesJson, defaultFundamentalizerInfo, sessionData_url } from "../shared/constants";
import { FundamentalizerData } from "../models/fundamentalizerModel";
import { sessionStateInit, getUsersSuccess, selectUserSuccess, addUserSuccess, logoutSuccess, startSessionSuccess } from "../store/session/sessionData";

import { recordSession } from "../services/accountApi";

const StereoAnalyserNode = require("stereo-analyser-node");

const { Meta } = Card;
const { Panel } = Collapse;
const { Dragger } = Upload;
const { confirm } = Modal;
const { Option } = Select;
var user;
let firebaseApp = null;
let auth = null;
const firebaseConfig = {
  apiKey: "AIzaSyDniHnvuDPyKOPZBqbmOMMDTkr0oCaji64",
  authDomain: "innersense-server.firebaseapp.com",
  databaseURL: "https://innersense-server.firebaseio.com",
  projectId: "innersense-server",
  storageBucket: "innersense-server.appspot.com",
  messagingSenderId: "469401836773",
  appId: "1:469401836773:web:d73426efeed6a49416480c",
};
// Initialize Firebase
let app = null;
if (!firebase.apps.length) {
  app = firebase.initializeApp(firebaseConfig);
  auth = firebase.auth();
} else {
  auth = firebase.auth();
}
var storageRef = firebase.storage().ref();

let fftSize = 4096;
let SAMPLERATE = 48000;
let nyquist = SAMPLERATE / 2;
let binCount = fftSize / 2;
let freqResolution = nyquist / binCount; // 24000/2048 = 11.71875Hz per bar
let timeDomainArray = new Uint8Array(fftSize);
let freqDomainArray = new Uint8Array(fftSize);
// let contextInput;
let inputDevice;
let frameCountId = 0;

let contextInput = new AudioContext({
  latencyHint: "interactive",
  sampleRate: SAMPLERATE,
});

let contextOutput = new AudioContext({
  latencyHint: "interactive",
  sampleRate: SAMPLERATE,
});

let vectorAnalyser = new StereoAnalyserNode(contextOutput);
vectorAnalyser.fftSize = fftSize;

let timeDomainL = new Float32Array(fftSize);
let timeDomainR = new Float32Array(fftSize);

let oscLeft = contextOutput.createOscillator();
let oscRight = contextOutput.createOscillator();

oscLeft.type = "sine";
oscRight.type = "sine";

let leftPanner = contextOutput.createStereoPanner();
let rightPanner = contextOutput.createStereoPanner();

leftPanner.pan.value = -1;
rightPanner.pan.value = 1;

let merger = contextOutput.createChannelMerger(2);
let delay = contextOutput.createDelay();

let gainNode = contextOutput.createGain();

let analyserNodeInput = contextInput.createAnalyser();
analyserNodeInput.fftSize = fftSize;
let bufferLength = analyserNodeInput.fftSize / 2;

let mediaRecorder;


/************************************************************ 
  Dropbox/Mega/QR Code logic
************************************************************/
// Constants for Dropbox OAuth
const APP_KEY = 'v76fn7dxwlqmhmd';
const APP_SECRET = 'rzkb18awh9f6862';
const REDIRECT_URI = 'https://mantramachine.xyz'; //'http://localhost:3000';

// Helper functions for token management
const getAccessToken = () => localStorage.getItem('dropboxAccessToken');
const getRefreshToken = () => localStorage.getItem('dropboxRefreshToken');
const storeAccessToken = token => localStorage.setItem('dropboxAccessToken', token);
const storeRefreshToken = token => localStorage.setItem('dropboxRefreshToken', token);
const clearTokens = () => {
  localStorage.removeItem('dropboxAccessToken');
  localStorage.removeItem('dropboxRefreshToken');
};

// Function to initiate the OAuth flow
const initiateDropboxAuth = async () => {
  const dbxAuth = new DropboxAuth({ clientId: APP_KEY });
  const authUrl = await dbxAuth.getAuthenticationUrl(REDIRECT_URI, null, 'code', 'offline', null, 'none', false);
  window.location.href = authUrl;
};

// Function to exchange authorization code for access and refresh tokens
const getTokenFromCode = async (code) => {
  try {
    const response = await fetch('https://api.dropboxapi.com/oauth2/token', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams({
        code,
        grant_type: 'authorization_code',
        client_id: APP_KEY,
        client_secret: APP_SECRET,
        redirect_uri: REDIRECT_URI
      })
    });

    if (response.ok) {
      const data = await response.json();
      storeAccessToken(data.access_token);
      storeRefreshToken(data.refresh_token);
      return data.access_token;
    } else {
      console.error('Error getting tokens');
      return null;
    }
  } catch (error) {
    console.error('Error getting tokens:', error);
    return null;
  }
};

// Function to refresh access token
const refreshAccessToken = async () => {
  const refreshToken = getRefreshToken();
  if (!refreshToken) {
    initiateDropboxAuth();
    return null;
  }

  try {
    const response = await fetch('https://api.dropboxapi.com/oauth2/token', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams({
        grant_type: 'refresh_token',
        refresh_token: refreshToken,
        client_id: APP_KEY,
        client_secret: APP_SECRET
      })
    });

    if (response.ok) {
      const data = await response.json();
      storeAccessToken(data.access_token);
      return data.access_token;
    } else {
      console.error('Error refreshing access token');
      initiateDropboxAuth();
      return null;
    }
  } catch (error) {
    console.error('Error refreshing access token:', error);
    initiateDropboxAuth();
    return null;
  }
};

// Function to upload image to Dropbox
const uploadImageToDropbox = async (dataUrl, fileName) => {
  let accessToken = getAccessToken();
  if (!accessToken) {
    accessToken = await refreshAccessToken();
    if (!accessToken) {
      return;
    }
  }

  const blob = await fetch(dataUrl).then(res => res.blob());
  const dropbox = new Dropbox({ accessToken });

  try {
    const response = await dropbox.filesUpload({
      path: `/MANTRA-Turkey/${fileName}`,
      contents: blob,
      mode: 'overwrite'
    });

    const sharedLinkResponse = await dropbox.sharingCreateSharedLinkWithSettings({
      path: response.result.path_lower,
      settings: { requested_visibility: 'public' }
    });

    // Ensure the URL points directly to the file
    const url = sharedLinkResponse.result.url.replace('www.dropbox.com', 'dl.dropboxusercontent.com').replace('?dl=0', '');

    return url;
  } catch (error) {
    if (error.status === 401) {
      accessToken = await refreshAccessToken();
      if (!accessToken) {
        return;
      }
      const dropbox = new Dropbox({ accessToken });

      try {
        const response = await dropbox.filesUpload({
          path: `/MANTRA-Turkey/${fileName}`,
          contents: blob,
          mode: 'overwrite'
        });

        const sharedLinkResponse = await dropbox.sharingCreateSharedLinkWithSettings({
          path: response.result.path_lower,
          settings: { requested_visibility: 'public' }
        });

        // Ensure the URL points directly to the file
        const url = sharedLinkResponse.result.url.replace('www.dropbox.com', 'dl.dropboxusercontent.com').replace('?dl=0', '');

        return url;
      } catch (retryError) {
        console.error('Error uploading file after refreshing token:', retryError);
      }
    } else {
      console.error('Error uploading file:', error);
    }
  }
};


const Fundamentalizer = React.memo(function Fundamentalizer(props) {
  const dispatch = useDispatch();

  /************************************************************ 
    Selector Based
  ************************************************************/
  const fundamentalizerStatus = useSelector((state) => state.lsvParams.fundamentalizerStatus);
  const fundamentalizerDevice = useSelector((state) => state.lsvParams.fundamentalizerDevice);
  const fundamentalInfo = useSelector((state) => state.lsvParams.fundamentalizer);
  const fundamentalNoteCount = useSelector((state) => state.lsvParams.fundamentalizer.fundamentalNoteCount);
  const colorWindowOpen = useSelector((state) => state.lsvParams.colorWindowOpen);

  const brainstateList = useSelector((state) => state.lsvParams.brainstateList);
  const selectedBrainstate = useSelector((state) => state.lsvParams.selectedBrainstate);
  const happyPlaceInfo = useSelector(state => state.lsvParams.happyPlaceInfo);

  const sessionUser = useSelector((state) => state.sessionParams.user);
  const isBldgAdmin = useSelector((state) => state.sessionParams.isBldgAdmin);
  const userOrg = useSelector((state) => state.sessionParams.org);
  const isOrgAdmin = useSelector((state) => state.sessionParams.isOrgAdmin);
  const fundamentalInfoRef = useSelector((state) => state.lsvParams.fundamentalizer);
  const sessionID = useSelector((state) => state.sessionParams.sessionID);
  const selectedUser = useSelector((state) => state.sessionParams.selectedUser);
  const usersArray = useSelector((state) => state.sessionParams.users);
  const orgs = useSelector((state) => state.sessionParams.orgs);

  /************************************************************ 
    State Based
  ************************************************************/
  const [binauralPlaying, setBinauralPlaying] = useState(false);
  const [binauralFrequency, setBinauralFrequency] = useState([0, 0]);
  const [visColorSpectrumArray, setVisColorSpectrumArray] = useState([]);
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    goal: "",
    firstName: "",
    lastName: "",
  });
  const [modeArray, setModeArray] = useState([]);
  const [previousAvg, setPreviousAvg] = useState(0);

  const [colorArray, setColorArray] = useState([
    { note: "C", color: "rgb(0%,0%,0%)" },
    { note: "C#", color: "rgb(0%,0%,0%)" },
    { note: "D", color: "rgb(0%,0%,0%)" },
    { note: "D#", color: "rgb(0%,0%,0%)" },
    { note: "E", color: "rgb(0%,0%,0%)" },
    { note: "F", color: "rgb(0%,0%,0%)" },
    { note: "F#", color: "rgb(0%,0%,0%)" },
    { note: "G", color: "rgb(0%,0%,0%)" },
    { note: "G#", color: "rgb(0%,0%,0%)" },
    { note: "A", color: "rgb(0%,0%,0%)" },
    { note: "A#", color: "rgb(0%,0%,0%)" },
    { note: "B", color: "rgb(0%,0%,0%)" },
  ]);
  const [sortedUsers, setSortedUsers] = useState(usersArray);
  const [allOrgs, setAllOrgs] = useState(orgs);
  const [userRef, setUserRef] = useState({ email: "", firstName: "", lastName: "" });
  const [userModalVisible, setUserVisible] = useState(true);
  const [newUserModalVisible, setNewUserModalVisible] = useState(false);
  const [addUserModalVisible, setAddUserModalVisible] = useState(false);
  const [logOutVisible, setLogOutVisible] = useState(false);
  const [loginVisible, setLoginVisible] = useState(false);
  const types = [
    "types",
    "audio/webm;codecs=opus",
    "audio/mpeg3",
    "audio/wav",
    "audio/aiff",
    "audio/mpeg"
  ];
  const [message, setMessage] = useState('');
  const [qrCode, setQrCode] = useState(null);
  const [loading, setLoading] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);

  // const [fundamentalArray, setFundamentalArray] = useState([]);

  /************************************************************ 
    Ref Based
  ************************************************************/
  const formRef = useRef(null);
  const chartRef = useRef(null);
  const imgRef = useRef(null);
  const frameRef = useRef(null);
  const playerRef = useRef(null);
  const resetButton = useRef(null);
  const downloadLink = useRef(null);
  const canvasRef = useRef(null);
  const shinyRef = useRef(null);

  /************************************************************ 
    Other Variables/Constants
  ************************************************************/

  const appStorage = window.localStorage;
  let ALERT_TITLE = "Successfully Signed Up!";
  let ALERT_BUTTON_TEXT = "Thanks";
  let binColorSpectrumArray = [];
  var confirmPassword = "";
  let vectorscopeAnalyzer;
  let frequencyScale = 432;
  let frequencyScaleArray = notesJson[frequencyScale.toString()];
  let analysisArray = { input: null };
  let visArray = { input: null };
  let analyserTimeDomainArray;
  let analyserFreqDomainArray;
  let cachedFundamental = 0;
  let WIDTH = 2400;
  let HEIGHT = 180;
  let canvas;
  let drawContext;
  var creatingNewUser = false;
  // canvas.width = WIDTH;
  // canvas.height = HEIGHT;
  let shiny;
  let drawShiny;
  // Interesting parameters to tweak!
  let SMOOTHING = 0.8;

  var fundamentalizerInfo;
  var downloadURL = '';
  // let analyserNodeInput;
  // let analyserTimeDomainArray;

  const activateFundamentalizer = (status) => {
    // Fundamentalizer ("enabled", "disabled", "frozen")

    if (status === "enabled") {
      // window.api.send("canvasRequest", "disabled")
      dispatch(updateFundamentalStatus("disabled"));
    } else if (status === "disabled") {
      // window.api.send("canvasRequest", "enabled")
      dispatch(updateFundamentalStatus("enabled"));
      if (null != selectedUser) {
        var newSession = {
          date: new Date(Date.now()).getTime(),
          uid: selectedUser.uid,
          userName: `${selectedUser.firstName} ${selectedUser.lastName}`,
        };
        if ((null != sessionUser.uid) && (null != sessionUser.role) && (sessionUser.role !== 'User')) {
          newSession.operatorID = sessionUser.uid;
          newSession.operatorName = `${sessionUser.firstName} ${sessionUser.lastName}`;
        }
        startNewSession(newSession);
      }
    }

    return;
  };

  const resetFundamentalizer = () => {
    // window.api.send("canvasRequest", "reset")
    setTimeout(() => dispatch(updateFundamentalStatus("reset")), 250);
    setTimeout(() => dispatch(updateFundamentalInfo(defaultFundamentalizerInfo)), 250);
    // mediaRecorder.stop();
    setTimeout(() => dispatch(updateFundamentalStatus("disabled")), 250);
    setPreviousAvg(0);
    setModeArray([]);
    resetShinyCanvas();
    resetVisCanvas();
    clearScreenshot();
    return;
  };

  const fundamentalizerTriggerLabel = (status) => {
    if (status === "enabled") {
      return "FREEZE CAPTURE";
    } else if (status === "disabled") {
      return "START CAPTURE";
    } else {
      return "START CAPTURE";
    }
  };

  const openColorWindow = () => {
    dispatch(updateColorWindowStatus(true));
  };

  let findFundamentalFreq = async (buffer, sampleRate) => {
    let bufferIndex = 0;
    // We use Autocorrelation to find the fundamental frequency.

    // In order to correlate the signal with itself (hence the name of the algorithm), we will check two points 'k' frames away.
    // The autocorrelation index will be the average of these products. At the same time, we normalize the values.
    // Source: http://www.phy.mty.edu/~suits/autocorrelation.html
    // Assuming the sample rate is 48000Hz, a 'k' equal to 1000 would correspond to a 48Hz signal (48000/1000 = 48),
    // while a 'k' equal to 8 would correspond to a 6000Hz one, which is enough to cover most (if not all)
    // the notes we have in the notes.json file.
    let n = buffer.length / 2,
      bestR = 0,
      bestK = -1;
    // console.log("fundamentalBuffer", buffer.length)
    for (let k = 20; k <= 2400; k++) {
      // 20Hz - 24000Hz
      let sum = 0;

      for (let i = 0; i < n; i++) {
        sum += ((buffer[i] - 128) / 128) * ((buffer[i + k] - 128) / 128);
      }

      let r = sum / (n + k);

      if (r > bestR) {
        // console.log({r, bestR, k, bestK})
        bestR = r;
        bestK = k;
      }

      if (r > 0.9) {
        // Let's assume that this is good enough and stop right here
        break;
      }
    }

    if (bestR > 0.0025) {
      // (bestR > 0.0025)
      // The period (in frames) of the fundamental frequency is 'bestK'. Getting the frequency from there is trivial.
      let fundamentalFreq = sampleRate / bestK;

      // console.log("finding fundamental", fundamentalFreq)
      return fundamentalFreq;
    } else {
      // We haven't found a good correlation
      return -1;
    }
  };

  // 'notes' is an array of objects like { note: 'A4', frequency: 440 }.
  // See initialization in the source code of the demo
  const findClosestNote = (freq, notes) => {
    // Use binary search to find the closest note
    let low = -1,
      high = notes.length;
    while (high - low > 1) {
      let pivot = Math.round((low + high) / 2);
      if (notes[pivot].frequency <= freq) {
        low = pivot;
      } else {
        high = pivot;
      }
    }

    if (Math.abs(notes[high].frequency - freq) <= Math.abs(notes[low].frequency - freq)) {
      // notes[high] is closer to the frequency we found
      return notes[high];
    }

    return notes[low];
  };

  const findCentsOffPitch = (freq, refFreq) => {
    // We need to find how far freq is from baseFreq in cents
    const log2 = 0.6931471805599453; // Math.log(2)
    const multiplicativeFactor = freq / refFreq;

    // We use Math.floor to get the integer part and ignore decimals
    let cents = Math.floor(1200 * (Math.log(multiplicativeFactor) / log2));
    return cents;
  };

  const findMiddleOctaveNote = (note) => {
    let parsedOctaveNumber = note.note.replace(/^\D+/g, "");
    let middleOctaveNote = frequencyScaleArray.find((s) => s.note === note.note.replace(parsedOctaveNumber, "4"));

    return middleOctaveNote;
  };

  const convertToVisibleFrequency = (note) => {
    let visibleFrequency = note.frequency;

    for (let i = 0; i < 40; i++) {
      visibleFrequency = visibleFrequency * 2;
      // console.log(i+1, visibleFrequency);
    }
    let oct = 49 - Math.round(Math.log(note.frequency) / Math.log(2));
    let nm = (299792458 * Math.pow(10, 9)) / (note.frequency * Math.pow(2, oct));
    // console.log({oct, nm, visibleFrequency})
    return visibleFrequency;
  };

  const getWavelengthNm = (note) => {
    let oct = 49 - Math.round(Math.log(note.frequency) / Math.log(2));
    let nm = (299792458 * Math.pow(10, 9)) / (note.frequency * Math.pow(2, oct));
    return nm;
  };

  const createHarmonicsArray = (fundamentalFreq) => {
    // harmonics are integer multiples of fundamental frequency
    let harmonicValue = fundamentalFreq;
    let harmonicsArray = [];

    for (let i = 0; i < 12; i++) {
      harmonicValue = fundamentalFreq * (i + 1);

      harmonicsArray.push(harmonicValue);
      // console.log(harmonicsArray);
      // console.log(harmonicsArray.length);
    }

    return harmonicsArray;
  };

  const responsiveToFixed = (toFixed) => {
    let floatNum = toFixed;
    if (window.innerWidth < 685) {
      floatNum = toFixed - 3;
    } else if (window.innerWidth < 785) {
      floatNum = toFixed - 2;
    } else if (window.innerWidth < 875) {
      floatNum = toFixed - 1;
    } else if (window.innerWidth >= 875) {
      floatNum = toFixed;
    }
    return Math.max(floatNum, 0);
  };

  const convertFrequencyToWavelength = (freq) => {
    // λ = v/f (wavelength = velocity/frequency)
    // v = c = 299,792,458 m/s
    // Light in air or vacuum: 299,792,458 m/s
    // Light in water: 224,901,000 m/s
    // Sound in air: 343.2 m/s
    // Sound in water (20 °C): 1,481 m/s

    let c = 299792458;

    return (c / freq) * 1000000000; //convert to nanometers
  };

  // https://scienceprimer.com/javascript-code-convert-light-wavelength-color
  // takes wavelength in nm and returns an rgba value
  const wavelengthToColor = (wavelength) => {
    let r,
      g,
      b,
      factor,
      alpha,
      colorSpace,
      wl = wavelength,
      gamma = 0.8;

    if (wl >= 380 && wl < 440) {
      r = (-1 * (wl - 440)) / (440 - 380);
      g = 0;
      b = 1;
    } else if (wl >= 440 && wl < 490) {
      r = 0;
      g = (wl - 440) / (490 - 440);
      b = 1;
    } else if (wl >= 490 && wl < 510) {
      r = 0;
      g = 1;
      b = (-1 * (wl - 510)) / (510 - 490);
    } else if (wl >= 510 && wl < 580) {
      r = (wl - 510) / (580 - 510);
      g = 1;
      b = 0;
    } else if (wl >= 580 && wl < 645) {
      r = 1;
      g = (-1 * (wl - 645)) / (645 - 580);
      b = 0.0;
    } else if (wl >= 645 && wl <= 781) {
      //else if (wl >= 645 && wl <= 780)
      r = 1;
      g = 0;
      b = 0;
    } else {
      r = 0;
      g = 0;
      b = 0;
    }

    // let the intensity fall off near the vision limits
    if (wl >= 380 && wl < 420) {
      factor = 0.3 + (0.7 * (wl - 380)) / (420 - 380);
    } else if (wl >= 420 && wl < 701) {
      factor = 1.0;
    } else if (wl >= 701 && wl < 781) {
      factor = 0.3 + (0.7 * (780 - wl)) / (780 - 700);
    } else {
      factor = 0.0;
    }
    // gamma correct rgb values
    if (r !== 0) {
      r = Math.pow(r * factor, gamma);
    }
    if (g !== 0) {
      g = Math.pow(g * factor, gamma);
    }
    if (b !== 0) {
      b = Math.pow(b * factor, gamma);
    }
    /*
        // intensty is lower at the edges of the visible spectrum.
        if (wl > 780 || wl < 380) {
            alpha = 0;
        } else if (wl > 700) {
            alpha = (780 - wl) / (780 - 700);
        } else if (wl < 420) {
            alpha = (wl - 380) / (420 - 380);
        } else {
            alpha = 1;
        }
    */
    colorSpace = ["rgb(" + r * 100 + "%," + g * 100 + "%," + b * 100 + "%)", r, g, b];

    // colorSpace is an array with 5 elements.
    // The first element is the complete code as a string.
    // Use colorSpace[0] as is to display the desired color.
    // use the last four elements alone or together to access each of the individual r, g, b and a channels.

    return colorSpace;
  };

  const createColorArray = (scaleString) => {
    let colorArray = [];

    let fourthOctaveScale = notesJson[scaleString].filter((s) => s.note.includes("4"));

    fourthOctaveScale.map((n) => {
      colorArray.push({ note: n.note.replace("4", ""), color: wavelengthToColor(getWavelengthNm(n))[0] });
    });

    // console.log({fourthOctaveScale, colorArray});
    return colorArray;
  };

  /************************************************************
      Server Functions
  /*************************************************************/

  function createUser(user) {
    creatingNewUser = true;
    fetch(newUser_url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: user }),
    })
      .then(function (responseOne) {
        return responseOne.json();
      })
      .then(function (serverResponse) {
        if (serverResponse.success) {
          let serverCredentials = {
            email: credentials.email,
            id_token: "",
          };
          console.log("fundamentalInfoRef", fundamentalInfoRef);

          firebaseSignIn(serverCredentials, credentials.password);

          setTimeout(() => {
            if (null != selectedUser) {
              // setTimeout(() => {
              //   if ((null != sessionID) && (null != fundamentalInfoRef)) {
              //       let helper = {
              //         fundamental: fundamentalInfoRef,
              //         date: new Date(Date.now()).getTime(),
              //         uid: selectedUser.uid,
              //         userName: selectedUser.userName,
              //         sessionID: sessionID,
              //       };
              //       // console.log("fundamental effect");
              //       console.log("fundamentalInfoRef found", helper.sessionID, helper.uid);
              //       if ((null != selectedUser) && (null != sessionID) && (null != fundamentalizerInfo) && (null != fundamentalizerInfo.fundamentalNoteCount)) {
              //         recordSession(helper);
              //     }
              //   }
              // }, 1000);
            }
          }, 1000);
        }
        // const dispatch = useDispatch();
        else {
        }
      })
      .catch(function (error) {
        creatingNewUser = false;
        console.error(error);
      });
  }

  function firebaseSignIn(serverCredentials, password) {
    auth
      .signInWithEmailAndPassword(serverCredentials.email, password)
      .then(function (response) {
        let user = response.user;
        console.log("Signed In User! ", user.refreshToken, user.uid);
        user.getIdToken().then(function (auth) {
          let idToken = auth;
          console.log("Token! ", idToken);
          let serverCredentials = {
            email: user.email,
            id_token: idToken,
          };
          _loginServer(serverCredentials, password);
        });
      })
      .catch(function (error) {
        console.log("emailSignIn ", error);
        // Handle Errors here.
        let errorCode = error.code;
        let errorMessage = error.message;
        ALERT_TITLE = "Login Error";
        switch (errorCode) {
          case "auth/user-not-found": {
            window.alert("User Account Not Found!");
            break;
          }

          case "auth/invalid-email": {
            window.alert("Invalid Email!");
            break;
          }

          case "auth/user-disabled": {
            window.alert("User is disabled!");
            break;
          }

          case "auth/wrong-password": {
            window.alert("Wrong Password!");
            break;
          }

          default: {
            window.alert(error.message);
            break;
          }
        }
      });
  }

  function _loginServer(serverCredentials, password) {
    console.log("Server", serverCredentials);

    fetch(login_url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(serverCredentials),
    })
      .then(function (responseOne) {
        return responseOne.json();
      })
      .then(function (serverResponse) {
        if (serverResponse.success) {
          console.log("Innersense User! ", serverResponse);
          let innersenseUser = serverResponse.data;
          let serverUser = new RealityMgmtUser(innersenseUser.firstName, innersenseUser.lastName, innersenseUser.orgs, innersenseUser.role ? innersenseUser.role : "User", innersenseUser.address, innersenseUser.email, innersenseUser.isFirstTime, innersenseUser.isAdmin, innersenseUser.uid, innersenseUser.photoURL, innersenseUser.stripeID, innersenseUser.subscriptionLevel);
          user = serverUser;
          appStorage.setItem(REALITY_MGMT_JWT_TOKEN, serverResponse.accessToken);
          if ((null != sessionUser.uid) && (null != sessionUser.role) && (sessionUser.role !== 'User')) {
            getUsers();
          }
          appStorage.setItem(REALITY_MGMT_UID, serverResponse.data.uid + "___" + serverResponse.data.email);
          if (creatingNewUser) {
            var newSession = {
              date: new Date(Date.now()).getTime(),
              uid: selectedUser.uid,
              userName: `${selectedUser.firstName} ${selectedUser.lastName}`,
            };
            if ((null != sessionUser.uid) && (null != sessionUser.role) && (sessionUser.role !== 'User')) {
              newSession.operatorID = sessionUser.uid;
              newSession.operatorName = `${sessionUser.firstName} ${sessionUser.lastName}`;
            }
            startNewSession(newSession);
          }
          dispatch(sessionStateInit(user));
          // if (null != user.subscriptionLevel && user.subscriptionLevel === "tier1") {
          //   showTierOne();
          // } else if (null != user.subscriptionLevel && user.subscriptionLevel === "tier2") {
          //   showTierTwo();
          // }
        } else {
          if (null != password) {
            firebaseSignIn(serverCredentials, password);
          }
        }
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  function addUser(newUser) {
    let token = appStorage.getItem(REALITY_MGMT_JWT_TOKEN);
    // console.log('addUser', newUser);
    fetch(users_v1_url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({ data: newUser }),
    })
      .then(function (responseOne) {
        return responseOne.json();
      })
      .then(function (serverResponse) {
        if (serverResponse.success) {
          console.log("serverResponse", serverResponse);
          window.alert("Successfully added a new user");
          dispatch(addUserSuccess(serverResponse.data));
        } else {
        }
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  function startNewSession(session) {
    let self = this;
    let token = appStorage.getItem(REALITY_MGMT_JWT_TOKEN);
    fetch(sessionData_url + session.uid, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({ data: session }),
    })
      .then(function (responseOne) {
        return responseOne.json();
      })
      .then(function (serverResponse) {
        if (serverResponse.success) {
          let helper = {
            fundamental: fundamentalInfoRef,
            date: new Date(Date.now()).getTime(),
            uid: selectedUser.uid,
            userName: `${selectedUser.firstName} ${selectedUser.lastName}`,
            sessionID: serverResponse.data.id,
          };
          recordSession(helper);
          // if (null != downloadLink) {
          //   this.uploadAudio();
          // }
          dispatch(startSessionSuccess(serverResponse.data));
        } else {
        }
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  /************************************************************
      Analysis Functions/Helpers
  /*************************************************************/

  const countTotal = () => {
    let reducer = fundamentalNoteCount.reduce((accumulator, object) => {
      return accumulator + object.count;
    }, 1);

    return reducer;
  };

  // let highest = 0;
  const highestCount = (updatedNoteCount) => {
    let highest = 0;

    updatedNoteCount.map((f) => {
      highest = Math.max(highest, f.count);
    });

    // console.log({highest})
    return highest;
  };

  const fundamentalAverage = (fundamentalFreq, previousAvg, count) => {
    let average = (previousAvg * (count - 1) + fundamentalFreq) / count;
    // console.log({average}, fundamentalFreq, previousAvg, count)
    setPreviousAvg(average);
    return { note: findClosestNote(average, frequencyScaleArray).note, frequency: average };
  };

  const fundamentalMode = (number) => {
    let numbersCount = modeArray;

    let freqIndex = numbersCount.findIndex(n => n.frequency === (number).toFixed(3));
    // console.log({freqIndex})
    if (freqIndex >= 0) {
      numbersCount[freqIndex].count = numbersCount[freqIndex].count + 1;
    } else {
      numbersCount.push({ frequency: (number).toFixed(3), count: 1 });
    }
    
    // console.log({numbersCount})
    setModeArray(numbersCount);
    
    let maxCount = Math.max(...numbersCount.map(o => o.count));
    let maxResult = numbersCount.find(n => n.count === maxCount)

    return { note: findClosestNote(maxResult.frequency, frequencyScaleArray).note, frequency: maxResult.frequency };
  }

  const detectPitch = async (analyserArray) => {
    // console.log("detecting pitch")
    // console.log(fundamentalNoteCount)
    let fundamentalFreq = await findFundamentalFreq(analyserArray, SAMPLERATE);
    // console.log({fundamentalFreq})
    // console.log("downloadLink", downloadLink.current.download.length)

    if (fundamentalFreq !== cachedFundamental && fundamentalFreq !== -1 && fundamentalFreq <= 2000) {
      let note = findClosestNote(fundamentalFreq, frequencyScaleArray); // See the 'Finding the right note' section.
      let cents = findCentsOffPitch(fundamentalFreq, note.frequency); // See the 'Calculating the cents off pitch' section.
      let middleOctaveNote = findMiddleOctaveNote(note);
      let harmonicsArray = createHarmonicsArray(fundamentalFreq);
      let visibleFrequency = convertToVisibleFrequency(middleOctaveNote);
      // let visibleWavelength = convertFrequencyToWavelength(visibleFrequency);
      let visibleWavelength = getWavelengthNm(middleOctaveNote);
      let colorSpace = wavelengthToColor(visibleWavelength);
      // let colorArray = createColorArray(frequencyScale);
      // updateNote(note.note); // Function that updates the note on the page (see demo source code).
      // updateCents(cents); // Function that updates the cents on the page and the gauge control (see demo source code).
      let parsedOctave = note.note.replace(/^\D+/g, "");
      let parsedNote = note.note.replace(parsedOctave, "");
      let thisNote = fundamentalNoteCount.find(c => c.note === parsedNote);
      let currentNoteCount = [
        ...fundamentalNoteCount.filter(c => c.note !== parsedNote),
        {
          ...thisNote,
          count: thisNote.count + 1
        }
      ]

      fundamentalizerInfo = {
        "frequency": fundamentalFreq,
        "harmonicsArray": harmonicsArray,
        "nearestNote": {
          "note": note.note,
          "frequency": note.frequency
        },
        "cents": cents,
        "visibleFrequency": visibleFrequency,
        "wavelength": visibleWavelength,
        "colorSpace": {
          "rgb": colorSpace[0],
          "red": colorSpace[1],
          "green": colorSpace[2],
          "blue": colorSpace[3]
        },
        "fundamentalNoteCount": currentNoteCount,
        "highestCount": highestCount(currentNoteCount),
        "totalNotes": countTotal(),
        "fundamentalAverage": /*fundamentalAverage([...fundamentalArray, fundamentalFreq])*/ fundamentalAverage(fundamentalFreq, previousAvg, countTotal()),
        "fundamentalMode": /*fundamentalMode([...fundamentalArray, fundamentalFreq])*/ fundamentalMode(fundamentalFreq)
      };

      // console.log("selectedUser", selectedUser, sessionID);
      if (selectedUser && sessionID) {
        let data = new FundamentalizerData(fundamentalizerInfo.frequency, fundamentalizerInfo.harmonicsArray, fundamentalizerInfo.nearestNote, fundamentalizerInfo.cents, fundamentalizerInfo.visibleFrequency, fundamentalizerInfo.wavelength, fundamentalizerInfo.colorSpace, fundamentalizerInfo.fundamentalNoteCount, fundamentalizerInfo.highestCount, fundamentalizerInfo.totalNotes, new Date(Date.now()).getTime());
        let helper = {
          fundamental: fundamentalizerInfo,
          date: new Date(Date.now()).getTime(),
          uid: selectedUser.uid,
          userName: selectedUser.userName,
          sessionID: sessionID,
        };
        // console.log("fundamental effect");
        if (null != selectedUser && null != sessionID && null != fundamentalizerInfo && null != fundamentalizerInfo.fundamentalNoteCount) {
          recordSession(helper);
        }
      }

      // dispatch(updateFundamentalInfo(defaultFundamentalizerInfo));
      if (JSON.stringify(fundamentalInfo) !== JSON.stringify(fundamentalizerInfo)) {
        dispatch(updateFundamentalInfo(fundamentalizerInfo));
        // console.log(fundamentalizerInfo)
      } else {
        frameCountId = requestAnimationFrame(resumeAnalysis);
        frameRef.current = frameCountId;
      }

      // console.log({fundamentalFreq, note, cents, middleOctaveNote, harmonicsArray, visibleFrequency, visibleWavelength, colorSpace})
      // fundamentalNoteCount = currentNoteCount;
      cachedFundamental = fundamentalFreq;
      // return fundamentalizerInfo;
    } else {
      frameCountId = requestAnimationFrame(resumeAnalysis);
      frameRef.current = frameCountId;
      // updateNote('--');
      // updateCents(-50);
      // console.log({fundamentalFreq, note, cents, middleOctaveNote, harmonicsArray, visibleFrequency, visibleWavelength, colorSpace})
      // return;
    }

    return fundamentalizerInfo;
  };

  const initContext = (mediaStream) => {
    SAMPLERATE = mediaStream.getAudioTracks()[0].getSettings().sampleRate;
    nyquist = SAMPLERATE / 2;

    contextInput = new AudioContext({
      latencyHint: "interactive",
      sampleRate: SAMPLERATE,
    });

    binCount = fftSize / 2;
    freqResolution = nyquist / binCount;

    analyserNodeInput = contextInput.createAnalyser();
    analyserNodeInput.fftSize = fftSize;
    bufferLength = analyserNodeInput.fftSize / 2;

    inputDevice = contextInput.createMediaStreamSource(mediaStream);
    inputDevice.connect(analyserNodeInput);
    resumeAnalysis();
  };

  const generateUUID = () => {
    let d = Date.now();
    let uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
      let r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c === "x" ? r : (r & 0x3 | 0x8))
        .toString(16);
    });
    return uuid;
  }

  const recordAudio = (mediaStream) => {

    var options = { mimeType: types[0] };
    const recordedChunks = [];
    if (MediaRecorder.isTypeSupported(types[0])) {
     options = {mimeType: types[0]};
    } else if (MediaRecorder.isTypeSupported(types[1])) {
      options = {mimeType: types[1]};
    }  else if (MediaRecorder.isTypeSupported(types[4])) {
      options = {mimeType: types[4]};
    } else if (MediaRecorder.isTypeSupported(types[3])) {
      options = {mimeType: types[3]};
    } else {
        console.error("no suitable mimetype found for this device");
    }
    const mediaRecorder = new MediaRecorder(mediaStream, options);

    mediaRecorder.addEventListener("dataavailable", function (e) {
      if (e.data.size > 0) recordedChunks.push(e.data);
    });

    mediaRecorder.addEventListener("start", function () {
      console.log("RECORDING STARTED");
    });

    // mediaRecorder.addEventListener('pause', function() {

    // });

    // mediaRecorder.addEventListener('resume', function() {

    // });

    mediaRecorder.addEventListener("stop", function () {
      console.log("downloadLink", downloadLink);
      // downloadLink.current.href = URL.createObjectURL(new Blob(recordedChunks));
      // Push to child path.
      if ((null != sessionUser)) {
        var metadata = {
          contentType: options.mimeType
        };
        console.log("file", recordedChunks);
        let uuid = generateUUID();
        let fileType = ((options.mimeType === types[0]) || options.mimeType === types[1]) ? ".webm" : (options.mimeType === [4]) ? "audio/aiff" : "audio/wav";
        storageRef.child('sessions/' + selectedUser.uid + "/" + uuid + fileType).put(recordedChunks, metadata).then(function(snapshot) {
          console.log('Uploaded', snapshot.totalBytes, 'bytes.');
          console.log('File metadata:', snapshot.metadata);
          // Let's get a download URL for the file.
          snapshot.ref.getDownloadURL().then(function(url) {
            downloadURL = url;
            console.log("downloadURL", downloadURL);
          });
        }).catch(function(error) {
          console.error('Upload failed:', error);
        });
      }
      // downloadLink.current.download = 'acetest.wav';
      // console.log("downloadLink", (downloadLink.current.download).toString())
    });

    const getFileFromUrl = async (url, name, defaultType = 'audio/webm') =>{
      const response = await fetch(url);
      const data = await response.blob();
      return new File([data], name, {
        type: data.type || defaultType,
      });
    }

    const uploadAudio = async() => {
      var metadata = {
        contentType: 'audio/wav'
      };
      const file = await getFileFromUrl(downloadLink.current.href, downloadLink.current.download);
      console.log("file", file);
      storageRef.child('sessions/' + selectedUser.uid + "/" + sessionID + "/").put(file, metadata).then(function(snapshot) {
        console.log('Uploaded', snapshot.totalBytes, 'bytes.');
        console.log('File metadata:', snapshot.metadata);
        // Let's get a download URL for the file.
        snapshot.ref.getDownloadURL().then(function(url) {
          downloadURL = url;
          console.log("downloadURL", downloadURL);
        });
      }).catch(function(error) {
        console.error('Upload failed:', error);
      });
    }

    resetButton.current.addEventListener("click", function () {
      mediaRecorder.stop();
    });

    mediaRecorder.start();
  };

  let angle = 0;
  let spiralCoords = {
    x: 300,
    y: 300,
  };

  let rotatedAngle = 0;
  let scalar;
  const drawSpiral = (context, centerX, centerY, stepCount, loopCount, innerDistance, loopSpacing, rotation) => {
    context.beginPath();

    // spiralCoords = {
    //   x: centerX,
    //   y: centerY
    // };

    let stepSize = (2 * Math.PI) / stepCount;
    let endAngle = 2 * Math.PI * loopCount;
    let finished = false;
    let x;
    let y;
    // for (let angle = 0; !finished; angle += stepSize) {
    // Ensure that the spiral finishes at the correct place,
    // avoiding any drift introduced by cumulative errors from
    // repeatedly adding floating point numbers.
    // if (angle > endAngle) {
    //     angle = endAngle;
    //     finished = true;
    // }

    scalar = innerDistance + loopSpacing * angle;
    rotatedAngle = angle + rotation;
    spiralCoords.x = spiralCoords.x + scalar * Math.cos(rotatedAngle);
    spiralCoords.y = spiralCoords.y + scalar * Math.sin(rotatedAngle);

    // context.lineTo(x, y);
    // }
    // context.lineTo(spiralCoords.x, spiralCoords.y);
    context.arc(spiralCoords.x, spiralCoords.y, 10, 0, 2 * Math.PI);
    context.stroke();
    angle += stepSize;
    console.log({ spiralCoords, angle });
  };

  const resetVisCanvas = () => {
    canvas = canvasRef.current;
    drawContext = canvas.getContext("2d");
    drawContext.clearRect(0, 0, canvas.width, canvas.height);
  }

  const resetShinyCanvas = () => {
    shiny = shinyRef.current;
    drawShiny = shiny.getContext("2d");
    drawShiny.clearRect(0, 0, shiny.width, shiny.height);
  }

  const markShinyCanvas = (gainPercentage = 100) => {
    // MUST BE SQUARE DIMENSIONS
    shiny = shinyRef.current;
    // console.log({ shiny });
    drawShiny = shiny.getContext("2d");
    // shiny.width = 255;
    // shiny.height = 255;
    drawShiny.lineWidth = 1;
    let sideDimension = 400;
    let center = shiny.width / 2;
    let xOffset = 0.5;
    let rotationCount = 1;

    let startPoint = {
      x: center + xOffset,
      y: center,
    };

    let endPoint = {
      x: center + xOffset,
      y: 0,
    };

    // MUST BE SQUARE DIMENSIONS
    let finalCoordinates = (noteCount) => {
      let coords = [center, center, 0, 0];
      if (noteCount <= sideDimension * 1 * rotationCount) {
        coords = [center, center, noteCount, 0];
      } else if (noteCount <= sideDimension * 2 * rotationCount) {
        coords = [center, center, shiny.width, noteCount - shiny.width];
      } else if (noteCount <= sideDimension * 3 * rotationCount) {
        coords = [center, center, shiny.width - (noteCount - (shiny.width + shiny.height)), shiny.height];
      } else if (noteCount <= sideDimension * 4 * rotationCount) {
        coords = [center, center, 0, shiny.height - (noteCount - (shiny.width * 2 + shiny.height))];
      }
      rotationCount += 4;
      return coords;
    };

    let degreesToRadians = (degrees) => {
      let pi = Math.PI;
      let radians = degrees * (pi / 180);
      // console.log({radians})
      return radians;
    };

    let barWidth = WIDTH / analyserNodeInput.frequencyBinCount;
    drawShiny.beginPath();

    drawShiny.translate(startPoint.x, startPoint.y);

    // rotate some angle (radians)
    let rotationAngle = fundamentalInfo.totalNotes % 360;
    let revolutionCount = parseInt(String(Number(fundamentalInfo.totalNotes) / 360));
    let rotationOffset = 1 / 6;

    if (rotationAngle > 0) {
      drawShiny.rotate(degreesToRadians(rotationAngle + revolutionCount * rotationOffset));
    }

    // VORTEX
    // drawShiny.translate(-startPoint.x/1.1, -startPoint.y)
    // HISTOGRAM
    drawShiny.translate(-startPoint.x, -startPoint.y);

    drawShiny.moveTo(startPoint.x, startPoint.y);
    // Draw at full length;
    // drawShiny.lineTo(endPoint.x, endPoint.y);
    // Draw based on amplitude of value;
    drawShiny.lineTo(endPoint.x, center - ((sideDimension / 2) * gainPercentage) / 100);
    drawShiny.strokeStyle = fundamentalInfo.colorSpace.rgb;

    // if (fundamentalInfo.totalNotes >= 360) {
    // Draw image with filter
    // drawShiny.filter = 'drop-shadow(0px 1px 1px #000000bf)';
    // }
    // drawShiny.shadowBlur = 2;
    // drawShiny.shadowColor = fundamentalInfo.colorSpace.rgb //'rgba(' + fundamentalInfo.colorSpace.red + '%,' + fundamentalInfo.colorSpace.green + '%,' + fundamentalInfo.colorSpace.blue + '%,' + fundamentalInfo.colorSpace.alpha + '%)';

    drawShiny.stroke();
    // drawShiny.closePath();
    // drawSpiral(drawShiny, center, center, 1, 1, 1, 1, 1)
    // reset transforms
    // drawShiny.setTransform(1,0,0,1,0,0);
    // console.log(rgb)
    // }
    // drawShiny.beginPath();
    // drawShiny.translate(startPoint.x, startPoint.y);
    // drawShiny.rotate(degreesToRadians(rotationAngle + (revolutionCount * rotationOffset)))
    // drawShiny.translate(-startPoint.x, -startPoint.y/2);
    // drawShiny.moveTo(startPoint.x, startPoint.y);
    // drawShiny.lineTo(endPoint.x, center - (sideDimension/2 * gainPercentage/100));
    // drawShiny.stroke();
    // drawShiny.setTransform(1,0,0,1,0,0);
  };

  const getVisColorSpectrum = () => {
    binColorSpectrumArray = [];
    for (let i = 0; i < binCount; i++) {
      let binFrequency = Math.max(i * freqResolution, frequencyScaleArray[0].frequency);
      let note = findClosestNote(binFrequency, frequencyScaleArray); // See the 'Finding the right note' section.
      let cents = findCentsOffPitch(binFrequency, note.frequency); // See the 'Calculating the cents off pitch' section.
      let middleOctaveNote = findMiddleOctaveNote(note);
      let spectrumNote = { note: null, frequency: binFrequency };
      let visibleFrequency = convertToVisibleFrequency(middleOctaveNote);
      let visibleWavelength = getWavelengthNm(middleOctaveNote);
      let colorSpace = wavelengthToColor(visibleWavelength);
      binColorSpectrumArray.push(colorSpace);
      // setVisColorSpectrumArray(
      //   [
      //     ...visColorSpectrumArray,
      //     colorSpace
      //   ]
      // );
    }
    // setVisColorSpectrumArray(binColorSpectrumArray);
    return;
  };

  const markHarmonicsCanvas = (harmonicsArray) => {
    canvas = canvasRef.current;
    drawContext = canvas.getContext("2d");
    canvas.width = WIDTH;
    canvas.height = HEIGHT;
    let p = 0;

    let barWidth = WIDTH / analyserNodeInput.frequencyBinCount;
    let noteName = "";
    let fontSize = "16";
    let yLabelOffset = parseInt(fontSize) / 2;
    harmonicsArray.map((h, index) => {
      let strokeLocation = Math.floor(h / freqResolution) * barWidth;
      drawContext.moveTo(0.5 + strokeLocation + p, p);
      drawContext.lineTo(0.5 + strokeLocation + p, HEIGHT + p);

      noteName = findClosestNote(Math.max(h, frequencyScaleArray[0].frequency), frequencyScaleArray);
      // console.log(noteName);

      drawContext.font = "bold " + fontSize + "px Arial";
      drawContext.fillStyle = "white";

      if (strokeLocation < WIDTH - parseInt(fontSize) * 3) {
        drawContext.textAlign = "left";
      } else {
        drawContext.textAlign = "right";
      }
      drawContext.fillText(noteName.note, 0.5 + strokeLocation + p, parseInt(fontSize) + yLabelOffset * index);
    });

    drawContext.strokeStyle = "white";
    drawContext.stroke();
  };

  const markAverageMode = () => {
    canvas = canvasRef.current;
    drawContext = canvas.getContext("2d");
    canvas.width = WIDTH;
    canvas.height = HEIGHT;
    let p = 0;

    let barWidth = WIDTH/analyserNodeInput.frequencyBinCount;
    let noteName = '';
    let fontSize = '20';
    let yLabelOffset = parseInt(fontSize)/2;

    drawContext.font = fontSize + "px -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'";
    drawContext.fillStyle = 'white';
    drawContext.textAlign = "left";

    drawContext.fillText("Average: " + fundamentalInfo.fundamentalAverage.frequency.toFixed(3) + " (" + fundamentalInfo.fundamentalAverage.note + ")", 4, parseInt(fontSize));
    drawContext.fillText("Common: " + Number(fundamentalInfo.fundamentalMode.frequency).toFixed(3) + " (" + fundamentalInfo.fundamentalMode.note + ")", 4, (parseInt(fontSize) * 2) + 6);

    drawContext.strokeStyle = "white";
    drawContext.stroke();
  }

  const getVisualizerData = () => {
    // getVisColorSpectrum();
    visArray = { input: null };
    canvas = canvasRef.current;
    drawContext = canvas.getContext("2d");
    canvas.width = WIDTH;
    canvas.height = HEIGHT;
    let p = 0;

    let chartDivisions = WIDTH / freqResolution;

    analyserNodeInput.smoothingTimeConstant = SMOOTHING;

    analyserNodeInput.getByteFrequencyData(analyserFreqDomainArray);

    let width = Math.floor(1 / analyserFreqDomainArray.length, 10);

    visArray.input = analyserFreqDomainArray;

    // markHarmonicsCanvas(fundamentalInfo.harmonicsArray);
    markAverageMode();
    for (let i = 0; i < analyserNodeInput.frequencyBinCount; i++) {
      let value = analyserTimeDomainArray[i];
      let percent = value / 256;
      let height = HEIGHT * percent;
      let offset = HEIGHT - height - 1;
      let barWidth = WIDTH / analyserNodeInput.frequencyBinCount;
      drawContext.fillStyle = "grey";
      drawContext.fillRect(i * barWidth, offset, 1, 2);
    }
    drawContext.fillStyle = "rgba(174,244,218,1)";
    for (let i = 0; i < analyserNodeInput.frequencyBinCount; i++) {
      let value = analyserFreqDomainArray[i];
      let percent = value / 256;
      let height = HEIGHT * percent;
      let offset = HEIGHT - height;
      let barWidth = WIDTH / analyserNodeInput.frequencyBinCount;
      let hue = (i / analyserNodeInput.frequencyBinCount) * 360;
      // let rgb = binColorSpectrumArray[i][0];
      // drawContext.fillStyle = 'hsl(' + hue + ', 100%, 50%)';
      // drawContext.fillStyle = binColorSpectrumArray[i][0];
      drawContext.fillRect(i * barWidth, offset, barWidth, height);

      if (fundamentalInfo.harmonicsArray[0] >= analyserFreqDomainArray[i] * freqResolution && fundamentalInfo.harmonicsArray[0] < analyserFreqDomainArray[i + 1] * freqResolution) {
        // console.log("harmonicsArrayValue", fundamentalInfo.harmonicsArray[0], analyserFreqDomainArray[i] * freqResolution, analyserFreqDomainArray[i+1] * freqResolution, {value})
        markShinyCanvas(value);
      }
    }
  };

  const getAnalyserData = () => {
    analysisArray = { input: null };
    // analyserTimeDomainArray = timeDomainArray

    if (fundamentalizerDevice === "input" && fundamentalizerStatus === "enabled") {
      if (analyserNodeInput && analyserTimeDomainArray) {
        analyserNodeInput.getByteTimeDomainData(analyserTimeDomainArray);
        analysisArray.input = analyserTimeDomainArray;
        // dispatch(updateFundamentalInfo(defaultFundamentalizerInfo));
        // console.log({fundamentalizerDevice, fundamentalizerStatus, analyserNodeInput, analyserTimeDomainArray})
        detectPitch(analysisArray.input);
      }
      // if (analyserNodeInput && analyserFreqDomainArray) {
      //   getVisualizerData();
      // }
    }
  };

  const resumeAnalysis = () => {
    if (fundamentalizerStatus === "enabled") {
      console.log("resumeAnalysis", fundamentalizerStatus)
      if (null != downloadLink && null != downloadLink.current && downloadLink.current.download.length === 0) {
        downloadLink.current.download = Date.now() + " - " + contextInput.currentTime + ".wav";
      }
      analyserTimeDomainArray = timeDomainArray;
      getAnalyserData();

      analyserFreqDomainArray = freqDomainArray;
      getVisualizerData();
      // markShinyCanvas()
    } else if (fundamentalizerStatus === "disabled") {
      // console.log("resumeAnalysis", fundamentalizerStatus)
    } else {
      // console.log("resumeAnalysis", fundamentalizerStatus)
    }
  };

  const callback = (stream) => {
    // console.log("streamCallback", fundamentalizerStatus)
    initContext(stream);
    recordAudio(stream);
    // if (window.URL) {
    //   playerRef.current.srcObject = stream;
    // } else {
    //   playerRef.current.src = stream;
    // }
  };

  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: false })
      .then((stream) => {
        if (fundamentalizerStatus === "enabled") {
          callback(stream);
        }
      })
      .catch((err) => console.log(err));
  }, [fundamentalizerStatus]);

  useEffect(() => {
    // console.log("fundamentalInfo useEffect", fundamentalInfo)
    frameCountId = requestAnimationFrame(resumeAnalysis);
    frameRef.current = frameCountId;
    // console.log(frameRef.current)
    return () => cancelAnimationFrame(frameCountId);
  }, [fundamentalInfo, frameRef.current]);

  useEffect(() => {
    setColorArray(createColorArray("432"));
    getVisColorSpectrum();
    canvas = canvasRef.current;
  }, []);

  useEffect(() => {
    analyzeBinaural();
  }, [binauralPlaying]);

  useEffect(() => {
    if (usersArray !== undefined) {
      normalizeUserDisplay(usersArray);
    }
  }, [usersArray]);

  const normalizeUserDisplay = (inputArray) => {
    let modifiedUserArray = [];
    if (inputArray !== undefined) {
      inputArray.map((userObject) => {
        let firstName = userObject.firstName ? userObject.firstName.trim() : "";
        let lastName = userObject.lastName ? userObject.lastName.trim() : "";
        let email = userObject.email ? userObject.email.trim() : "";
        let userName = userObject.userName ? userObject.userName.trim() : "";

        let displayName = "";

        if (firstName.length > 0) {
          if (lastName.length > 0) {
            displayName = lastName + ", " + firstName + " (" + email + ")";
          } else if (lastName.length === 0) {
            if (email.length > 0) {
              displayName = firstName + " (" + email + ")";
            } else {
              displayName = firstName + " (" + email + ")";
            }
          }
        } else if (firstName.length === 0) {
          if (lastName.length > 0) {
            displayName = lastName + " (" + email + ")";
          } else if (lastName.length === 0) {
            if (userName.length > 0) {
              displayName = userName + " (" + email + ")";
            } else if (userName.length === 0) {
              displayName = email;
            }
          }
        } else {
          displayName = email;
        }
        // console.log("modifiedUserArray", displayName);
        modifiedUserArray.push({ ...userObject, displayName });
      });
    }

    modifiedUserArray.sort((a, b) => a.displayName.toLowerCase().localeCompare(b.displayName.toLowerCase()));
    setSortedUsers(modifiedUserArray);
  };

  const mapUserArrayOptions = () => {
    return sortedUsers.map((u, index) => (
      <Option key={index} value={JSON.stringify(u)}>
        {u.displayName}
      </Option>
    ));
  };


  const handleDownloadClick = () => {
    if (null != selectedUser) {
      downloadLink.current.click();
    } else {
      showNewUserModal();
      window.alert("Please sign up or login");
    }
  };

  const handleInputChange = (value) => {
    dispatch(updateFundamentalDevice(value));
    // console.log(`selected ${value}`);
  };

  const chartToImg = () => {
    if (null != selectedUser) {
      const scale = 3;
      const node = chartRef.current;
      const imgNode = imgRef.current;

      const style = {
        transform: "scale(" + scale + ")",
        transformOrigin: "top left",
        width: node.offsetWidth + "px",
        height: node.offsetHeight + "px",
      };

      const param = {
        quality: 1,
      };

      domtoimage
        .toJpeg(node, param)
        .then(function (dataUrl) {
          let img = new Image();
          img.src = dataUrl;
          let imgContainer = new DOMParser().parseFromString('<span class="capture-preview ant-col-24"></span>', "text/html");
          imgContainer.className = "ant-col-6";
          imgContainer.body.firstChild.appendChild(img);
          if (imgNode.childNodes.length > 0) {
            imgNode.prepend(imgContainer.body.firstChild); // Add screenshot before previous screenshot
          } else {
            imgNode.appendChild(imgContainer.body.firstChild);
          }
        })
        .catch(function (error) {
          console.error("oops, something went wrong!", error);
        });
    } else {
      showNewUserModal();
      window.alert("Please sign up or login");
    }
  };

  const clearScreenshot = () => {
    const imgNode = imgRef.current;

    if (imgNode.childNodes.length > 0) {
      for (let n = 0; n < imgNode.childNodes.length; n++) {
        imgNode.removeChild(imgNode.childNodes[n]); // Remove all screenshots
      }

      // imgNode.removeChild(imgNode.childNodes[0]); // Remove screenshot
    }
  };

  const calculateDelay = (freq) => {
    let value = 0.25 * (1 / freq);
    while (value < 0) {
      value += Math.abs(1 / freq);
    }

    return value;
  };

  function getUsers() {
    let token = appStorage.getItem(REALITY_MGMT_JWT_TOKEN);
    fetch(users_url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then(function (responseOne) {
        return responseOne.json();
      })
      .then(function (serverResponse) {
        if (serverResponse.success) {
          console.log("Users", serverResponse);
          dispatch(getUsersSuccess(serverResponse.data));
          dispatch(selectUserSuccess(serverResponse.data[0]));
          // dispatch(addUser());
        } else {
        }
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  function logOut() {
    auth.signOut();
    let token = appStorage.getItem(REALITY_MGMT_JWT_TOKEN);
    if (null != token) {
      appStorage.setItem(REALITY_MGMT_JWT_TOKEN, token + "expires=Thu, 01 Jan 1970 00:00:01 GMT;");
    }
    logoutSuccess();
    window.location.reload();
    hideLogOut();
  }

  const fundamentalDisplay = () => {
    if (window.innerWidth < 767) {
        return (
          <>
            <Row className="harmonics-array fundamental-cell" style={{ marginTop: 20, fontSize: 18, fontWeight: 600 }}>
              <Col span={24} key={"fundamental"} style={{ border: "1px solid #303030", textAlign: "-webkit-center" }}>
                {fundamentalInfo.frequency + " Hz"}
              </Col>
            </Row>
            <Row id="harmonicsWrapper" className="harmonics-array" style={{ display: "flex", flexDirection: "row" }}>
              <Row id='mobileHarmonicsRow' className="harmonics-array harmonics-cell" style={{ marginTop: 0, fontSize: 10, fontWeight: 600,marginTop: 0,display: 'flex !important',flexDirection: 'column !important' }}>
                {harmonicsLabel.map((l, index) => (
                  <Col id="mobileHarmonicsColumn" span={2} key={index} style={{ border: "1px solid #303030", textAlign: "-webkit-center", width: '100%', maxWidth: '100% !important' }}>
                    {l}
                  </Col>
                ))}
              </Row>
              <Row id='mobileHarmonicsRow' className="harmonics-array harmonics-cell" style={{ marginBottom: 0 }}>
                {fundamentalInfo.harmonicsArray.map((h, index) => (
                  <Col id="mobileHarmonicsColumn" span={2} key={index} style={{ border: "1px solid #303030", textAlign: "-webkit-center", width: '100%', maxWidth: '100% !important' }}>
                    {h.toFixed(responsiveToFixed(3))}
                  </Col>
                ))}
              </Row>
            </Row>
          </>
        );
    } else {
      return (
        <>
          <Row className='harmonics-array fundamental-cell' style={{ marginTop: 20, fontSize: 18, fontWeight: 600 }}>
            <Col span={24} key={'fundamental'} style={{ border: '1px solid #303030', textAlign: '-webkit-center' }} >
            {fundamentalInfo.frequency + ' Hz'}
            </Col>
          </Row>
          <Row className='harmonics-array harmonics-cell' style={{ marginTop: 20, fontSize: 10, fontWeight: 600 }}>
            {harmonicsLabel.map((l, index) => 
              <Col span={2} key={index} style={{ border: '1px solid #303030', textAlign: '-webkit-center' }} >
                {l}
              </Col>
            )}
          </Row>
          <Row className='harmonics-array harmonics-cell' style={{ marginBottom: 16 }}>
            {fundamentalInfo.harmonicsArray.map((h, index) => 
              <Col span={2} key={index} style={{ border: '1px solid #303030', textAlign: '-webkit-center' }} >{(h).toFixed(responsiveToFixed(3))}</Col>
            )}
          </Row>
        </>
      );
    }
  };

  const octaveReduction = (freq, nearestNote) => {
    let frequency = freq; // Hz
    let octavesBelow = Number([...nearestNote].pop()) - 4;
    return frequency / Math.pow(2, octavesBelow);
  }

  const analyzeBinaural = () => {
    vectorAnalyser.getFloatTimeDomainData(timeDomainL, timeDomainR);
    requestAnimationFrame(analyzeBinaural);
  };

  const generateBinaural = () => {
    if (null == selectedUser) {
      showNewUserModal();
      window.alert("Please sign up or login");
    } else {
      // let note = fundamentalNoteCount.find(n => n.count === fundamentalInfo.highestCount);
      //       console.log({fundamentalNoteCount, note}, fundamentalInfo.highestCount);
      // let newNote = { 'note': note.note + 0, 'count': fundamentalInfo.highestCount };
      // let middleOctaveNote = findMiddleOctaveNote(newNote);
      // console.log(note, fundamentalInfo.highestCount, {newNote, middleOctaveNote});
      let middleOctaveNote = octaveReduction(fundamentalInfo.fundamentalMode.frequency, fundamentalInfo.fundamentalMode.note);
      contextOutput = new AudioContext({
        latencyHint: "interactive",
        sampleRate: SAMPLERATE,
      });

      vectorAnalyser = new StereoAnalyserNode(contextOutput);
      vectorAnalyser.fftSize = fftSize;

      timeDomainL = new Float32Array(fftSize);
      timeDomainR = new Float32Array(fftSize);

      oscLeft = contextOutput.createOscillator();
      oscRight = contextOutput.createOscillator();

      oscLeft.type = "sine";
      oscRight.type = "sine";

      leftPanner = contextOutput.createStereoPanner();
      rightPanner = contextOutput.createStereoPanner();

      merger = contextOutput.createChannelMerger(2);
      delay = contextOutput.createDelay();

      leftPanner.pan.value = -1;
      rightPanner.pan.value = 1;

      gainNode = contextOutput.createGain();
      gainNode.gain.value = 0;

      // oscLeft.frequency.value = 440;
      oscLeft.frequency.value = middleOctaveNote
      oscRight.frequency.value = middleOctaveNote + selectedBrainstate.fqDelta;
      // oscRight.frequency.setValueAtTime(middleOctaveNote.frequency, contextOutput.currentTime);
      // oscRight.frequency.linearRampToValueAtTime(middleOctaveNote.frequency + selectedBrainstate.fqDelta, contextOutput.currentTime + selectedBrainstate.fqDelta)
      setBinauralFrequency([middleOctaveNote - selectedBrainstate.fqDelta/2, middleOctaveNote + selectedBrainstate.fqDelta/2]);
      delay.value = calculateDelay(oscRight.frequency.value);
      // oscLeft.connect(leftPanner);
      // oscRight.connect(rightPanner);
      // leftPanner.connect(gainNode);
      // rightPanner.connect(gainNode);
      oscLeft.connect(merger, 0, 0);
      oscRight.connect(delay);
      delay.connect(merger, 0, 1);
      merger.connect(gainNode);
      merger.connect(vectorAnalyser);
      gainNode.connect(contextOutput.destination);

      oscLeft.start(contextOutput.currentTime);
      oscRight.start(contextOutput.currentTime);
      gainNode.gain.setValueAtTime(0, contextOutput.currentTime);
      gainNode.gain.linearRampToValueAtTime(1, contextOutput.currentTime + 3);
      gainNode.gain.setValueAtTime(1, contextOutput.currentTime + 3);

      setBinauralPlaying(true);
      console.log("BINAURAL STARTED", middleOctaveNote, oscLeft.frequency.value, selectedBrainstate.fqDelta, oscRight.frequency.value);
      return;
    }
  };

  const stopBinaural = () => {
    gainNode.gain.cancelScheduledValues(contextOutput.currentTime);
    gainNode.gain.setValueAtTime(gainNode.gain.value, contextOutput.currentTime);
    gainNode.gain.linearRampToValueAtTime(0, contextOutput.currentTime + 1);
    oscLeft.stop(contextOutput.currentTime + 1);
    oscRight.stop(contextOutput.currentTime + 1);

    setBinauralPlaying(false);
    return;
  };

  const rgbToHex = (rgb) => {
    let sep = rgb.indexOf(",") > -1 ? "," : " ";
    rgb = rgb.substr(4).split(")")[0].split(sep);

    // Convert %s to 0–255
    for (let R in rgb) {
      let r = rgb[R];
      if (r.indexOf("%") > -1)
        rgb[R] = Math.round(r.substr(0,r.length - 1) / 100 * 255);
        /* Example:
        75% -> 191
        75/100 = 0.75, * 255 = 191.25 -> 191
        */
    }

    return '#' + rgb[0].toString(16).padStart(2, '0') + rgb[1].toString(16).padStart(2, '0') + rgb[2].toString(16).padStart(2, '0');
  }

  const hideLogOut = () => {
    setLogOutVisible(false);
  };

  const handleLogout = () => {
    logOut();
  };

  const mapBrainstates = () => {
    return brainstateList.map((u, index) => (
      <Option key={index} value={JSON.stringify(u)}>
        {u.name + " (" + u.description + ")"}
      </Option>
    ));
  };

  const handleBrainstateChange = (value) => {
    dispatch(updateSelectedBrainstate(JSON.parse(value)));
    console.log(value);
  };

  const handleUserChange = (value) => {
    dispatch(selectUserSuccess(JSON.parse(value)));
  };

  const handleSubmitSignUp = () => {
    if (confirmPassword.trim() !== credentials.password) {
      window.alert("Passwords must match");
    } else {
      let newUser = new RealityMgmtUser(credentials.firstName, credentials.lastName, DEFAULT_ORG, "User",  null, credentials.email, true, false, null, null, null, null, credentials.goal, credentials.password);
      console.log("createUser", newUser);
      hideNewUserModal();
      createUser(newUser);
    }
  };

  const handleSubmitAddUser = () => {
    var orgs = userOrg;
    orgs.isAdmin = false;
    orgs.buildings[0].isAdmin = false;
    console.log("orgs", orgs);
    let newUser = new RealityMgmtUser(credentials.firstName, credentials.lastName, orgs, "User",  null, credentials.email, true, false, null, null, null, null, credentials.goal, 'realitymgmt');
    console.log("createUser", newUser);
    hideAddUserModal();
    addUser(newUser);
  };

  const handleSubmit = () => {
    let serverCredentials = {
      email: credentials.email,
      id_token: "",
    };
    firebaseSignIn(serverCredentials, credentials.password);
    hideLoginModal();
  };

  const saveSelectedUser = () => (
    <Button
      style={{
        margin: 0,
        display: (isBldgAdmin || sessionUser.isAdmin) ? "block" : "none",
        visibility: (isBldgAdmin || sessionUser.isAdmin) ? "visible" : "hidden",
      }}
      type="primary"
      ghost
      icon={<PlusOutlined />}
      onClick={(e) => {
        e.stopPropagation();
        showNewUserModal();
      }}
    >
      Add A User
    </Button>
  );

  const handleEmail = (e) => {
    setCredentials({
      email: e.target.value,
      password: credentials.password,
      firstName: credentials.firstName,
      lastName: credentials.lastName,
      goal: credentials.goal,
    });
    // console.log("credentials", credentials);
  };

  const handleFirstName = (e) => {
    setCredentials({
      email: credentials.email,
      password: credentials.password,
      firstName: e.target.value,
      lastName: credentials.lastName,
      goal: credentials.goal,
    });
    // console.log("credentials", credentials);
  };

  const handleLastName = (e) => {
    setCredentials({
      email: credentials.email,
      password: credentials.password,
      firstName: credentials.firstName,
      lastName: e.target.value,
      goal: credentials.goal,
    });
    // console.log("credentials", credentials);
  };

  const handleGoal = (e) => {
    setCredentials({
      email: credentials.email,
      password: credentials.password,
      firstName: credentials.firstName,
      lastName: credentials.lastName,
      goal: e.target.value,
    });
    // console.log("credentials", credentials);
  };

  const handlePassword = (e) => {
    setCredentials({
      password: e.target.value,
      email: credentials.email,
      firstName: credentials.firstName,
      lastName: credentials.lastName,
      goal: credentials.goal,
    });
    // console.log("credentials", credentials);
  };

  const handleConfirmPassword = (e) => {
    confirmPassword = e.target.value;
    // setCredentials({ password: e.target.value, email: credentials.email, firstName: credentials.firstName, lastName: credentials.lastName, goal: credentials.goal });
    // console.log("credentials", credentials);
  };

  const showNewUserModal = () => {
    setNewUserModalVisible(true);
  };

  const showAddUserModal = () => {
    setAddUserModalVisible(true);
  };

  const showLogoutModal = () => {
    setLogOutVisible(true);
  };

  const showLoginModal = () => {
    setLoginVisible(true);
  };

  const hideNewUserModal = () => {
    setNewUserModalVisible(false);
  };

  const hideAddUserModal = () => {
    setAddUserModalVisible(false);
  };

  const hideLogoutModal = () => {
    setLogOutVisible(false);
  };

  const hideLoginModal = () => {
    setLoginVisible(false);
  };

  /******************************
    Button Handlers
  ******************************/

  const logInButton = () => {
    // e.stopPropagation();
    hideNewUserModal();
    showLoginModal();
  };

  const signUpButton = () => {
    // e.stopPropagation();
    hideLoginModal();
    showNewUserModal();
  };

  const logOutButton = () => (
    <Button
      style={{
        margin: 0,
        display: null != selectedUser ? "block" : "none",
        visibility: null != selectedUser ? "visible" : "hidden",
      }}
      type="primary"
      ghost
      icon={<PlusOutlined />}
      onClick={(e) => {
        e.stopPropagation();
        showLogoutModal();
      }}
    >
      Logout
    </Button>
  );


  const uploadImageToMega = (dataUrl, fileName) => {
    return new Promise((resolve, reject) => {
      const storage = new Storage({
        email: process.env.MEGA_USER,
        password: process.env.MEGA_PASS,
      }, (error) => {
        if (error) {
          console.error('MEGA login error:', error);
          reject(error);
          return;
        }

        const folder = storage.root.children.find(child => child.name === 'MANTRA-Turkey');
        if (!folder) {
          console.error('MEGA folder not found');
          reject(new Error('MEGA folder not found'));
          return;
        }

        // Convert base64-encoded data URL to Uint8Array
        const byteString = atob(dataUrl.split(',')[1]);
        const buffer = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i++) {
          buffer[i] = byteString.charCodeAt(i);
        }

        folder.upload({ name: fileName, size: buffer.length }, buffer, (error, file) => {
          if (error) {
            console.error('MEGA upload error:', error);
            reject(error);
            return;
          }

          console.log('File uploaded to MEGA:', file);

          file.link((error, link) => {
            if (error) {
              console.error('MEGA link error:', error);
              reject(error);
              return;
            }

            console.log('File link:', link);

            // Generate QR code and resolve with the component
            resolve(
              <div className="qr-code">
                <QRCode value={link} />
              </div>
            );
          });
        });
      });
    });
  };

  // Handle OAuth redirect
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');

    if (code) {
      getTokenFromCode(code).then(accessToken => {
        if (accessToken) {
          setAuthenticated(true);
          window.history.replaceState({}, document.title, window.location.pathname); // Remove code from URL
        }
      });
    } else if (getAccessToken()) {
      setAuthenticated(true);
    }
  }, []);

  const captureFinalScreenshot = async () => {
    const chartElement = chartRef.current;
    const canvasElement = canvasRef.current;
    const images = Array.from(document.querySelectorAll('.image-capture'));

    if (!chartElement || !canvasElement) {
      console.error('Chart element or canvas element not found');
      return;
    }

    const binauralTriggerElement = document.querySelector('.binaural-trigger');
    if (binauralTriggerElement) {
      binauralTriggerElement.style.display = 'none';
    }

    try {
      const canvasImage = await domtoimage.toJpeg(canvasElement);
      const chartImage = await domtoimage.toJpeg(chartElement);
      const imagesDataUrls = await Promise.all(images.map(img => domtoimage.toJpeg(img)));

      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');

      const width = Math.max(chartElement.offsetWidth, canvasElement.offsetWidth);
      const height = canvasElement.offsetHeight + chartElement.offsetHeight + (images.length * chartElement.offsetHeight);

      canvas.width = width;
      canvas.height = height;

      const canvasImg = new Image();
      canvasImg.src = canvasImage;

      const chartImg = new Image();
      chartImg.src = chartImage;

      await Promise.all([
        new Promise(resolve => {
          canvasImg.onload = resolve;
        }),
        new Promise(resolve => {
          chartImg.onload = resolve;
        })
      ]);

      context.drawImage(canvasImg, 0, 0);
      context.drawImage(chartImg, 0, canvasElement.offsetHeight);

      for (let i = 0; i < imagesDataUrls.length; i++) {
        const img = new Image();
        img.src = imagesDataUrls[i];

        await new Promise(resolve => {
          img.onload = () => {
            context.drawImage(img, 0, canvasElement.offsetHeight + chartElement.offsetHeight + (i * chartElement.offsetHeight));
            resolve();
          };
        });
      }

      const timestamp = new Date(Date.now()).getTime();
      const fileName = `${selectedUser.firstName}-${selectedUser.lastName}_VocalAnalysis_${timestamp}.jpg`;

      const link = document.createElement('a');
      link.href = canvas.toDataURL('image/jpeg');
      link.download = fileName;
      link.click();

      setLoading(true);

      const sharedLink = await uploadImageToDropbox(canvas.toDataURL('image/jpeg'), fileName);

      const siteRoot = 'https://report.mantramachine.xyz';
      const externalSiteUrl = `${siteRoot}?firstname=${encodeURIComponent(selectedUser.firstName)}&lastname=${encodeURIComponent(selectedUser.lastName)}&activation=VocalAnalysis&timestamp=${timestamp}&sharedlink=${encodeURIComponent(sharedLink)}`;
      setQrCode(<>
        <QRCode value={externalSiteUrl} size={256} /><br/>
        <a href={externalSiteUrl} target="_blank" alt="Vocal Analysis Report">Vocal Analysis Report</a>
        </>
      );
    } catch (error) {
      console.error('Error capturing or uploading screenshot:', error);
    } finally {
      setLoading(false);

      if (binauralTriggerElement) {
        binauralTriggerElement.style.display = 'block';
      }
    }
  };

  /******************************
    JSX View
  ******************************/

  return (
    <>
      
      <Modal
        className="user-modal"
        title="Add A User"
        open={addUserModalVisible}
        okText="Add User"
        onOk={() => {
          handleSubmitAddUser();
        }}
        // okButtonProps={{ disabled: isPlaylistDisabled }}
        onCancel={() => setAddUserModalVisible(false)}
      >

        <Form ref={formRef} name="basic" className="playlist-info-form" whitespace="false" labelCol={{ span: 3 }} wrapperCol={{ span: 21 }} initialValues={{ remember: false }} style={{ width: "100%", textAlignLast: "left" }}>
          <Row className="form-group">
            <Col span={24} style={{ paddingRight: 8 }}>
              <label>Email</label>
              <br />
              <Form.Item
                value={credentials.email}
                onChange={(e) => handleEmail(e)}
                rules={[
                  { required: true, message: "Please enter a valid email" },
                  {
                    pattern: new RegExp(/^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,9}(?:\.[a-z]{2})?)$/i),
                    message: "Invalid Characters",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row className="form-group">
            <Col span={24} style={{ paddingRight: 8 }}>
              <label>First Name</label>
              <br />
              <Form.Item value={credentials.firstName} onChange={(e) => handleFirstName(e)} rules={[{ required: true, message: "Please enter your first name" }]}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row className="form-group">
            <Col span={24} style={{ paddingRight: 8 }}>
              <label>Last Name</label>
              <br />
              <Form.Item value={credentials.lastName} onChange={(e) => handleLastName(e)} rules={[{ required: true, message: "Please enter your last name" }]}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row className="form-group">
            <Col span={24} style={{ paddingRight: 8 }}>
              <label>Goal?</label>
              <br />
              <Form.Item value={credentials.goal} onChange={(e) => handleGoal(e)} rules={[{ required: true, message: "Please enter a goal you want to achieve" }]}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          
        </Form>
      </Modal>

      <Modal
        className="user-modal"
        title="Sign Up"
        open={newUserModalVisible}
        okText="Sign Up"
        onOk={() => {
          handleSubmitSignUp();
        }}
        // okButtonProps={{ disabled: isPlaylistDisabled }}
        onCancel={() => setNewUserModalVisible(false)}
      >
        <Form ref={formRef} name="basic" className="playlist-info-form" whitespace="false" labelCol={{ span: 3 }} wrapperCol={{ span: 21 }} initialValues={{ remember: false }} style={{ width: "100%", textAlignLast: "left" }}>
          <Row className="form-group">
            <Col span={24} style={{ paddingRight: 8 }}>
              <label>Email</label>
              <br />
              <Form.Item
                value={credentials.email}
                onChange={(e) => handleEmail(e)}
                rules={[
                  { required: true, message: "Please enter a valid email" },
                  {
                    pattern: new RegExp(/^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,9}(?:\.[a-z]{2})?)$/i),
                    message: "Invalid Characters",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row className="form-group">
            <Col span={24} style={{ paddingRight: 8 }}>
              <label>First Name</label>
              <br />
              <Form.Item value={credentials.firstName} onChange={(e) => handleFirstName(e)} rules={[{ required: true, message: "Please enter your first name" }]}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row className="form-group">
            <Col span={24} style={{ paddingRight: 8 }}>
              <label>Last Name</label>
              <br />
              <Form.Item value={credentials.lastName} onChange={(e) => handleLastName(e)} rules={[{ required: true, message: "Please enter your last name" }]}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row className="form-group">
            <Col span={24} style={{ paddingRight: 8 }}>
              <label>Goal?</label>
              <br />
              <Form.Item value={credentials.goal} onChange={(e) => handleGoal(e)} rules={[{ required: true, message: "Please enter a goal you want to achieve" }]}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row className="form-group">
            <Col span={24} style={{ paddingRight: 8 }}>
              <label>Password</label>
              <br />
              <Form.Item value={credentials.password} onChange={(e) => handlePassword(e)} rules={[{ required: true, message: "Please enter your password" }]}>
                <Input.Password placeholder="input password" iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
              </Form.Item>
            </Col>
          </Row>
          <Row className="form-group">
            <Col span={24} style={{ paddingRight: 8 }}>
              <label>Confirm Password</label>
              <br />
              <Form.Item value={credentials.confirmPassword} onChange={(e) => handleConfirmPassword(e)} rules={[{ required: true, message: "Please confirm your password" }]}>
                <Input.Password placeholder="input password" iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
              </Form.Item>
            </Col>
          </Row>
          <Row className="form-group">
            <Col span={24} style={{ paddingRight: 8 }}>
              <div style={{ backgroundColor: "transparent !important", border: "0px !important" }} onClick={logInButton}>
                Already signed up?
              </div>
            </Col>
          </Row>
        </Form>
      </Modal>

      <Modal
        className="user-modal"
        title="Sign In"
        open={loginVisible}
        okText="Sign In"
        onOk={() => {
          handleSubmit();
        }}
        // okButtonProps={{ disabled: isPlaylistDisabled }}
        onCancel={() => setLoginVisible(false)}
      >
        <Form ref={formRef} name="basic" className="playlist-info-form" whitespace="false" labelCol={{ span: 3 }} wrapperCol={{ span: 21 }} initialValues={{ remember: false }} style={{ width: "100%", textAlignLast: "left" }}>
          <Row className="form-group">
            <Col span={24} style={{ paddingRight: 8 }}>
              <label>Email</label>
              <br />
              <Form.Item value={credentials.email} onChange={(e) => handleEmail(e)}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row className="form-group">
            <Col span={24} style={{ paddingRight: 8 }}>
              <label>Password</label>
              <br />
              <Form.Item value={credentials.password} onChange={(e) => handlePassword(e)}>
                <Input.Password placeholder="input password" iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
              </Form.Item>
            </Col>
          </Row>
          <Row className="form-group">
            <Col span={24} style={{ paddingRight: 8 }}>
              <div style={{ backgroundColor: "transparent !important", border: "0px !important" }} onClick={signUpButton}>
                Need to sign up?
              </div>
            </Col>
          </Row>
        </Form>
      </Modal>
      <Alert
        style={{
          display: logOutVisible ? "grid" : "none",
          visibility: logOutVisible ? "visible" : "hidden",
          position: "absolute",
          top: "33%",
          width: "50%",
          height: "200px",
          zIndex: "999999999",
          textAlign: "center",
          alignSelf: "center",
          justifyItems: "center",
        }}
        message="Confirm Logout"
        type="info"
        showIcon
        action={
          <Space>
            <Button size="small" type="ghost" onClick={handleLogout}>
              Logout
            </Button>
            <Button size="small" type="ghost" onClick={hideLogOut}>
              Cancel
            </Button>
          </Space>
        }
      />
      <Row
        style={{
          display: "inline-flex",
          margin: "10px 0px",
          width: null != sessionUser.uid ? "40%" : "22%",
          justifyContent: "left",
        }}
      >
        <Col
          span={12}
          style={{
            textAlign: "center",
            marginRight: "10px",
            maxWidth: "150px",
            display: null != sessionUser.uid ? "block" : "none",
            visibility: null != sessionUser.uid ? "visible" : "hidden",
          }}
        >
          Logged in as:
          <br />
          {sessionUser.firstName + " " + sessionUser.lastName}
        </Col>

        <Button
          id="newBtn"
          style={{
            marginRight: "10px",
            width: "125px",
            display: null == selectedUser ? "block" : "none",
            visibility: null == selectedUser ? "visible" : "hidden",
          }}
          className="signUpBtn"
          type="primary"
          ghost
          icon={<PlusOutlined />}
          onClick={(e) => {
            e.stopPropagation();
            hideNewUserModal();
            showLoginModal();
          }}
        >
          Sign In
        </Button>

        <Button
          id="newBtn"
          style={{
            width: "125px",
            display: null == selectedUser ? "block" : "none",
            visibility: null == selectedUser ? "visible" : "hidden",
          }}
          className="signUpBtn"
          type="primary"
          ghost
          icon={<PlusOutlined />}
          onClick={(e) => {
            e.stopPropagation();
            hideLoginModal();
            showNewUserModal();
          }}
        >
          Sign Up
        </Button>
        <Button
          id="newBtn"
          style={{
            width: "125px",
            display: null != selectedUser ? "block" : "none",
            visibility: null != selectedUser ? "visible" : "hidden",
          }}
          type="primary"
          ghost
          onClick={(e) => {
            e.stopPropagation();
            showLogoutModal();
          }}
        >
          Logout
        </Button>
        <Button
          id="newBtnTwo"
          style={{
            marginRight: "10px",
            marginLeft: "20px",
            width: "125px",
            display: (isBldgAdmin || sessionUser.isAdmin) ? "block" : "none",
            visibility: (isBldgAdmin || sessionUser.isAdmin) ? "visible" : "hidden",
          }}
          className="signUpBtn"
          type="primary"
          ghost
          icon={<PlusOutlined />}
          onClick={(e) => {
            e.stopPropagation();
            showAddUserModal();
          }}
        >
          Add A User
        </Button>
      </Row>

      <br />
      {false && JSON.stringify(fundamentalizerStatus)}
      <Row style={{ margin: "10px 0px" }}>
        <Button style={{ marginRight: "10px", width: "150px" }} type="primary" ghost disabled={!fundamentalizerDevice} onClick={() => activateFundamentalizer(fundamentalizerStatus)}>
          {fundamentalizerTriggerLabel(fundamentalizerStatus)}
        </Button>
        <Button style={{ width: "150px" }} type="primary" ghost danger disabled={!fundamentalizerDevice} onClick={() => resetFundamentalizer()} ref={resetButton}>
          RESET
        </Button>
      </Row>
      <Row>
      </Row>
      <Row
        style={{
          width: "300px",
          marginBottom: "20px",
          display: (isBldgAdmin || sessionUser.isAdmin) ? "block" : "none",
          visibility: (isBldgAdmin || sessionUser.isAdmin) ? "visible" : "hidden",
        }}
      >
        <Select showSearch style={{
          width: "auto",
          minWidth: "300px",
          maxWidth: "500px" }}
          value={JSON.stringify(selectedUser) === "null" ? selectedUser : (selectedUser.firstName + " " + selectedUser.lastName)}
          onChange={handleUserChange}
          placeholder="Select User"
          optionFilterProp="children"
          filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
          filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}>
          {mapUserArrayOptions()}
        </Select>
      </Row>
      <Col span={24}>
        <canvas id="vis-canvas" ref={canvasRef} />
      </Col>
      <Col span={24} ref={chartRef}>
        {fundamentalDisplay()}
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={14}>
            {false && JSON.stringify(fundamentalInfo)}
            {notesArray.map((n, index) => (
              <Row key={index}>
                <Col xs={2} sm={1} md={1} lg={1} xl={1} className="note-label" style={{ border: "1px solid #303030", fontSize: 12 }}>
                  {n.note}
                </Col>
                <Col xs={16} sm={18} md={18} lg={18} xl={18} style={{ border: "1px solid #303030" }}>
                  <Col
                    style={{
                      borderLeft: "6px solid black",
                      borderRight: "6px solid black",
                      textAlign: "end",
                      height: "100%",
                      width: (fundamentalNoteCount.find((c) => c.note === n.note).count > 0 ? ((fundamentalNoteCount.find((c) => c.note === n.note).count / fundamentalInfo.highestCount) * 100).toFixed(6) : (0).toFixed(6)) + "%",
                      backgroundColor: colorArray.find((c) => c.note === n.note).color,
                    }}
                  >
                    <Col
                      style={{
                        borderLeft: "2px solid black",
                        position: "absolute",
                        right: 0,
                        height: "100%",
                        width: 6,
                        backgroundColor: colorArray.find((c) => c.note === n.note).color,
                      }}
                    />
                  </Col>
                </Col>
                <Col xs={2} sm={2} md={2} lg={2} xl={2} style={{ border: "1px solid #303030", paddingRight: 8, textAlign: "-webkit-right", fontSize: 12 }}>
                  {fundamentalNoteCount.find((c) => c.note === n.note).count}
                </Col>
                <Col xs={4} sm={3} md={3} lg={3} xl={3} style={{ border: "1px solid #303030", textAlign: "-webkit-center", fontSize: 12 }}>
                  {fundamentalNoteCount.find((c) => c.note === n.note).count > 0 ? ((fundamentalNoteCount.find((c) => c.note === n.note).count / fundamentalInfo.totalNotes) * 100).toFixed(6) : (0).toFixed(6)}
                </Col>
              </Row>
            ))}
          </Col>
          <Col xs={24} sm={10} md={10} lg={10} xl={4} id="shiny-container">
            <canvas id="shiny-canvas" ref={shinyRef} width="600" height="600" />
          </Col>
          <Col xs={24} sm={14} md={14} lg={14} xl={6} style={{ backgroundColor: fundamentalInfo.colorSpace.rgb, border: "1px solid #303030" }} />
        </Row>
      </Col>
      {false && (
        <Row>
          <Button type="primary" ghost disabled={!fundamentalizerDevice} onClick={() => activateFundamentalizer(fundamentalizerStatus)}>
            {fundamentalizerTriggerLabel(fundamentalizerStatus)}
          </Button>
          <Button style={{ width: "150px" }} type="primary" ghost danger disabled={!fundamentalizerDevice} onClick={() => resetFundamentalizer()} ref={resetButton}>
            RESET
          </Button>
          <Button id="newBtn" type="primary" onClick={() => openColorWindow(true)}>
            EXPAND COLOR WINDOW
          </Button>
          <Button id="newBtn" type="primary" onClick={() => chartToImg()}>
            SCREENCAP ANALYSIS
          </Button>
          <Button id="newBtn" type="primary" disabled={!fundamentalizerStatus.includes("enabled") ? false : true} onClick={() => downloadLink.current.click()}>
            DOWNLOAD RECORDING
            <a ref={downloadLink} style={{ display: "none" }} />
          </Button>
          {false && fundamentalizerStatus + " | " + fundamentalizerDevice}
        </Row>
      )}
      <Row className="binaural-trigger">
        <Col span={24}>
          <Select style={{ width: 180 }} value={JSON.stringify(selectedBrainstate)} onChange={handleBrainstateChange} placeholder={selectedBrainstate.name} disabled={binauralPlaying === false ? false : true}>
            {mapBrainstates()}
          </Select>
          <Button id="newBtn" type="primary" onClick={() => (binauralPlaying === false ? generateBinaural() : stopBinaural())}>
            {binauralPlaying === false ? "GENERATE BINAURAL" : "STOP BINAURAL"}
          </Button>
          <Button type="primary" className="screenshot" onClick={() => chartToImg()}>
            <CameraOutlined />
          </Button>
        </Col>
        {binauralPlaying && (
          <Col span={24}>
            <Row>
              <Tag color={"geekblue"} key={"binauralLeft"} className={"binaural-frequency"}>
                {binauralFrequency[0].toFixed(3) + "Hz"}
              </Tag>
              <Tag color={"green"} key={"binauralRight"} className={"binaural-frequency"}>
                {binauralFrequency[1].toFixed(3) + "Hz"}
              </Tag>
            </Row>
            <Col xs={24} sm={6} md={6} lg={6} xl={6}>
              <Vectorscope className="vectorscope-canvas" timeDomainL={timeDomainL} timeDomainR={timeDomainR} />
            </Col>
          </Col>
        )}
      </Row>
      <Row ref={imgRef} className="image-capture" />
      {true && (
        <Row>
          {false && <audio id="player" ref={playerRef} controls></audio>}
          {false && <SpeechTranscription socketID={props.socketID} active={fundamentalizerStatus.includes("enabled") ? true : fundamentalizerStatus.includes("disabled") ? false : "reset"} fundamentalNoteCount={fundamentalNoteCount.map((n, index) => ({ ...n, color: rgbToHex(colorArray.find((c) => c.note === n.note).color) }))} />}
        </Row>
      )}
      <Row>
      {props.skyboxImage.length !== 0 ?
        <PanoViewer img={props.skyboxImage} /> :
        null
      }
      </Row>
      <div className="fundamentalizer">
        <Row>
          <Col span={24} style={{ textAlign: 'center', padding: '20px' }}>
            {loading && (
              <div>
                <Spin size="large" /><br />
                Please wait while we generate your results...
              </div>
            )}
            {qrCode && (
              <div className="qr-code-container">
                {qrCode}
              </div>
            )}
            <Button type="primary" onClick={captureFinalScreenshot}>
              Generate Session Results
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );
});
// Fundamentalizer.whyDidYouRender = false;
export default Fundamentalizer;
