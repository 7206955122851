// ****************************************************************
//
// This file is subject to the terms and conditions defined in file
// 'LICENSE.txt', which can be found at the root of this project.
//
// ****************************************************************

const REALITY_MGMT_JWT_TOKEN = 'REALITY_MGMT_JWT_TOKEN';
const REALITY_MGMT_UID = 'REALITY_MGMT_UID';
const api_url = "https://api.realitymgmt.com:3000/";
const happyApi_url = "https://happy-place.onrender.com/";
// const api_url = "http://localhost:2000/";
const base_url = api_url + "live/v1/";
const auth_url = api_url + "auth/v1/live/";
const login_url = auth_url + "login/";
const users_url = base_url + "usersNoSession/";
const account_url = base_url + "account/";
const cards_url = account_url + "cards/";
const signup_url = base_url + "signup/";
const sessionData_url = base_url + 'sessionData/';
const newUser_url = base_url + 'newUserSignUp/';
const users_v1_url = base_url + 'users/';
const card_token_v1_url = base_url + 'addCardToken/';
const DEFAULT_AVATAR_URL = "https://firebasestorage.googleapis.com/v0/b/innersense-server.appspot.com/o/Defaults%2Fdefault-avatar.png?alt=media&token=47264565-ba45-4dc6-8251-f164a578c396";
const DEFAULT_ORG = {
  id: "-NSNansYT3oyzJ_TI3Xn",
  isAdmin: false,
  name: "Reality Center",
  buildings: [{
      isAdmin: false,
      id: "-NSNauiIu9Ehp5rCgyoe",
      name: "Reality Center"
  }]
};

/********************************************************************
LIVE STRIPE API KEY
********************************************************************/
const STRIPE_API_TOKEN = "pk_live_51NQyYkGb5shQ8pliI1bAUjpLMl051TVLLdwE9rTqXml6mJLb6rNOk7PtjPrT02NmNSRCwT1oqa5gGEA7vtJQIwEk00KdYiUrh1";


/********************************************************************
TEST STRIPE API KEY
********************************************************************/
// const STRIPE_API_TOKEN = 'rk_test_51NQyYkGb5shQ8pliu0h8ymskAPEjX5HFcb5DBavFs9EFY4ta6V5INo3rLMc7SPhdlBzKQZG2atWrYthOhkeovP3400m6TamTPJ'

const STATE_LIST = [
  "AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DC", "DE", "FL", "GA",
  "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MD",
  "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ",
  "NM", "NY", "NC", "ND", "OH", "OK", "OR", "PA", "RI", "SC",
  "SD", "TN", "TX", "UT", "VT", "VA", "WA", "WV", "WI", "WY"
];
const notesArray = [
    {
      note: "F",
      count: 0,
    },
    {
      note: "E",
      count: 0,
    },
    {
      note: "D#",
      count: 0,
    },
    {
      note: "D",
      count: 0,
    },
    {
      note: "C#",
      count: 0,
    },
    {
      note: "C",
      count: 0,
    },
    {
      note: "B",
      count: 0,
    },
    {
      note: "A#",
      count: 0,
    },
    {
      note: "A",
      count: 0,
    },
    {
      note: "G#",
      count: 0,
    },
    {
      note: "G",
      count: 0,
    },
    {
      note: "F#",
      count: 0,
    },
  ];
const harmonicsLabel = [
    "Fundamental",
    "2nd Harmonic",
    "3rd Harmonic",
    "4th Harmonic",
    "5th Harmonic",
    "6th Harmonic",
    "7th Harmonic",
    "8th Harmonic",
    "9th Harmonic",
    "10th Harmonic",
    "11th Harmonic",
    "12th Harmonic",
  ];

const harmonicsLabelMobile = [
    "Fund.",
    "2nd",
    "3rd",
    "4th",
    "5th",
    "6th",
    "7th",
    "8th",
    "9th",
    "10th",
    "11th",
    "12th",
  ];

const defaultFundamentalizerInfo = {
    frequency: 0,
    harmonicsArray: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    nearestNote: {
      note: "",
      frequency: 0,
    },
    cents: 0,
    visibleFrequency: 0,
    wavelength: 0,
    colorSpace: {
      rgb: "rgb(0%, 0%, 0%)",
      red: 0,
      green: 0,
      blue: 0,
      alpha: 0,
    },
    fundamentalNoteCount: [
      { note: "C", count: 0 },
      { note: "C#", count: 0 },
      { note: "D", count: 0 },
      { note: "D#", count: 0 },
      { note: "E", count: 0 },
      { note: "F", count: 0 },
      { note: "F#", count: 0 },
      { note: "G", count: 0 },
      { note: "G#", count: 0 },
      { note: "A", count: 0 },
      { note: "A#", count: 0 },
      { note: "B", count: 0 },
    ],
    highestCount: 0,
    totalNoteCount: 0,
    fundamentalAverage: {
      note: "--",
      frequency: 0
    },
    fundamentalMode: {
      note: "--",
      frequency: 0
    }
  };

const notesJson = {
  "432": [
    {
      "note":"C0",
      "frequency":16.05
    },
    {
      "note":"C#0",
      "frequency":17.01
    },
    {
      "note":"D0",
      "frequency":18.02
    },
    {
      "note":"D#0",
      "frequency":19.09
    },
    {
      "note":"E0",
      "frequency":20.23
    },
    {
      "note":"F0",
      "frequency":21.43
    },
    {
      "note":"F#0",
      "frequency":22.7
    },
    {
      "note":"G0",
      "frequency":24.05
    },
    {
      "note":"G#0",
      "frequency":25.48
    },
    {
      "note":"A0",
      "frequency":27
    },
    {
      "note":"A#0",
      "frequency":28.61
    },
    {
      "note":"B0",
      "frequency":30.31
    },
    {
      "note":"C1",
      "frequency":32.11
    },
    {
      "note":"C#1",
      "frequency":34.02
    },
    {
      "note":"D1",
      "frequency":36.04
    },
    {
      "note":"D#1",
      "frequency":38.18
    },
    {
      "note":"E1",
      "frequency":40.45
    },
    {
      "note":"F1",
      "frequency":42.86
    },
    {
      "note":"F#1",
      "frequency":45.41
    },
    {
      "note":"G1",
      "frequency":48.11
    },
    {
      "note":"G#1",
      "frequency":50.97
    },
    {
      "note":"A1",
      "frequency":54
    },
    {
      "note":"A#1",
      "frequency":57.21
    },
    {
      "note":"B1",
      "frequency":60.61
    },
    {
      "note":"C2",
      "frequency":64.22
    },
    {
      "note":"C#2",
      "frequency":68.04
    },
    {
      "note":"D2",
      "frequency":72.08
    },
    {
      "note":"D#2",
      "frequency":76.37
    },
    {
      "note":"E2",
      "frequency":80.91
    },
    {
      "note":"F2",
      "frequency":85.72
    },
    {
      "note":"F#2",
      "frequency":90.82
    },
    {
      "note":"G2",
      "frequency":96.22
    },
    {
      "note":"G#2",
      "frequency":101.94
    },
    {
      "note":"A2",
      "frequency":108
    },
    {
      "note":"A#2",
      "frequency":114.42
    },
    {
      "note":"B2",
      "frequency":121.23
    },
    {
      "note":"C3",
      "frequency":128.43
    },
    {
      "note":"C#3",
      "frequency":136.07
    },
    {
      "note":"D3",
      "frequency":144.16
    },
    {
      "note":"D#3",
      "frequency":152.74
    },
    {
      "note":"E3",
      "frequency":161.82
    },
    {
      "note":"F3",
      "frequency":171.44
    },
    {
      "note":"F#3",
      "frequency":181.63
    },
    {
      "note":"G3",
      "frequency":192.43
    },
    {
      "note":"G#3",
      "frequency":203.88
    },
    {
      "note":"A3",
      "frequency":216
    },
    {
      "note":"A#3",
      "frequency":228.84
    },
    {
      "note":"B3",
      "frequency":242.45
    },
    {
      "note":"C4",
      "frequency":256.87
    },
    {
      "note":"C#4",
      "frequency":272.14
    },
    {
      "note":"D4",
      "frequency":288.33
    },
    {
      "note":"D#4",
      "frequency":305.47
    },
    {
      "note":"E4",
      "frequency":323.63
    },
    {
      "note":"F4",
      "frequency":342.88
    },
    {
      "note":"F#4",
      "frequency":363.27
    },
    {
      "note":"G4",
      "frequency":384.87
    },
    {
      "note":"G#4",
      "frequency":407.75
    },
    {
      "note":"A4",
      "frequency":432
    },
    {
      "note":"A#4",
      "frequency":457.69
    },
    {
      "note":"B4",
      "frequency":484.9
    },
    {
      "note":"C5",
      "frequency":513.74
    },
    {
      "note":"C#5",
      "frequency":544.29
    },
    {
      "note":"D5",
      "frequency":576.65
    },
    {
      "note":"D#5",
      "frequency":610.94
    },
    {
      "note":"E5",
      "frequency":647.27
    },
    {
      "note":"F5",
      "frequency":685.76
    },
    {
      "note":"F#5",
      "frequency":726.53
    },
    {
      "note":"G5",
      "frequency":769.74
    },
    {
      "note":"G#5",
      "frequency":815.51
    },
    {
      "note":"A5",
      "frequency":864
    },
    {
      "note":"A#5",
      "frequency":915.38
    },
    {
      "note":"B5",
      "frequency":969.81
    },
    {
      "note":"C6",
      "frequency":1027.47
    },
    {
      "note":"C#6",
      "frequency":1088.57
    },
    {
      "note":"D6",
      "frequency":1153.3
    },
    {
      "note":"D#6",
      "frequency":1221.88
    },
    {
      "note":"E6",
      "frequency":1294.54
    },
    {
      "note":"F6",
      "frequency":1371.51
    },
    {
      "note":"F#6",
      "frequency":1453.07
    },
    {
      "note":"G6",
      "frequency":1539.47
    },
    {
      "note":"G#6",
      "frequency":1631.01
    },
    {
      "note":"A6",
      "frequency":1728
    },
    {
      "note":"A#6",
      "frequency":1830.75
    },
    {
      "note":"B6",
      "frequency":1939.61
    },
    {
      "note":"C7",
      "frequency":2054.95
    },
    {
      "note":"C#7",
      "frequency":2177.14
    },
    {
      "note":"D7",
      "frequency":2306.6
    },
    {
      "note":"D#7",
      "frequency":2443.76
    },
    {
      "note":"E7",
      "frequency":2589.07
    },
    {
      "note":"F7",
      "frequency":2743.03
    },
    {
      "note":"F#7",
      "frequency":2906.14
    },
    {
      "note":"G7",
      "frequency":3078.95
    },
    {
      "note":"G#7",
      "frequency":3262.03
    },
    {
      "note":"A7",
      "frequency":3456
    },
    {
      "note":"A#7",
      "frequency":3661.5
    },
    {
      "note":"B7",
      "frequency":3879.23
    },
    {
      "note":"C8",
      "frequency":4109.9
    },
    {
      "note":"C#8",
      "frequency":4354.29
    },
    {
      "note":"D8",
      "frequency":4613.21
    },
    {
      "note":"D#8",
      "frequency":4887.52
    },
    {
      "note":"E8",
      "frequency":5178.15
    },
    {
      "note":"F8",
      "frequency":5486.06
    },
    {
      "note":"F#8",
      "frequency":5812.28
    },
    {
      "note":"G8",
      "frequency":6157.89
    },
    {
      "note":"G#8",
      "frequency":6524.06
    },
    {
      "note":"A8",
      "frequency":6912
    },
    {
      "note":"A#8",
      "frequency":7323.01
    },
    {
      "note":"B8",
      "frequency":7758.46
    },
    {
      "note":"C9",
      "frequency":8219.8
    },
    {
      "note":"C#9",
      "frequency":8708.58
    },
    {
      "note":"D9",
      "frequency":9226.42
    },
    {
      "note":"D#9",
      "frequency":9775.04
    },
    {
      "note":"E9",
      "frequency":10356.3
    },
    {
      "note":"F9",
      "frequency":10972.12
    },
    {
      "note":"F#9",
      "frequency":11624.56
    },
    {
      "note":"G9",
      "frequency":12315.78
    },
    {
      "note":"G#9",
      "frequency":13048.12
    },
    {
      "note":"A9",
      "frequency":13824
    },
    {
      "note":"A#9",
      "frequency":14646.02
    },
    {
      "note":"B9",
      "frequency":15516.92
    },
    {
      "note":"C10",
      "frequency":16439.6
    },
    {
      "note":"C#10",
      "frequency":8708.58
    },
    {
      "note":"D10",
      "frequency":17417.16
    },
    {
      "note":"D#10",
      "frequency":9775.04
    },
    {
      "note":"E10",
      "frequency":20712.6
    },
    {
      "note":"F10",
      "frequency":21944.24
    },
    {
      "note":"F#10",
      "frequency":23249.12
    },
    {
      "note":"G10",
      "frequency":24631.56
    },
    {
      "note":"G#10",
      "frequency":26096.24
    },
    {
      "note":"A10",
      "frequency":27648
    },
    {
      "note":"A#10",
      "frequency":29292.04
    },
    {
      "note":"B10",
      "frequency":31033.84
    }
  ],
  "434": [
    {
      "note":"C0",
      "frequency":16.13
    },
    {
      "note":"C#0",
      "frequency":17.09
    },
    {
      "note":"D0",
      "frequency":18.1
    },
    {
      "note":"D#0",
      "frequency":19.18
    },
    {
      "note":"E0",
      "frequency":20.32
    },
    {
      "note":"F0",
      "frequency":21.53
    },
    {
      "note":"F#0",
      "frequency":22.81
    },
    {
      "note":"G0",
      "frequency":24.17
    },
    {
      "note":"G#0",
      "frequency":25.6
    },
    {
      "note":"A0",
      "frequency":27.12
    },
    {
      "note":"A#0",
      "frequency":28.74
    },
    {
      "note":"B0",
      "frequency":30.45
    },
    {
      "note":"C1",
      "frequency":32.26
    },
    {
      "note":"C#1",
      "frequency":34.18
    },
    {
      "note":"D1",
      "frequency":36.21
    },
    {
      "note":"D#1",
      "frequency":38.36
    },
    {
      "note":"E1",
      "frequency":40.64
    },
    {
      "note":"F1",
      "frequency":43.06
    },
    {
      "note":"F#1",
      "frequency":45.62
    },
    {
      "note":"G1",
      "frequency":48.33
    },
    {
      "note":"G#1",
      "frequency":51.21
    },
    {
      "note":"A1",
      "frequency":54.25
    },
    {
      "note":"A#1",
      "frequency":57.48
    },
    {
      "note":"B1",
      "frequency":60.89
    },
    {
      "note":"C2",
      "frequency":64.51
    },
    {
      "note":"C#2",
      "frequency":68.35
    },
    {
      "note":"D2",
      "frequency":72.42
    },
    {
      "note":"D#2",
      "frequency":76.72
    },
    {
      "note":"E2",
      "frequency":81.28
    },
    {
      "note":"F2",
      "frequency":86.12
    },
    {
      "note":"F#2",
      "frequency":91.24
    },
    {
      "note":"G2",
      "frequency":96.66
    },
    {
      "note":"G#2",
      "frequency":102.41
    },
    {
      "note":"A2",
      "frequency":108.5
    },
    {
      "note":"A#2",
      "frequency":114.95
    },
    {
      "note":"B2",
      "frequency":121.79
    },
    {
      "note":"C3",
      "frequency":129.03
    },
    {
      "note":"C#3",
      "frequency":136.7
    },
    {
      "note":"D3",
      "frequency":144.83
    },
    {
      "note":"D#3",
      "frequency":153.44
    },
    {
      "note":"E3",
      "frequency":162.57
    },
    {
      "note":"F3",
      "frequency":172.23
    },
    {
      "note":"F#3",
      "frequency":182.47
    },
    {
      "note":"G3",
      "frequency":193.32
    },
    {
      "note":"G#3",
      "frequency":204.82
    },
    {
      "note":"A3",
      "frequency":217
    },
    {
      "note":"A#3",
      "frequency":229.9
    },
    {
      "note":"B3",
      "frequency":243.57
    },
    {
      "note":"C4",
      "frequency":258.06
    },
    {
      "note":"C#4",
      "frequency":273.4
    },
    {
      "note":"D4",
      "frequency":289.66
    },
    {
      "note":"D#4",
      "frequency":306.88
    },
    {
      "note":"E4",
      "frequency":325.13
    },
    {
      "note":"F4",
      "frequency":344.47
    },
    {
      "note":"F#4",
      "frequency":364.95
    },
    {
      "note":"G4",
      "frequency":386.65
    },
    {
      "note":"G#4",
      "frequency":409.64
    },
    {
      "note":"A4",
      "frequency":434
    },
    {
      "note":"A#4",
      "frequency":459.81
    },
    {
      "note":"B4",
      "frequency":487.15
    },
    {
      "note":"C5",
      "frequency":516.12
    },
    {
      "note":"C#5",
      "frequency":546.81
    },
    {
      "note":"D5",
      "frequency":579.32
    },
    {
      "note":"D#5",
      "frequency":613.77
    },
    {
      "note":"E5",
      "frequency":650.27
    },
    {
      "note":"F5",
      "frequency":688.93
    },
    {
      "note":"F#5",
      "frequency":729.9
    },
    {
      "note":"G5",
      "frequency":773.3
    },
    {
      "note":"G#5",
      "frequency":819.28
    },
    {
      "note":"A5",
      "frequency":868
    },
    {
      "note":"A#5",
      "frequency":919.61
    },
    {
      "note":"B5",
      "frequency":974.3
    },
    {
      "note":"C6",
      "frequency":1032.23
    },
    {
      "note":"C#6",
      "frequency":1093.61
    },
    {
      "note":"D6",
      "frequency":1158.64
    },
    {
      "note":"D#6",
      "frequency":1227.54
    },
    {
      "note":"E6",
      "frequency":1300.53
    },
    {
      "note":"F6",
      "frequency":1377.86
    },
    {
      "note":"F#6",
      "frequency":1459.8
    },
    {
      "note":"G6",
      "frequency":1546.6
    },
    {
      "note":"G#6",
      "frequency":1638.57
    },
    {
      "note":"A6",
      "frequency":1736
    },
    {
      "note":"A#6",
      "frequency":1839.23
    },
    {
      "note":"B6",
      "frequency":1948.59
    },
    {
      "note":"C7",
      "frequency":2064.46
    },
    {
      "note":"C#7",
      "frequency":2187.22
    },
    {
      "note":"D7",
      "frequency":2317.28
    },
    {
      "note":"D#7",
      "frequency":2455.07
    },
    {
      "note":"E7",
      "frequency":2601.06
    },
    {
      "note":"F7",
      "frequency":2755.73
    },
    {
      "note":"F#7",
      "frequency":2919.59
    },
    {
      "note":"G7",
      "frequency":3093.2
    },
    {
      "note":"G#7",
      "frequency":3277.13
    },
    {
      "note":"A7",
      "frequency":3472
    },
    {
      "note":"A#7",
      "frequency":3678.46
    },
    {
      "note":"B7",
      "frequency":3897.19
    },
    {
      "note":"C8",
      "frequency":4128.93
    },
    {
      "note":"C#8",
      "frequency":4374.44
    },
    {
      "note":"D8",
      "frequency":4634.56
    },
    {
      "note":"D#8",
      "frequency":4910.15
    },
    {
      "note":"E8",
      "frequency":5202.12
    },
    {
      "note":"F8",
      "frequency":5511.46
    },
    {
      "note":"F#8",
      "frequency":5839.18
    },
    {
      "note":"G8",
      "frequency":6186.4
    },
    {
      "note":"G#8",
      "frequency":6554.26
    },
    {
      "note":"A8",
      "frequency":6944
    },
    {
      "note":"A#8",
      "frequency":7356.91
    },
    {
      "note":"B8",
      "frequency":7794.38
    },
    {
      "note":"C9",
      "frequency":8257.86
    },
    {
      "note":"C#9",
      "frequency":8748.88
    },
    {
      "note":"D9",
      "frequency":9269.12
    },
    {
      "note":"D#9",
      "frequency":9820.3
    },
    {
      "note":"E9",
      "frequency":10404.24
    },
    {
      "note":"F9",
      "frequency":11022.92
    },
    {
      "note":"F#9",
      "frequency":11678.36
    },
    {
      "note":"G9",
      "frequency":12372.8
    },
    {
      "note":"G#9",
      "frequency":13108.52
    },
    {
      "note":"A9",
      "frequency":13888
    },
    {
      "note":"A#9",
      "frequency":14713.82
    },
    {
      "note":"B9",
      "frequency":15588.76
    },
    {
      "note":"C10",
      "frequency":16515.72
    },
    {
      "note":"C#10",
      "frequency":17497.76
    },
    {
      "note":"D10",
      "frequency":18538.24
    },
    {
      "note":"D#10",
      "frequency":19640.6
    },
    {
      "note":"E10",
      "frequency":20808.48
    },
    {
      "note":"F10",
      "frequency":22045.84
    },
    {
      "note":"F#10",
      "frequency":23356.72
    },
    {
      "note":"G10",
      "frequency":24745.6
    },
    {
      "note":"G#10",
      "frequency":26217.04
    },
    {
      "note":"A10",
      "frequency":27776
    },
    {
      "note":"A#10",
      "frequency":29427.64
    },
    {
      "note":"B10",
      "frequency":31177.52
    }
  ],
  "436": [
    {
      "note":"C0",
      "frequency":16.2
    },
    {
      "note":"C#0",
      "frequency":17.17
    },
    {
      "note":"D0",
      "frequency":18.19
    },
    {
      "note":"D#0",
      "frequency":19.27
    },
    {
      "note":"E0",
      "frequency":20.41
    },
    {
      "note":"F0",
      "frequency":21.63
    },
    {
      "note":"F#0",
      "frequency":22.91
    },
    {
      "note":"G0",
      "frequency":24.28
    },
    {
      "note":"G#0",
      "frequency":25.72
    },
    {
      "note":"A0",
      "frequency":27.25
    },
    {
      "note":"A#0",
      "frequency":28.87
    },
    {
      "note":"B0",
      "frequency":30.59
    },
    {
      "note":"C1",
      "frequency":32.41
    },
    {
      "note":"C#1",
      "frequency":34.33
    },
    {
      "note":"D1",
      "frequency":36.37
    },
    {
      "note":"D#1",
      "frequency":38.54
    },
    {
      "note":"E1",
      "frequency":40.83
    },
    {
      "note":"F1",
      "frequency":43.26
    },
    {
      "note":"F#1",
      "frequency":45.83
    },
    {
      "note":"G1",
      "frequency":48.55
    },
    {
      "note":"G#1",
      "frequency":51.44
    },
    {
      "note":"A1",
      "frequency":54.5
    },
    {
      "note":"A#1",
      "frequency":57.74
    },
    {
      "note":"B1",
      "frequency":61.17
    },
    {
      "note":"C2",
      "frequency":64.81
    },
    {
      "note":"C#2",
      "frequency":68.67
    },
    {
      "note":"D2",
      "frequency":72.75
    },
    {
      "note":"D#2",
      "frequency":77.07
    },
    {
      "note":"E2",
      "frequency":81.66
    },
    {
      "note":"F2",
      "frequency":86.51
    },
    {
      "note":"F#2",
      "frequency":91.66
    },
    {
      "note":"G2",
      "frequency":97.11
    },
    {
      "note":"G#2",
      "frequency":102.88
    },
    {
      "note":"A2",
      "frequency":109
    },
    {
      "note":"A#2",
      "frequency":115.48
    },
    {
      "note":"B2",
      "frequency":122.35
    },
    {
      "note":"C3",
      "frequency":129.62
    },
    {
      "note":"C#3",
      "frequency":137.33
    },
    {
      "note":"D3",
      "frequency":145.5
    },
    {
      "note":"D#3",
      "frequency":154.15
    },
    {
      "note":"E3",
      "frequency":163.32
    },
    {
      "note":"F3",
      "frequency":173.03
    },
    {
      "note":"F#3",
      "frequency":183.32
    },
    {
      "note":"G3",
      "frequency":194.22
    },
    {
      "note":"G#3",
      "frequency":205.76
    },
    {
      "note":"A3",
      "frequency":218
    },
    {
      "note":"A#3",
      "frequency":230.96
    },
    {
      "note":"B3",
      "frequency":244.7
    },
    {
      "note":"C4",
      "frequency":259.25
    },
    {
      "note":"C#4",
      "frequency":274.66
    },
    {
      "note":"D4",
      "frequency":290.99
    },
    {
      "note":"D#4",
      "frequency":308.3
    },
    {
      "note":"E4",
      "frequency":326.63
    },
    {
      "note":"F4",
      "frequency":346.05
    },
    {
      "note":"F#4",
      "frequency":366.63
    },
    {
      "note":"G4",
      "frequency":388.43
    },
    {
      "note":"G#4",
      "frequency":411.53
    },
    {
      "note":"A4",
      "frequency":436
    },
    {
      "note":"A#4",
      "frequency":461.93
    },
    {
      "note":"B4",
      "frequency":489.39
    },
    {
      "note":"C5",
      "frequency":518.49
    },
    {
      "note":"C#5",
      "frequency":549.33
    },
    {
      "note":"D5",
      "frequency":581.99
    },
    {
      "note":"D#5",
      "frequency":616.6
    },
    {
      "note":"E5",
      "frequency":653.26
    },
    {
      "note":"F5",
      "frequency":692.11
    },
    {
      "note":"F#5",
      "frequency":733.26
    },
    {
      "note":"G5",
      "frequency":776.86
    },
    {
      "note":"G#5",
      "frequency":823.06
    },
    {
      "note":"A5",
      "frequency":872
    },
    {
      "note":"A#5",
      "frequency":923.85
    },
    {
      "note":"B5",
      "frequency":978.79
    },
    {
      "note":"C6",
      "frequency":1036.99
    },
    {
      "note":"C#6",
      "frequency":1098.65
    },
    {
      "note":"D6",
      "frequency":1163.98
    },
    {
      "note":"D#6",
      "frequency":1233.19
    },
    {
      "note":"E6",
      "frequency":1306.52
    },
    {
      "note":"F6",
      "frequency":1384.21
    },
    {
      "note":"F#6",
      "frequency":1466.52
    },
    {
      "note":"G6",
      "frequency":1553.73
    },
    {
      "note":"G#6",
      "frequency":1646.12
    },
    {
      "note":"A6",
      "frequency":1744
    },
    {
      "note":"A#6",
      "frequency":1847.7
    },
    {
      "note":"B6",
      "frequency":1957.57
    },
    {
      "note":"C7",
      "frequency":2073.98
    },
    {
      "note":"C#7",
      "frequency":2197.3
    },
    {
      "note":"D7",
      "frequency":2327.96
    },
    {
      "note":"D#7",
      "frequency":2466.39
    },
    {
      "note":"E7",
      "frequency":2613.05
    },
    {
      "note":"F7",
      "frequency":2768.43
    },
    {
      "note":"F#7",
      "frequency":2933.05
    },
    {
      "note":"G7",
      "frequency":3107.45
    },
    {
      "note":"G#7",
      "frequency":3292.23
    },
    {
      "note":"A7",
      "frequency":3488
    },
    {
      "note":"A#7",
      "frequency":3695.41
    },
    {
      "note":"B7",
      "frequency":3915.15
    },
    {
      "note":"C8",
      "frequency":4147.95
    },
    {
      "note":"C#8",
      "frequency":4394.6
    },
    {
      "note":"D8",
      "frequency":4655.92
    },
    {
      "note":"D#8",
      "frequency":4932.78
    },
    {
      "note":"E8",
      "frequency":5226.09
    },
    {
      "note":"F8",
      "frequency":5536.85
    },
    {
      "note":"F#8",
      "frequency":5866.09
    },
    {
      "note":"G8",
      "frequency":6214.91
    },
    {
      "note":"G#8",
      "frequency":6584.47
    },
    {
      "note":"A8",
      "frequency":6976
    },
    {
      "note":"A#8",
      "frequency":7390.81
    },
    {
      "note":"B8",
      "frequency":7830.3
    },
    {
      "note":"C9",
      "frequency":8295.9
    },
    {
      "note":"C#9",
      "frequency":8789.2
    },
    {
      "note":"D9",
      "frequency":9311.84
    },
    {
      "note":"D#9",
      "frequency":9865.56
    },
    {
      "note":"E9",
      "frequency":10452.18
    },
    {
      "note":"F9",
      "frequency":11073.7
    },
    {
      "note":"F#9",
      "frequency":11732.18
    },
    {
      "note":"G9",
      "frequency":12429.82
    },
    {
      "note":"G#9",
      "frequency":13168.94
    },
    {
      "note":"A9",
      "frequency":13952
    },
    {
      "note":"A#9",
      "frequency":14781.62
    },
    {
      "note":"B9",
      "frequency":15660.6
    },
    {
      "note":"C10",
      "frequency":16591.8
    },
    {
      "note":"C#10",
      "frequency":17578.4
    },
    {
      "note":"D10",
      "frequency":18623.68
    },
    {
      "note":"D#10",
      "frequency":19731.12
    },
    {
      "note":"E10",
      "frequency":20904.36
    },
    {
      "note":"F10",
      "frequency":22147.4
    },
    {
      "note":"F#10",
      "frequency":23464.36
    },
    {
      "note":"G10",
      "frequency":24859.64
    },
    {
      "note":"G#10",
      "frequency":26337.88
    },
    {
      "note":"A10",
      "frequency":27904
    },
    {
      "note":"A#10",
      "frequency":29563.24
    },
    {
      "note":"B10",
      "frequency":31321.2
    }
  ],
  "438": [
    {
      "note":"C0",
      "frequency":16.28
    },
    {
      "note":"C#0",
      "frequency":17.25
    },
    {
      "note":"D0",
      "frequency":18.27
    },
    {
      "note":"D#0",
      "frequency":19.36
    },
    {
      "note":"E0",
      "frequency":20.51
    },
    {
      "note":"F0",
      "frequency":21.73
    },
    {
      "note":"F#0",
      "frequency":23.02
    },
    {
      "note":"G0",
      "frequency":24.39
    },
    {
      "note":"G#0",
      "frequency":25.84
    },
    {
      "note":"A0",
      "frequency":27.38
    },
    {
      "note":"A#0",
      "frequency":29
    },
    {
      "note":"B0",
      "frequency":30.73
    },
    {
      "note":"C1",
      "frequency":32.55
    },
    {
      "note":"C#1",
      "frequency":34.49
    },
    {
      "note":"D1",
      "frequency":36.54
    },
    {
      "note":"D#1",
      "frequency":38.71
    },
    {
      "note":"E1",
      "frequency":41.02
    },
    {
      "note":"F1",
      "frequency":43.46
    },
    {
      "note":"F#1",
      "frequency":46.04
    },
    {
      "note":"G1",
      "frequency":48.78
    },
    {
      "note":"G#1",
      "frequency":51.68
    },
    {
      "note":"A1",
      "frequency":54.75
    },
    {
      "note":"A#1",
      "frequency":58.01
    },
    {
      "note":"B1",
      "frequency":61.45
    },
    {
      "note":"C2",
      "frequency":65.11
    },
    {
      "note":"C#2",
      "frequency":68.98
    },
    {
      "note":"D2",
      "frequency":73.08
    },
    {
      "note":"D#2",
      "frequency":77.43
    },
    {
      "note":"E2",
      "frequency":82.03
    },
    {
      "note":"F2",
      "frequency":86.91
    },
    {
      "note":"F#2",
      "frequency":92.08
    },
    {
      "note":"G2",
      "frequency":97.55
    },
    {
      "note":"G#2",
      "frequency":103.35
    },
    {
      "note":"A2",
      "frequency":109.5
    },
    {
      "note":"A#2",
      "frequency":116.01
    },
    {
      "note":"B2",
      "frequency":122.91
    },
    {
      "note":"C3",
      "frequency":130.22
    },
    {
      "note":"C#3",
      "frequency":137.96
    },
    {
      "note":"D3",
      "frequency":146.16
    },
    {
      "note":"D#3",
      "frequency":154.86
    },
    {
      "note":"E3",
      "frequency":164.06
    },
    {
      "note":"F3",
      "frequency":173.82
    },
    {
      "note":"F#3",
      "frequency":184.16
    },
    {
      "note":"G3",
      "frequency":195.11
    },
    {
      "note":"G#3",
      "frequency":206.71
    },
    {
      "note":"A3",
      "frequency":219
    },
    {
      "note":"A#3",
      "frequency":232.02
    },
    {
      "note":"B3",
      "frequency":245.82
    },
    {
      "note":"C4",
      "frequency":260.44
    },
    {
      "note":"C#4",
      "frequency":275.92
    },
    {
      "note":"D4",
      "frequency":292.33
    },
    {
      "note":"D#4",
      "frequency":309.71
    },
    {
      "note":"E4",
      "frequency":328.13
    },
    {
      "note":"F4",
      "frequency":347.64
    },
    {
      "note":"F#4",
      "frequency":368.31
    },
    {
      "note":"G4",
      "frequency":390.21
    },
    {
      "note":"G#4",
      "frequency":413.42
    },
    {
      "note":"A4",
      "frequency":438
    },
    {
      "note":"A#4",
      "frequency":464.04
    },
    {
      "note":"B4",
      "frequency":491.64
    },
    {
      "note":"C5",
      "frequency":520.87
    },
    {
      "note":"C#5",
      "frequency":551.85
    },
    {
      "note":"D5",
      "frequency":584.66
    },
    {
      "note":"D#5",
      "frequency":619.43
    },
    {
      "note":"E5",
      "frequency":656.26
    },
    {
      "note":"F5",
      "frequency":695.28
    },
    {
      "note":"F#5",
      "frequency":736.63
    },
    {
      "note":"G5",
      "frequency":780.43
    },
    {
      "note":"G#5",
      "frequency":826.83
    },
    {
      "note":"A5",
      "frequency":876
    },
    {
      "note":"A#5",
      "frequency":928.09
    },
    {
      "note":"B5",
      "frequency":983.28
    },
    {
      "note":"C6",
      "frequency":1041.74
    },
    {
      "note":"C#6",
      "frequency":1103.69
    },
    {
      "note":"D6",
      "frequency":1169.32
    },
    {
      "note":"D#6",
      "frequency":1238.85
    },
    {
      "note":"E6",
      "frequency":1312.52
    },
    {
      "note":"F6",
      "frequency":1390.56
    },
    {
      "note":"F#6",
      "frequency":1473.25
    },
    {
      "note":"G6",
      "frequency":1560.85
    },
    {
      "note":"G#6",
      "frequency":1653.67
    },
    {
      "note":"A6",
      "frequency":1752
    },
    {
      "note":"A#6",
      "frequency":1856.18
    },
    {
      "note":"B6",
      "frequency":1966.55
    },
    {
      "note":"C7",
      "frequency":2083.49
    },
    {
      "note":"C#7",
      "frequency":2207.38
    },
    {
      "note":"D7",
      "frequency":2338.64
    },
    {
      "note":"D#7",
      "frequency":2477.7
    },
    {
      "note":"E7",
      "frequency":2625.03
    },
    {
      "note":"F7",
      "frequency":2781.13
    },
    {
      "note":"F#7",
      "frequency":2946.5
    },
    {
      "note":"G7",
      "frequency":3121.71
    },
    {
      "note":"G#7",
      "frequency":3307.34
    },
    {
      "note":"A7",
      "frequency":3504
    },
    {
      "note":"A#7",
      "frequency":3712.36
    },
    {
      "note":"B7",
      "frequency":3933.11
    },
    {
      "note":"C8",
      "frequency":4166.98
    },
    {
      "note":"C#8",
      "frequency":4414.76
    },
    {
      "note":"D8",
      "frequency":4677.28
    },
    {
      "note":"D#8",
      "frequency":4955.4
    },
    {
      "note":"E8",
      "frequency":5250.07
    },
    {
      "note":"F8",
      "frequency":5562.25
    },
    {
      "note":"F#8",
      "frequency":5893
    },
    {
      "note":"G8",
      "frequency":6243.42
    },
    {
      "note":"G#8",
      "frequency":6614.67
    },
    {
      "note":"A8",
      "frequency":7008
    },
    {
      "note":"A#8",
      "frequency":7424.72
    },
    {
      "note":"B8",
      "frequency":7866.21
    },
    {
      "note":"C9",
      "frequency":8333.96
    },
    {
      "note":"C#9",
      "frequency":8829.52
    },
    {
      "note":"D9",
      "frequency":9354.56
    },
    {
      "note":"D#9",
      "frequency":9910.8
    },
    {
      "note":"E9",
      "frequency":10500.14
    },
    {
      "note":"F9",
      "frequency":11124.5
    },
    {
      "note":"F#9",
      "frequency":11786
    },
    {
      "note":"G9",
      "frequency":12486.84
    },
    {
      "note":"G#9",
      "frequency":13229.34
    },
    {
      "note":"A9",
      "frequency":14016
    },
    {
      "note":"A#9",
      "frequency":14849.44
    },
    {
      "note":"B9",
      "frequency":15732.42
    },
    {
      "note":"C10",
      "frequency":16667.92
    },
    {
      "note":"C#10",
      "frequency":17659.04
    },
    {
      "note":"D10",
      "frequency":18709.12
    },
    {
      "note":"D#10",
      "frequency":19821.6
    },
    {
      "note":"E10",
      "frequency":21000.28
    },
    {
      "note":"F10",
      "frequency":22249
    },
    {
      "note":"F#10",
      "frequency":23572
    },
    {
      "note":"G10",
      "frequency":24973.68
    },
    {
      "note":"G#10",
      "frequency":26458.68
    },
    {
      "note":"A10",
      "frequency":28032
    },
    {
      "note":"A#10",
      "frequency":29698.88
    },
    {
      "note":"B10",
      "frequency":31464.84
    }
  ],
  "440": [
    {
      "note":"C0",
      "frequency":16.35
    },
    {
      "note":"C#0",
      "frequency":17.32
    },
    {
      "note":"D0",
      "frequency":18.35
    },
    {
      "note":"D#0",
      "frequency":19.45
    },
    {
      "note":"E0",
      "frequency":20.6
    },
    {
      "note":"F0",
      "frequency":21.83
    },
    {
      "note":"F#0",
      "frequency":23.12
    },
    {
      "note":"G0",
      "frequency":24.5
    },
    {
      "note":"G#0",
      "frequency":25.96
    },
    {
      "note":"A0",
      "frequency":27.5
    },
    {
      "note":"A#0",
      "frequency":29.14
    },
    {
      "note":"B0",
      "frequency":30.87
    },
    {
      "note":"C1",
      "frequency":32.7
    },
    {
      "note":"C#1",
      "frequency":34.65
    },
    {
      "note":"D1",
      "frequency":36.71
    },
    {
      "note":"D#1",
      "frequency":38.89
    },
    {
      "note":"E1",
      "frequency":41.2
    },
    {
      "note":"F1",
      "frequency":43.65
    },
    {
      "note":"F#1",
      "frequency":46.25
    },
    {
      "note":"G1",
      "frequency":49
    },
    {
      "note":"G#1",
      "frequency":51.91
    },
    {
      "note":"A1",
      "frequency":55
    },
    {
      "note":"A#1",
      "frequency":58.27
    },
    {
      "note":"B1",
      "frequency":61.74
    },
    {
      "note":"C2",
      "frequency":65.41
    },
    {
      "note":"C#2",
      "frequency":69.3
    },
    {
      "note":"D2",
      "frequency":73.42
    },
    {
      "note":"D#2",
      "frequency":77.78
    },
    {
      "note":"E2",
      "frequency":82.41
    },
    {
      "note":"F2",
      "frequency":87.31
    },
    {
      "note":"F#2",
      "frequency":92.5
    },
    {
      "note":"G2",
      "frequency":98
    },
    {
      "note":"G#2",
      "frequency":103.83
    },
    {
      "note":"A2",
      "frequency":110
    },
    {
      "note":"A#2",
      "frequency":116.54
    },
    {
      "note":"B2",
      "frequency":123.47
    },
    {
      "note":"C3",
      "frequency":130.81
    },
    {
      "note":"C#3",
      "frequency":138.59
    },
    {
      "note":"D3",
      "frequency":146.83
    },
    {
      "note":"D#3",
      "frequency":155.56
    },
    {
      "note":"E3",
      "frequency":164.81
    },
    {
      "note":"F3",
      "frequency":174.61
    },
    {
      "note":"F#3",
      "frequency":185
    },
    {
      "note":"G3",
      "frequency":196
    },
    {
      "note":"G#3",
      "frequency":207.65
    },
    {
      "note":"A3",
      "frequency":220
    },
    {
      "note":"A#3",
      "frequency":233.08
    },
    {
      "note":"B3",
      "frequency":246.94
    },
    {
      "note":"C4",
      "frequency":261.63
    },
    {
      "note":"C#4",
      "frequency":277.18
    },
    {
      "note":"D4",
      "frequency":293.66
    },
    {
      "note":"D#4",
      "frequency":311.13
    },
    {
      "note":"E4",
      "frequency":329.63
    },
    {
      "note":"F4",
      "frequency":349.23
    },
    {
      "note":"F#4",
      "frequency":369.99
    },
    {
      "note":"G4",
      "frequency":392
    },
    {
      "note":"G#4",
      "frequency":415.3
    },
    {
      "note":"A4",
      "frequency":440
    },
    {
      "note":"A#4",
      "frequency":466.16
    },
    {
      "note":"B4",
      "frequency":493.88
    },
    {
      "note":"C5",
      "frequency":523.25
    },
    {
      "note":"C#5",
      "frequency":554.37
    },
    {
      "note":"D5",
      "frequency":587.33
    },
    {
      "note":"D#5",
      "frequency":622.25
    },
    {
      "note":"E5",
      "frequency":659.25
    },
    {
      "note":"F5",
      "frequency":698.46
    },
    {
      "note":"F#5",
      "frequency":739.99
    },
    {
      "note":"G5",
      "frequency":783.99
    },
    {
      "note":"G#5",
      "frequency":830.61
    },
    {
      "note":"A5",
      "frequency":880
    },
    {
      "note":"A#5",
      "frequency":932.33
    },
    {
      "note":"B5",
      "frequency":987.77
    },
    {
      "note":"C6",
      "frequency":1046.5
    },
    {
      "note":"C#6",
      "frequency":1108.73
    },
    {
      "note":"D6",
      "frequency":1174.66
    },
    {
      "note":"D#6",
      "frequency":1244.51
    },
    {
      "note":"E6",
      "frequency":1318.51
    },
    {
      "note":"F6",
      "frequency":1396.91
    },
    {
      "note":"F#6",
      "frequency":1479.98
    },
    {
      "note":"G6",
      "frequency":1567.98
    },
    {
      "note":"G#6",
      "frequency":1661.22
    },
    {
      "note":"A6",
      "frequency":1760
    },
    {
      "note":"A#6",
      "frequency":1864.66
    },
    {
      "note":"B6",
      "frequency":1975.53
    },
    {
      "note":"C7",
      "frequency":2093
    },
    {
      "note":"C#7",
      "frequency":2217.46
    },
    {
      "note":"D7",
      "frequency":2349.32
    },
    {
      "note":"D#7",
      "frequency":2489.02
    },
    {
      "note":"E7",
      "frequency":2637.02
    },
    {
      "note":"F7",
      "frequency":2793.83
    },
    {
      "note":"F#7",
      "frequency":2959.96
    },
    {
      "note":"G7",
      "frequency":3135.96
    },
    {
      "note":"G#7",
      "frequency":3322.44
    },
    {
      "note":"A7",
      "frequency":3520
    },
    {
      "note":"A#7",
      "frequency":3729.31
    },
    {
      "note":"B7",
      "frequency":3951.07
    },
    {
      "note":"C8",
      "frequency":4186.01
    },
    {
      "note":"C#8",
      "frequency":4434.92
    },
    {
      "note":"D8",
      "frequency":4698.63
    },
    {
      "note":"D#8",
      "frequency":4978.03
    },
    {
      "note":"E8",
      "frequency":5274.04
    },
    {
      "note":"F8",
      "frequency":5587.65
    },
    {
      "note":"F#8",
      "frequency":5919.91
    },
    {
      "note":"G8",
      "frequency":6271.93
    },
    {
      "note":"G#8",
      "frequency":6644.88
    },
    {
      "note":"A8",
      "frequency":7040
    },
    {
      "note":"A#8",
      "frequency":7458.62
    },
    {
      "note":"B8",
      "frequency":7902.13
    },
    {
      "note":"C9",
      "frequency":8372.02
    },
    {
      "note":"C#9",
      "frequency":8869.84
    },
    {
      "note":"D9",
      "frequency":9397.26
    },
    {
      "note":"D#9",
      "frequency":9956.06
    },
    {
      "note":"E9",
      "frequency":10548.08
    },
    {
      "note":"F9",
      "frequency":11175.3
    },
    {
      "note":"F#9",
      "frequency":11839.82
    },
    {
      "note":"G9",
      "frequency":12543.86
    },
    {
      "note":"G#9",
      "frequency":13289.76
    },
    {
      "note":"A9",
      "frequency":14080
    },
    {
      "note":"A#9",
      "frequency":14917.24
    },
    {
      "note":"B9",
      "frequency":15804.26
    },
    {
      "note":"C10",
      "frequency":16744.04
    },
    {
      "note":"C#10",
      "frequency":17739.68
    },
    {
      "note":"D10",
      "frequency":18794.52
    },
    {
      "note":"D#10",
      "frequency":19912.12
    },
    {
      "note":"E10",
      "frequency":21096.16
    },
    {
      "note":"F10",
      "frequency":22350.6
    },
    {
      "note":"F#10",
      "frequency":23679.64
    },
    {
      "note":"G10",
      "frequency":25087.72
    },
    {
      "note":"G#9",
      "frequency":26579.52
    },
    {
      "note":"A10",
      "frequency":28160
    },
    {
      "note":"A#10",
      "frequency":29834.48
    },
    {
      "note":"B10",
      "frequency":31608.52
    }
  ],
  "442": [
    {
      "note":"C0",
      "frequency":16.43
    },
    {
      "note":"C#0",
      "frequency":17.4
    },
    {
      "note":"D0",
      "frequency":18.44
    },
    {
      "note":"D#0",
      "frequency":19.53
    },
    {
      "note":"E0",
      "frequency":20.7
    },
    {
      "note":"F0",
      "frequency":21.93
    },
    {
      "note":"F#0",
      "frequency":23.23
    },
    {
      "note":"G0",
      "frequency":24.61
    },
    {
      "note":"G#0",
      "frequency":26.07
    },
    {
      "note":"A0",
      "frequency":27.62
    },
    {
      "note":"A#0",
      "frequency":29.27
    },
    {
      "note":"B0",
      "frequency":31.01
    },
    {
      "note":"C1",
      "frequency":32.85
    },
    {
      "note":"C#1",
      "frequency":34.81
    },
    {
      "note":"D1",
      "frequency":36.87
    },
    {
      "note":"D#1",
      "frequency":39.07
    },
    {
      "note":"E1",
      "frequency":41.39
    },
    {
      "note":"F1",
      "frequency":43.85
    },
    {
      "note":"F#1",
      "frequency":46.46
    },
    {
      "note":"G1",
      "frequency":49.22
    },
    {
      "note":"G#1",
      "frequency":52.15
    },
    {
      "note":"A1",
      "frequency":55.25
    },
    {
      "note":"A#1",
      "frequency":58.54
    },
    {
      "note":"B1",
      "frequency":62.02
    },
    {
      "note":"C2",
      "frequency":65.7
    },
    {
      "note":"C#2",
      "frequency":69.61
    },
    {
      "note":"D2",
      "frequency":73.75
    },
    {
      "note":"D#2",
      "frequency":78.14
    },
    {
      "note":"E2",
      "frequency":82.78
    },
    {
      "note":"F2",
      "frequency":87.7
    },
    {
      "note":"F#2",
      "frequency":92.92
    },
    {
      "note":"G2",
      "frequency":98.44
    },
    {
      "note":"G#2",
      "frequency":104.3
    },
    {
      "note":"A2",
      "frequency":110.5
    },
    {
      "note":"A#2",
      "frequency":117.07
    },
    {
      "note":"B2",
      "frequency":124.03
    },
    {
      "note":"C3",
      "frequency":131.41
    },
    {
      "note":"C#3",
      "frequency":139.22
    },
    {
      "note":"D3",
      "frequency":147.5
    },
    {
      "note":"D#3",
      "frequency":156.27
    },
    {
      "note":"E3",
      "frequency":165.56
    },
    {
      "note":"F3",
      "frequency":175.41
    },
    {
      "note":"F#3",
      "frequency":185.84
    },
    {
      "note":"G3",
      "frequency":196.89
    },
    {
      "note":"G#3",
      "frequency":208.6
    },
    {
      "note":"A3",
      "frequency":221
    },
    {
      "note":"A#3",
      "frequency":234.14
    },
    {
      "note":"B3",
      "frequency":248.06
    },
    {
      "note":"C4",
      "frequency":262.81
    },
    {
      "note":"C#4",
      "frequency":278.44
    },
    {
      "note":"D4",
      "frequency":295
    },
    {
      "note":"D#4",
      "frequency":312.54
    },
    {
      "note":"E4",
      "frequency":331.13
    },
    {
      "note":"F4",
      "frequency":350.82
    },
    {
      "note":"F#4",
      "frequency":371.68
    },
    {
      "note":"G4",
      "frequency":393.78
    },
    {
      "note":"G#4",
      "frequency":417.19
    },
    {
      "note":"A4",
      "frequency":442
    },
    {
      "note":"A#4",
      "frequency":468.28
    },
    {
      "note":"B4",
      "frequency":496.13
    },
    {
      "note":"C5",
      "frequency":525.63
    },
    {
      "note":"C#5",
      "frequency":556.88
    },
    {
      "note":"D5",
      "frequency":590
    },
    {
      "note":"D#5",
      "frequency":625.08
    },
    {
      "note":"E5",
      "frequency":662.25
    },
    {
      "note":"F5",
      "frequency":701.63
    },
    {
      "note":"F#5",
      "frequency":743.35
    },
    {
      "note":"G5",
      "frequency":787.55
    },
    {
      "note":"G#5",
      "frequency":834.38
    },
    {
      "note":"A5",
      "frequency":884
    },
    {
      "note":"A#5",
      "frequency":936.57
    },
    {
      "note":"B5",
      "frequency":992.26
    },
    {
      "note":"C6",
      "frequency":1051.26
    },
    {
      "note":"C#6",
      "frequency":1113.77
    },
    {
      "note":"D6",
      "frequency":1180
    },
    {
      "note":"D#6",
      "frequency":1250.16
    },
    {
      "note":"E6",
      "frequency":1324.5
    },
    {
      "note":"F6",
      "frequency":1403.26
    },
    {
      "note":"F#6",
      "frequency":1486.7
    },
    {
      "note":"G6",
      "frequency":1575.11
    },
    {
      "note":"G#6",
      "frequency":1668.77
    },
    {
      "note":"A6",
      "frequency":1768
    },
    {
      "note":"A#6",
      "frequency":1873.13
    },
    {
      "note":"B6",
      "frequency":1984.51
    },
    {
      "note":"C7",
      "frequency":2102.52
    },
    {
      "note":"C#7",
      "frequency":2227.54
    },
    {
      "note":"D7",
      "frequency":2360
    },
    {
      "note":"D#7",
      "frequency":2500.33
    },
    {
      "note":"E7",
      "frequency":2649.01
    },
    {
      "note":"F7",
      "frequency":2806.52
    },
    {
      "note":"F#7",
      "frequency":2973.41
    },
    {
      "note":"G7",
      "frequency":3150.22
    },
    {
      "note":"G#7",
      "frequency":3337.54
    },
    {
      "note":"A7",
      "frequency":3536
    },
    {
      "note":"A#7",
      "frequency":3746.26
    },
    {
      "note":"B7",
      "frequency":3969.03
    },
    {
      "note":"C8",
      "frequency":4205.03
    },
    {
      "note":"C#8",
      "frequency":4455.08
    },
    {
      "note":"D8",
      "frequency":4719.99
    },
    {
      "note":"D#8",
      "frequency":5000.66
    },
    {
      "note":"E8",
      "frequency":5298.01
    },
    {
      "note":"F8",
      "frequency":5613.05
    },
    {
      "note":"F#8",
      "frequency":5946.82
    },
    {
      "note":"G8",
      "frequency":6300.44
    },
    {
      "note":"G#8",
      "frequency":6675.08
    },
    {
      "note":"A8",
      "frequency":7072
    },
    {
      "note":"A#8",
      "frequency":7492.52
    },
    {
      "note":"B8",
      "frequency":7938.05
    },
    {
      "note":"C9",
      "frequency":8410.06
    },
    {
      "note":"C#9",
      "frequency":8910.16
    },
    {
      "note":"D9",
      "frequency":9439.98
    },
    {
      "note":"D#9",
      "frequency":10001.32
    },
    {
      "note":"E9",
      "frequency":10596.02
    },
    {
      "note":"F9",
      "frequency":11226.1
    },
    {
      "note":"F#9",
      "frequency":11893.64
    },
    {
      "note":"G9",
      "frequency":12600.88
    },
    {
      "note":"G#9",
      "frequency":13350.16
    },
    {
      "note":"A9",
      "frequency":14144
    },
    {
      "note":"A#9",
      "frequency":14985.04
    },
    {
      "note":"B9",
      "frequency":15876.1
    },
    {
      "note":"C10",
      "frequency":16820.12
    },
    {
      "note":"C#10",
      "frequency":17820.32
    },
    {
      "note":"D10",
      "frequency":18879.96
    },
    {
      "note":"D#10",
      "frequency":20002.64
    },
    {
      "note":"E10",
      "frequency":21192.04
    },
    {
      "note":"F10",
      "frequency":22452.2
    },
    {
      "note":"F#10",
      "frequency":23787.28
    },
    {
      "note":"G10",
      "frequency":25201.76
    },
    {
      "note":"G#10",
      "frequency":26700.32
    },
    {
      "note":"A10",
      "frequency":28288
    },
    {
      "note":"A#10",
      "frequency":29970.08
    },
    {
      "note":"B10",
      "frequency":31752.2
    }
  ],
  "444": [
    {
      "note":"C0",
      "frequency":16.5
    },
    {
      "note":"C#0",
      "frequency":17.48
    },
    {
      "note":"D0",
      "frequency":18.52
    },
    {
      "note":"D#0",
      "frequency":19.62
    },
    {
      "note":"E0",
      "frequency":20.79
    },
    {
      "note":"F0",
      "frequency":22.03
    },
    {
      "note":"F#0",
      "frequency":23.33
    },
    {
      "note":"G0",
      "frequency":24.72
    },
    {
      "note":"G#0",
      "frequency":26.19
    },
    {
      "note":"A0",
      "frequency":27.75
    },
    {
      "note":"A#0",
      "frequency":29.4
    },
    {
      "note":"B0",
      "frequency":31.15
    },
    {
      "note":"C1",
      "frequency":33
    },
    {
      "note":"C#1",
      "frequency":34.96
    },
    {
      "note":"D1",
      "frequency":37.04
    },
    {
      "note":"D#1",
      "frequency":39.24
    },
    {
      "note":"E1",
      "frequency":41.58
    },
    {
      "note":"F1",
      "frequency":44.05
    },
    {
      "note":"F#1",
      "frequency":46.67
    },
    {
      "note":"G1",
      "frequency":49.44
    },
    {
      "note":"G#1",
      "frequency":52.39
    },
    {
      "note":"A1",
      "frequency":55.5
    },
    {
      "note":"A#1",
      "frequency":58.8
    },
    {
      "note":"B1",
      "frequency":62.3
    },
    {
      "note":"C2",
      "frequency":66
    },
    {
      "note":"C#2",
      "frequency":69.93
    },
    {
      "note":"D2",
      "frequency":74.08
    },
    {
      "note":"D#2",
      "frequency":78.49
    },
    {
      "note":"E2",
      "frequency":83.16
    },
    {
      "note":"F2",
      "frequency":88.1
    },
    {
      "note":"F#2",
      "frequency":93.34
    },
    {
      "note":"G2",
      "frequency":98.89
    },
    {
      "note":"G#2",
      "frequency":104.77
    },
    {
      "note":"A2",
      "frequency":111
    },
    {
      "note":"A#2",
      "frequency":117.6
    },
    {
      "note":"B2",
      "frequency":124.59
    },
    {
      "note":"C3",
      "frequency":132
    },
    {
      "note":"C#3",
      "frequency":139.85
    },
    {
      "note":"D3",
      "frequency":148.17
    },
    {
      "note":"D#3",
      "frequency":156.98
    },
    {
      "note":"E3",
      "frequency":166.31
    },
    {
      "note":"F3",
      "frequency":176.2
    },
    {
      "note":"F#3",
      "frequency":186.68
    },
    {
      "note":"G3",
      "frequency":197.78
    },
    {
      "note":"G#3",
      "frequency":209.54
    },
    {
      "note":"A3",
      "frequency":222
    },
    {
      "note":"A#3",
      "frequency":235.2
    },
    {
      "note":"B3",
      "frequency":249.19
    },
    {
      "note":"C4",
      "frequency":264
    },
    {
      "note":"C#4",
      "frequency":279.7
    },
    {
      "note":"D4",
      "frequency":296.33
    },
    {
      "note":"D#4",
      "frequency":313.96
    },
    {
      "note":"E4",
      "frequency":332.62
    },
    {
      "note":"F4",
      "frequency":352.4
    },
    {
      "note":"F#4",
      "frequency":373.36
    },
    {
      "note":"G4",
      "frequency":395.56
    },
    {
      "note":"G#4",
      "frequency":419.08
    },
    {
      "note":"A4",
      "frequency":444
    },
    {
      "note":"A#4",
      "frequency":470.4
    },
    {
      "note":"B4",
      "frequency":498.37
    },
    {
      "note":"C5",
      "frequency":528.01
    },
    {
      "note":"C#5",
      "frequency":559.4
    },
    {
      "note":"D5",
      "frequency":592.67
    },
    {
      "note":"D#5",
      "frequency":627.91
    },
    {
      "note":"E5",
      "frequency":665.25
    },
    {
      "note":"F5",
      "frequency":704.81
    },
    {
      "note":"F#5",
      "frequency":746.72
    },
    {
      "note":"G5",
      "frequency":791.12
    },
    {
      "note":"G#5",
      "frequency":838.16
    },
    {
      "note":"A5",
      "frequency":888
    },
    {
      "note":"A#5",
      "frequency":940.8
    },
    {
      "note":"B5",
      "frequency":996.75
    },
    {
      "note":"C6",
      "frequency":1056.02
    },
    {
      "note":"C#6",
      "frequency":1118.81
    },
    {
      "note":"D6",
      "frequency":1185.34
    },
    {
      "note":"D#6",
      "frequency":1255.82
    },
    {
      "note":"E6",
      "frequency":1330.5
    },
    {
      "note":"F6",
      "frequency":1409.61
    },
    {
      "note":"F#6",
      "frequency":1493.43
    },
    {
      "note":"G6",
      "frequency":1582.24
    },
    {
      "note":"G#6",
      "frequency":1676.32
    },
    {
      "note":"A6",
      "frequency":1776
    },
    {
      "note":"A#6",
      "frequency":1881.61
    },
    {
      "note":"B6",
      "frequency":1993.49
    },
    {
      "note":"C7",
      "frequency":2112.03
    },
    {
      "note":"C#7",
      "frequency":2237.62
    },
    {
      "note":"D7",
      "frequency":2370.67
    },
    {
      "note":"D#7",
      "frequency":2511.64
    },
    {
      "note":"E7",
      "frequency":2660.99
    },
    {
      "note":"F7",
      "frequency":2819.22
    },
    {
      "note":"F#7",
      "frequency":2986.86
    },
    {
      "note":"G7",
      "frequency":3164.47
    },
    {
      "note":"G#7",
      "frequency":3352.64
    },
    {
      "note":"A7",
      "frequency":3552
    },
    {
      "note":"A#7",
      "frequency":3763.21
    },
    {
      "note":"B7",
      "frequency":3986.99
    },
    {
      "note":"C8",
      "frequency":4224.06
    },
    {
      "note":"C#8",
      "frequency":4475.24
    },
    {
      "note":"D8",
      "frequency":4741.35
    },
    {
      "note":"D#8",
      "frequency":5023.29
    },
    {
      "note":"E8",
      "frequency":5321.99
    },
    {
      "note":"F8",
      "frequency":5638.45
    },
    {
      "note":"F#8",
      "frequency":5973.73
    },
    {
      "note":"G8",
      "frequency":6328.94
    },
    {
      "note":"G#8",
      "frequency":6705.28
    },
    {
      "note":"A8",
      "frequency":7104
    },
    {
      "note":"A#8",
      "frequency":7526.43
    },
    {
      "note":"B8",
      "frequency":7973.97
    },
    {
      "note":"C9",
      "frequency":8448.12
    },
    {
      "note":"C#9",
      "frequency":8950.48
    },
    {
      "note":"D9",
      "frequency":9482.7
    },
    {
      "note":"D#9",
      "frequency":10046.58
    },
    {
      "note":"E9",
      "frequency":10643.98
    },
    {
      "note":"F9",
      "frequency":11276.9
    },
    {
      "note":"F#9",
      "frequency":11947.46
    },
    {
      "note":"G9",
      "frequency":12657.88
    },
    {
      "note":"G#9",
      "frequency":13410.56
    },
    {
      "note":"A9",
      "frequency":14208
    },
    {
      "note":"A#9",
      "frequency":15052.86
    },
    {
      "note":"B9",
      "frequency":15947.94
    },
    {
      "note":"C10",
      "frequency":16896.24
    },
    {
      "note":"C#10",
      "frequency":17900.96
    },
    {
      "note":"D10",
      "frequency":18965.4
    },
    {
      "note":"D#10",
      "frequency":20093.16
    },
    {
      "note":"E10",
      "frequency":21287.96
    },
    {
      "note":"F10",
      "frequency":22553.8
    },
    {
      "note":"F#10",
      "frequency":23894.92
    },
    {
      "note":"G10",
      "frequency":25315.76
    },
    {
      "note":"G#10",
      "frequency":26821.12
    },
    {
      "note":"A10",
      "frequency":28416
    },
    {
      "note":"A#10",
      "frequency":30105.72
    },
    {
      "note":"B10",
      "frequency":31895.88
    }
  ],
  "446": [
    {
      "note":"C0",
      "frequency":16.57
    },
    {
      "note":"C#0",
      "frequency":17.56
    },
    {
      "note":"D0",
      "frequency":18.6
    },
    {
      "note":"D#0",
      "frequency":19.71
    },
    {
      "note":"E0",
      "frequency":20.88
    },
    {
      "note":"F0",
      "frequency":22.12
    },
    {
      "note":"F#0",
      "frequency":23.44
    },
    {
      "note":"G0",
      "frequency":24.83
    },
    {
      "note":"G#0",
      "frequency":26.31
    },
    {
      "note":"A0",
      "frequency":27.88
    },
    {
      "note":"A#0",
      "frequency":29.53
    },
    {
      "note":"B0",
      "frequency":31.29
    },
    {
      "note":"C1",
      "frequency":33.15
    },
    {
      "note":"C#1",
      "frequency":35.12
    },
    {
      "note":"D1",
      "frequency":37.21
    },
    {
      "note":"D#1",
      "frequency":39.42
    },
    {
      "note":"E1",
      "frequency":41.77
    },
    {
      "note":"F1",
      "frequency":44.25
    },
    {
      "note":"F#1",
      "frequency":46.88
    },
    {
      "note":"G1",
      "frequency":49.67
    },
    {
      "note":"G#1",
      "frequency":52.62
    },
    {
      "note":"A1",
      "frequency":55.75
    },
    {
      "note":"A#1",
      "frequency":59.07
    },
    {
      "note":"B1",
      "frequency":62.58
    },
    {
      "note":"C2",
      "frequency":66.3
    },
    {
      "note":"C#2",
      "frequency":70.24
    },
    {
      "note":"D2",
      "frequency":74.42
    },
    {
      "note":"D#2",
      "frequency":78.84
    },
    {
      "note":"E2",
      "frequency":83.53
    },
    {
      "note":"F2",
      "frequency":88.5
    },
    {
      "note":"F#2",
      "frequency":93.76
    },
    {
      "note":"G2",
      "frequency":99.34
    },
    {
      "note":"G#2",
      "frequency":105.24
    },
    {
      "note":"A2",
      "frequency":111.5
    },
    {
      "note":"A#2",
      "frequency":118.13
    },
    {
      "note":"B2",
      "frequency":125.15
    },
    {
      "note":"C3",
      "frequency":132.6
    },
    {
      "note":"C#3",
      "frequency":140.48
    },
    {
      "note":"D3",
      "frequency":148.83
    },
    {
      "note":"D#3",
      "frequency":157.68
    },
    {
      "note":"E3",
      "frequency":167.06
    },
    {
      "note":"F3",
      "frequency":177
    },
    {
      "note":"F#3",
      "frequency":187.52
    },
    {
      "note":"G3",
      "frequency":198.67
    },
    {
      "note":"G#3",
      "frequency":210.48
    },
    {
      "note":"A3",
      "frequency":223
    },
    {
      "note":"A#3",
      "frequency":236.26
    },
    {
      "note":"B3",
      "frequency":250.31
    },
    {
      "note":"C4",
      "frequency":265.19
    },
    {
      "note":"C#4",
      "frequency":280.96
    },
    {
      "note":"D4",
      "frequency":297.67
    },
    {
      "note":"D#4",
      "frequency":315.37
    },
    {
      "note":"E4",
      "frequency":334.12
    },
    {
      "note":"F4",
      "frequency":353.99
    },
    {
      "note":"F#4",
      "frequency":375.04
    },
    {
      "note":"G4",
      "frequency":397.34
    },
    {
      "note":"G#4",
      "frequency":420.97
    },
    {
      "note":"A4",
      "frequency":446
    },
    {
      "note":"A#4",
      "frequency":472.52
    },
    {
      "note":"B4",
      "frequency":500.62
    },
    {
      "note":"C5",
      "frequency":530.39
    },
    {
      "note":"C#5",
      "frequency":561.92
    },
    {
      "note":"D5",
      "frequency":595.34
    },
    {
      "note":"D#5",
      "frequency":630.74
    },
    {
      "note":"E5",
      "frequency":668.24
    },
    {
      "note":"F5",
      "frequency":707.98
    },
    {
      "note":"F#5",
      "frequency":750.08
    },
    {
      "note":"G5",
      "frequency":794.68
    },
    {
      "note":"G#5",
      "frequency":841.94
    },
    {
      "note":"A5",
      "frequency":892
    },
    {
      "note":"A#5",
      "frequency":945.04
    },
    {
      "note":"B5",
      "frequency":1001.24
    },
    {
      "note":"C6",
      "frequency":1060.77
    },
    {
      "note":"C#6",
      "frequency":1123.85
    },
    {
      "note":"D6",
      "frequency":1190.68
    },
    {
      "note":"D#6",
      "frequency":1261.48
    },
    {
      "note":"E6",
      "frequency":1336.49
    },
    {
      "note":"F6",
      "frequency":1415.96
    },
    {
      "note":"F#6",
      "frequency":1500.16
    },
    {
      "note":"G6",
      "frequency":1589.36
    },
    {
      "note":"G#6",
      "frequency":1683.87
    },
    {
      "note":"A6",
      "frequency":1784
    },
    {
      "note":"A#6",
      "frequency":1890.08
    },
    {
      "note":"B6",
      "frequency":2002.47
    },
    {
      "note":"C7",
      "frequency":2121.54
    },
    {
      "note":"C#7",
      "frequency":2247.7
    },
    {
      "note":"D7",
      "frequency":2381.35
    },
    {
      "note":"D#7",
      "frequency":2522.96
    },
    {
      "note":"E7",
      "frequency":2672.98
    },
    {
      "note":"F7",
      "frequency":2831.92
    },
    {
      "note":"F#7",
      "frequency":3000.32
    },
    {
      "note":"G7",
      "frequency":3178.73
    },
    {
      "note":"G#7",
      "frequency":3367.74
    },
    {
      "note":"A7",
      "frequency":3568
    },
    {
      "note":"A#7",
      "frequency":3780.16
    },
    {
      "note":"B7",
      "frequency":4004.95
    },
    {
      "note":"C8",
      "frequency":4243.09
    },
    {
      "note":"C#8",
      "frequency":4495.4
    },
    {
      "note":"D8",
      "frequency":4762.71
    },
    {
      "note":"D#8",
      "frequency":5045.91
    },
    {
      "note":"E8",
      "frequency":5345.96
    },
    {
      "note":"F8",
      "frequency":5663.85
    },
    {
      "note":"F#8",
      "frequency":6000.64
    },
    {
      "note":"G8",
      "frequency":6357.45
    },
    {
      "note":"G#8",
      "frequency":6735.49
    },
    {
      "note":"A8",
      "frequency":7136
    },
    {
      "note":"A#8",
      "frequency":7560.33
    },
    {
      "note":"B8",
      "frequency":8009.89
    },
    {
      "note":"C9",
      "frequency":8486.18
    },
    {
      "note":"C#9",
      "frequency":8990.8
    },
    {
      "note":"D9",
      "frequency":9525.42
    },
    {
      "note":"D#9",
      "frequency":10091.82
    },
    {
      "note":"E9",
      "frequency":10691.92
    },
    {
      "note":"F9",
      "frequency":11327.7
    },
    {
      "note":"F#9",
      "frequency":12001.28
    },
    {
      "note":"G9",
      "frequency":12714.9
    },
    {
      "note":"G#9",
      "frequency":13470.98
    },
    {
      "note":"A9",
      "frequency":14272
    },
    {
      "note":"A#9",
      "frequency":15120.66
    },
    {
      "note":"B9",
      "frequency":16019.78
    },
    {
      "note":"C10",
      "frequency":16972.36
    },
    {
      "note":"C#10",
      "frequency":17981.6
    },
    {
      "note":"D10",
      "frequency":19050.84
    },
    {
      "note":"D#10",
      "frequency":20183.64
    },
    {
      "note":"E10",
      "frequency":21383.84
    },
    {
      "note":"F10",
      "frequency":22655.4
    },
    {
      "note":"F#10",
      "frequency":24002.56
    },
    {
      "note":"G10",
      "frequency":25429.8
    },
    {
      "note":"G#10",
      "frequency":26941.96
    },
    {
      "note":"A10",
      "frequency":28544
    },
    {
      "note":"A#10",
      "frequency":30241.32
    },
    {
      "note":"B10",
      "frequency":32039.56
    }
  ]
}

module.exports = {
  channels: {
    APP_INFO: 'app_info',
  },
  REALITY_MGMT_JWT_TOKEN,
  REALITY_MGMT_UID,
  happyApi_url,
  STATE_LIST,
  api_url,
  DEFAULT_ORG,
  card_token_v1_url,
  defaultFundamentalizerInfo,
  base_url,
  auth_url,
  login_url,
  users_v1_url,
  harmonicsLabelMobile,
  notesJson,
  DEFAULT_AVATAR_URL,
  sessionData_url,
  users_url,
  account_url,
  notesArray,
  harmonicsLabel,
  cards_url,
  signup_url,
  newUser_url,
  STRIPE_API_TOKEN
};